/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchProcessTask
 */
export interface BatchProcessTask {
    /**
     * 
     * @type {string}
     * @memberof BatchProcessTask
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BatchProcessTask
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchProcessTask
     */
    uploadId?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchProcessTask
     */
    status: BatchProcessTaskStatusEnum;
}


/**
 * @export
 */
export const BatchProcessTaskStatusEnum = {
    Failed: 'failed',
    Pending: 'pending',
    Success: 'success'
} as const;
export type BatchProcessTaskStatusEnum = typeof BatchProcessTaskStatusEnum[keyof typeof BatchProcessTaskStatusEnum];


/**
 * Check if a given object implements the BatchProcessTask interface.
 */
export function instanceOfBatchProcessTask(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function BatchProcessTaskFromJSON(json: any): BatchProcessTask {
    return BatchProcessTaskFromJSONTyped(json, false);
}

export function BatchProcessTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchProcessTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'] == null ? undefined : json['filename'],
        'uploadId': json['upload_id'] == null ? undefined : json['upload_id'],
        'status': json['status'],
    };
}

export function BatchProcessTaskToJSON(value?: BatchProcessTask | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'filename': value['filename'],
        'upload_id': value['uploadId'],
        'status': value['status'],
    };
}

