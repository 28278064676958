/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectorMetric
 */
export interface ConnectorMetric {
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    rawCode: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    rawFormula: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorMetric
     */
    connectorName: string;
}

/**
 * Check if a given object implements the ConnectorMetric interface.
 */
export function instanceOfConnectorMetric(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('rawCode' in value)) return false;
    if (!('rawFormula' in value)) return false;
    if (!('connectorName' in value)) return false;
    return true;
}

export function ConnectorMetricFromJSON(json: any): ConnectorMetric {
    return ConnectorMetricFromJSONTyped(json, false);
}

export function ConnectorMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorMetric {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'rawCode': json['raw_code'],
        'rawFormula': json['raw_formula'],
        'logo': json['logo'] == null ? undefined : json['logo'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'description': json['description'] == null ? undefined : json['description'],
        'connectorName': json['connector_name'],
    };
}

export function ConnectorMetricToJSON(value?: ConnectorMetric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'raw_code': value['rawCode'],
        'raw_formula': value['rawFormula'],
        'logo': value['logo'],
        'unit': value['unit'],
        'description': value['description'],
        'connector_name': value['connectorName'],
    };
}

