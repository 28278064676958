/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateScenario,
  HTTPValidationError,
  Scenario,
  ScenarioTreeNode,
  UpdateScenario,
} from '../models/index';
import {
    CreateScenarioFromJSON,
    CreateScenarioToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ScenarioFromJSON,
    ScenarioToJSON,
    ScenarioTreeNodeFromJSON,
    ScenarioTreeNodeToJSON,
    UpdateScenarioFromJSON,
    UpdateScenarioToJSON,
} from '../models/index';

export interface CreateScenarioRequest {
    workspaceCode: string;
    createScenario: CreateScenario;
}

export interface DeleteScenarioRequest {
    scenarioUuid: string;
    workspaceCode: string;
}

export interface ListWorkspaceScenariosRequest {
    workspaceCode: string;
}

export interface UpdateScenarioRequest {
    scenarioUuid: string;
    workspaceCode: string;
    updateScenario: UpdateScenario;
}

/**
 * 
 */
export class ScenariosApi extends runtime.BaseAPI {

    /**
     * Create Scenario
     */
    async createScenarioRaw(requestParameters: CreateScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createScenario().'
            );
        }

        if (requestParameters['createScenario'] == null) {
            throw new runtime.RequiredError(
                'createScenario',
                'Required parameter "createScenario" was null or undefined when calling createScenario().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/scenarios`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateScenarioToJSON(requestParameters['createScenario']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioFromJSON(jsonValue));
    }

    /**
     * Create Scenario
     */
    async createScenario(requestParameters: CreateScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scenario> {
        const response = await this.createScenarioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Scenario
     */
    async deleteScenarioRaw(requestParameters: DeleteScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['scenarioUuid'] == null) {
            throw new runtime.RequiredError(
                'scenarioUuid',
                'Required parameter "scenarioUuid" was null or undefined when calling deleteScenario().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteScenario().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/scenarios/{scenario_uuid}/delete`.replace(`{${"scenario_uuid"}}`, encodeURIComponent(String(requestParameters['scenarioUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Scenario
     */
    async deleteScenario(requestParameters: DeleteScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteScenarioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workspace Scenarios
     */
    async listWorkspaceScenariosRaw(requestParameters: ListWorkspaceScenariosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScenarioTreeNode>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listWorkspaceScenarios().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/scenarios/summary`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScenarioTreeNodeFromJSON));
    }

    /**
     * Workspace Scenarios
     */
    async listWorkspaceScenarios(requestParameters: ListWorkspaceScenariosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScenarioTreeNode>> {
        const response = await this.listWorkspaceScenariosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Scenario
     */
    async updateScenarioRaw(requestParameters: UpdateScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['scenarioUuid'] == null) {
            throw new runtime.RequiredError(
                'scenarioUuid',
                'Required parameter "scenarioUuid" was null or undefined when calling updateScenario().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateScenario().'
            );
        }

        if (requestParameters['updateScenario'] == null) {
            throw new runtime.RequiredError(
                'updateScenario',
                'Required parameter "updateScenario" was null or undefined when calling updateScenario().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/scenarios/{scenario_uuid}/update`.replace(`{${"scenario_uuid"}}`, encodeURIComponent(String(requestParameters['scenarioUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScenarioToJSON(requestParameters['updateScenario']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Scenario
     */
    async updateScenario(requestParameters: UpdateScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateScenarioRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
