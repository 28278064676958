/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TableMetric
 */
export interface TableMetric {
    /**
     * 
     * @type {string}
     * @memberof TableMetric
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof TableMetric
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TableMetric
     */
    tableUuid: string;
}

/**
 * Check if a given object implements the TableMetric interface.
 */
export function instanceOfTableMetric(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('tableUuid' in value)) return false;
    return true;
}

export function TableMetricFromJSON(json: any): TableMetric {
    return TableMetricFromJSONTyped(json, false);
}

export function TableMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableMetric {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'tableUuid': json['table_uuid'],
    };
}

export function TableMetricToJSON(value?: TableMetric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'table_uuid': value['tableUuid'],
    };
}

