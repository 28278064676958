/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {Blob}
     * @memberof User
     */
    avatar?: Blob;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    registeredOrgName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isHidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    decimalsSeparator?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('lastName' in value)) return false;
    if (!('color' in value)) return false;
    if (!('email' in value)) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'lastName': json['last_name'],
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'color': json['color'],
        'email': json['email'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'phoneCountryCode': json['phone_country_code'] == null ? undefined : json['phone_country_code'],
        'registeredOrgName': json['registered_org_name'] == null ? undefined : json['registered_org_name'],
        'isAdmin': json['is_admin'] == null ? undefined : json['is_admin'],
        'isHidden': json['is_hidden'] == null ? undefined : json['is_hidden'],
        'decimalsSeparator': json['decimals_separator'] == null ? undefined : json['decimals_separator'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'last_name': value['lastName'],
        'avatar': value['avatar'],
        'color': value['color'],
        'email': value['email'],
        'phone_number': value['phoneNumber'],
        'phone_country_code': value['phoneCountryCode'],
        'registered_org_name': value['registeredOrgName'],
        'is_admin': value['isAdmin'],
        'is_hidden': value['isHidden'],
        'decimals_separator': value['decimalsSeparator'],
    };
}

