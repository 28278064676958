/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AskQuestionParams,
  AskQuestionResponse,
  HTTPValidationError,
} from '../models/index';
import {
    AskQuestionParamsFromJSON,
    AskQuestionParamsToJSON,
    AskQuestionResponseFromJSON,
    AskQuestionResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models/index';

export interface AskQuestionRequest {
    workspaceCode: string;
    askQuestionParams: AskQuestionParams;
}

export interface RegenerateLastAnswerRequest {
    workspaceCode: string;
    conversationUuid: string;
}

/**
 * 
 */
export class AiApi extends runtime.BaseAPI {

    /**
     * Ask Question
     */
    async askQuestionRaw(requestParameters: AskQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AskQuestionResponse>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling askQuestion().'
            );
        }

        if (requestParameters['askQuestionParams'] == null) {
            throw new runtime.RequiredError(
                'askQuestionParams',
                'Required parameter "askQuestionParams" was null or undefined when calling askQuestion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/ai/chat`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AskQuestionParamsToJSON(requestParameters['askQuestionParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AskQuestionResponseFromJSON(jsonValue));
    }

    /**
     * Ask Question
     */
    async askQuestion(requestParameters: AskQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AskQuestionResponse> {
        const response = await this.askQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Regenerate Last Answer
     */
    async regenerateLastAnswerRaw(requestParameters: RegenerateLastAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AskQuestionResponse>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling regenerateLastAnswer().'
            );
        }

        if (requestParameters['conversationUuid'] == null) {
            throw new runtime.RequiredError(
                'conversationUuid',
                'Required parameter "conversationUuid" was null or undefined when calling regenerateLastAnswer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['conversationUuid'] != null) {
            queryParameters['conversation_uuid'] = requestParameters['conversationUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/ai/regenerate`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AskQuestionResponseFromJSON(jsonValue));
    }

    /**
     * Regenerate Last Answer
     */
    async regenerateLastAnswer(requestParameters: RegenerateLastAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AskQuestionResponse> {
        const response = await this.regenerateLastAnswerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
