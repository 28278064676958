/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveUploaderOwnersParams
 */
export interface RemoveUploaderOwnersParams {
    /**
     * 
     * @type {string}
     * @memberof RemoveUploaderOwnersParams
     */
    userUuid: string;
}

/**
 * Check if a given object implements the RemoveUploaderOwnersParams interface.
 */
export function instanceOfRemoveUploaderOwnersParams(value: object): boolean {
    if (!('userUuid' in value)) return false;
    return true;
}

export function RemoveUploaderOwnersParamsFromJSON(json: any): RemoveUploaderOwnersParams {
    return RemoveUploaderOwnersParamsFromJSONTyped(json, false);
}

export function RemoveUploaderOwnersParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveUploaderOwnersParams {
    if (json == null) {
        return json;
    }
    return {
        
        'userUuid': json['user_uuid'],
    };
}

export function RemoveUploaderOwnersParamsToJSON(value?: RemoveUploaderOwnersParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_uuid': value['userUuid'],
    };
}

