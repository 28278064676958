/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DownloadTableXlsParams
 */
export interface DownloadTableXlsParams {
    /**
     * 
     * @type {object}
     * @memberof DownloadTableXlsParams
     */
    rowStyles?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadTableXlsParams
     */
    metricsList?: Array<string>;
}

/**
 * Check if a given object implements the DownloadTableXlsParams interface.
 */
export function instanceOfDownloadTableXlsParams(value: object): boolean {
    return true;
}

export function DownloadTableXlsParamsFromJSON(json: any): DownloadTableXlsParams {
    return DownloadTableXlsParamsFromJSONTyped(json, false);
}

export function DownloadTableXlsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownloadTableXlsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'rowStyles': json['row_styles'] == null ? undefined : json['row_styles'],
        'metricsList': json['metrics_list'] == null ? undefined : json['metrics_list'],
    };
}

export function DownloadTableXlsParamsToJSON(value?: DownloadTableXlsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'row_styles': value['rowStyles'],
        'metrics_list': value['metricsList'],
    };
}

