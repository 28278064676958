/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SourcesChartNode
 */
export interface SourcesChartNode {
    /**
     * 
     * @type {boolean}
     * @memberof SourcesChartNode
     */
    dS?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    categoryUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    formula?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SourcesChartNode
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SourcesChartNode
     */
    lowerIsBetter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SourcesChartNode
     */
    trendYearly?: number;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    unit?: string;
    /**
     * 
     * @type {Date}
     * @memberof SourcesChartNode
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesChartNode
     */
    workspaceUuid?: string;
    /**
     * 
     * @type {any}
     * @memberof SourcesChartNode
     */
    createdBy?: any;
    /**
     * 
     * @type {any}
     * @memberof SourcesChartNode
     */
    favoritedBy?: any;
    /**
     * 
     * @type {any}
     * @memberof SourcesChartNode
     */
    favoritedByAdmins?: any;
    /**
     * 
     * @type {any}
     * @memberof SourcesChartNode
     */
    owners?: any;
    /**
     * 
     * @type {{ [key: string]: SourcesChartNode; }}
     * @memberof SourcesChartNode
     */
    sources?: { [key: string]: SourcesChartNode; };
}

/**
 * Check if a given object implements the SourcesChartNode interface.
 */
export function instanceOfSourcesChartNode(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('name' in value)) return false;
    if (!('uuid' in value)) return false;
    return true;
}

export function SourcesChartNodeFromJSON(json: any): SourcesChartNode {
    return SourcesChartNodeFromJSONTyped(json, false);
}

export function SourcesChartNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourcesChartNode {
    if (json == null) {
        return json;
    }
    return {
        
        'dS': json['DS'] == null ? undefined : json['DS'],
        'categoryUuid': json['category_uuid'] == null ? undefined : json['category_uuid'],
        'code': json['code'],
        'createdAt': json['created_at'] == null ? undefined : json['created_at'],
        'description': json['description'] == null ? undefined : json['description'],
        'formula': json['formula'] == null ? undefined : json['formula'],
        'isPublic': json['is_public'] == null ? undefined : json['is_public'],
        'lowerIsBetter': json['lower_is_better'] == null ? undefined : json['lower_is_better'],
        'name': json['name'],
        'trendYearly': json['trend_yearly'] == null ? undefined : json['trend_yearly'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'] == null ? undefined : json['workspace_uuid'],
        'createdBy': json['created_by'] == null ? undefined : json['created_by'],
        'favoritedBy': json['favorited_by'] == null ? undefined : json['favorited_by'],
        'favoritedByAdmins': json['favorited_by_admins'] == null ? undefined : json['favorited_by_admins'],
        'owners': json['owners'] == null ? undefined : json['owners'],
        'sources': json['sources'] == null ? undefined : (mapValues(json['sources'], SourcesChartNodeFromJSON)),
    };
}

export function SourcesChartNodeToJSON(value?: SourcesChartNode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'DS': value['dS'],
        'category_uuid': value['categoryUuid'],
        'code': value['code'],
        'created_at': value['createdAt'],
        'description': value['description'],
        'formula': value['formula'],
        'is_public': value['isPublic'],
        'lower_is_better': value['lowerIsBetter'],
        'name': value['name'],
        'trend_yearly': value['trendYearly'],
        'unit': value['unit'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'created_by': value['createdBy'],
        'favorited_by': value['favoritedBy'],
        'favorited_by_admins': value['favoritedByAdmins'],
        'owners': value['owners'],
        'sources': value['sources'] == null ? undefined : (mapValues(value['sources'], SourcesChartNodeToJSON)),
    };
}

