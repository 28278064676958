/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { NavSection } from './NavSection';
import {
    NavSectionFromJSON,
    NavSectionFromJSONTyped,
    NavSectionToJSON,
} from './NavSection';

/**
 * 
 * @export
 * @interface UserNavigationSections
 */
export interface UserNavigationSections {
    /**
     * 
     * @type {string}
     * @memberof UserNavigationSections
     */
    userUuid: string;
    /**
     * 
     * @type {string}
     * @memberof UserNavigationSections
     */
    workspaceUuid: string;
    /**
     * 
     * @type {Array<NavSection>}
     * @memberof UserNavigationSections
     */
    reports?: Array<NavSection>;
    /**
     * 
     * @type {Array<NavSection>}
     * @memberof UserNavigationSections
     */
    models?: Array<NavSection>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserNavigationSections
     */
    favoritesUuid?: Array<string>;
    /**
     * 
     * @type {Array<NavSection>}
     * @memberof UserNavigationSections
     */
    favoriteReports?: Array<NavSection>;
    /**
     * 
     * @type {Array<Group>}
     * @memberof UserNavigationSections
     */
    reportsGroups?: Array<Group>;
    /**
     * 
     * @type {Array<Group>}
     * @memberof UserNavigationSections
     */
    modelsGroups?: Array<Group>;
    /**
     * 
     * @type {Array<NavSection>}
     * @memberof UserNavigationSections
     */
    databases?: Array<NavSection>;
    /**
     * 
     * @type {Array<Group>}
     * @memberof UserNavigationSections
     */
    databasesGroups?: Array<Group>;
    /**
     * 
     * @type {Array<NavSection>}
     * @memberof UserNavigationSections
     */
    favoriteModels?: Array<NavSection>;
}

/**
 * Check if a given object implements the UserNavigationSections interface.
 */
export function instanceOfUserNavigationSections(value: object): boolean {
    if (!('userUuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    return true;
}

export function UserNavigationSectionsFromJSON(json: any): UserNavigationSections {
    return UserNavigationSectionsFromJSONTyped(json, false);
}

export function UserNavigationSectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNavigationSections {
    if (json == null) {
        return json;
    }
    return {
        
        'userUuid': json['user_uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'reports': json['reports'] == null ? undefined : ((json['reports'] as Array<any>).map(NavSectionFromJSON)),
        'models': json['models'] == null ? undefined : ((json['models'] as Array<any>).map(NavSectionFromJSON)),
        'favoritesUuid': json['favorites_uuid'] == null ? undefined : json['favorites_uuid'],
        'favoriteReports': json['favorite_reports'] == null ? undefined : ((json['favorite_reports'] as Array<any>).map(NavSectionFromJSON)),
        'reportsGroups': json['reports_groups'] == null ? undefined : ((json['reports_groups'] as Array<any>).map(GroupFromJSON)),
        'modelsGroups': json['models_groups'] == null ? undefined : ((json['models_groups'] as Array<any>).map(GroupFromJSON)),
        'databases': json['databases'] == null ? undefined : ((json['databases'] as Array<any>).map(NavSectionFromJSON)),
        'databasesGroups': json['databases_groups'] == null ? undefined : ((json['databases_groups'] as Array<any>).map(GroupFromJSON)),
        'favoriteModels': json['favorite_models'] == null ? undefined : ((json['favorite_models'] as Array<any>).map(NavSectionFromJSON)),
    };
}

export function UserNavigationSectionsToJSON(value?: UserNavigationSections | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_uuid': value['userUuid'],
        'workspace_uuid': value['workspaceUuid'],
        'reports': value['reports'] == null ? undefined : ((value['reports'] as Array<any>).map(NavSectionToJSON)),
        'models': value['models'] == null ? undefined : ((value['models'] as Array<any>).map(NavSectionToJSON)),
        'favorites_uuid': value['favoritesUuid'],
        'favorite_reports': value['favoriteReports'] == null ? undefined : ((value['favoriteReports'] as Array<any>).map(NavSectionToJSON)),
        'reports_groups': value['reportsGroups'] == null ? undefined : ((value['reportsGroups'] as Array<any>).map(GroupToJSON)),
        'models_groups': value['modelsGroups'] == null ? undefined : ((value['modelsGroups'] as Array<any>).map(GroupToJSON)),
        'databases': value['databases'] == null ? undefined : ((value['databases'] as Array<any>).map(NavSectionToJSON)),
        'databases_groups': value['databasesGroups'] == null ? undefined : ((value['databasesGroups'] as Array<any>).map(GroupToJSON)),
        'favorite_models': value['favoriteModels'] == null ? undefined : ((value['favoriteModels'] as Array<any>).map(NavSectionToJSON)),
    };
}

