/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddMetricToTableParams,
  CreateReportTableParams,
  DownloadTableXlsParams,
  DuplicateReportParams,
  DuplicateReportResponse,
  FavoriteReportParams,
  HTTPValidationError,
  NewReportParams,
  Report,
  SetReportContributorsParams,
  Table,
  TableDataParams,
  TableDataResponse,
  UpdateReportParams,
  UpdateTableAttributesParams,
} from '../models/index';
import {
    AddMetricToTableParamsFromJSON,
    AddMetricToTableParamsToJSON,
    CreateReportTableParamsFromJSON,
    CreateReportTableParamsToJSON,
    DownloadTableXlsParamsFromJSON,
    DownloadTableXlsParamsToJSON,
    DuplicateReportParamsFromJSON,
    DuplicateReportParamsToJSON,
    DuplicateReportResponseFromJSON,
    DuplicateReportResponseToJSON,
    FavoriteReportParamsFromJSON,
    FavoriteReportParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    NewReportParamsFromJSON,
    NewReportParamsToJSON,
    ReportFromJSON,
    ReportToJSON,
    SetReportContributorsParamsFromJSON,
    SetReportContributorsParamsToJSON,
    TableFromJSON,
    TableToJSON,
    TableDataParamsFromJSON,
    TableDataParamsToJSON,
    TableDataResponseFromJSON,
    TableDataResponseToJSON,
    UpdateReportParamsFromJSON,
    UpdateReportParamsToJSON,
    UpdateTableAttributesParamsFromJSON,
    UpdateTableAttributesParamsToJSON,
} from '../models/index';

export interface AddMetricToTableRequest {
    reportUuid: string;
    tableUuid: string;
    workspaceCode: string;
    addMetricToTableParams: AddMetricToTableParams;
}

export interface CreateNewReportRequest {
    workspaceCode: string;
    newReportParams: NewReportParams;
}

export interface CreateReportTableRequest {
    reportUuid: string;
    workspaceCode: string;
    createReportTableParams: CreateReportTableParams;
}

export interface DeleteReportRequest {
    reportUuid: string;
    workspaceCode: string;
}

export interface DeleteReportTableRequest {
    reportUuid: string;
    tableUuid: string;
    workspaceCode: string;
}

export interface DownloadTableCSVRequest {
    tableUuid: string;
    workspaceCode: string;
}

export interface DownloadTableXLSRequest {
    tableUuid: string;
    workspaceCode: string;
    downloadTableXlsParams: DownloadTableXlsParams;
}

export interface DuplicateReportRequest {
    reportUuid: string;
    workspaceCode: string;
    duplicateReportParams: DuplicateReportParams;
}

export interface DuplicateTableRequest {
    reportUuid: string;
    tableUuid: string;
    workspaceCode: string;
}

export interface FetchReportDetailsRequest {
    reportUuid: string;
    workspaceCode: string;
}

export interface FetchTableDataRequest {
    tableUuid: string;
    workspaceCode: string;
    tableDataParams: TableDataParams;
}

export interface ListWorkspaceReportsRequest {
    workspaceCode: string;
}

export interface RemoveMetricFromTableRequest {
    reportUuid: string;
    tableUuid: string;
    workspaceCode: string;
    addMetricToTableParams: AddMetricToTableParams;
}

export interface SetReportContributorsRequest {
    reportUuid: string;
    workspaceCode: string;
    setReportContributorsParams: SetReportContributorsParams;
}

export interface ToggleFavoriteRequest {
    workspaceCode: string;
    favoriteReportParams: FavoriteReportParams;
}

export interface UpdateReportRequest {
    reportUuid: string;
    workspaceCode: string;
    updateReportParams: UpdateReportParams;
}

export interface UpdateTableAttributesRequest {
    tableUuid: string;
    workspaceCode: string;
    updateTableAttributesParams: UpdateTableAttributesParams;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Add Metric To Report Table
     */
    async addMetricToTableRaw(requestParameters: AddMetricToTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TableDataResponse>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling addMetricToTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling addMetricToTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addMetricToTable().'
            );
        }

        if (requestParameters['addMetricToTableParams'] == null) {
            throw new runtime.RequiredError(
                'addMetricToTableParams',
                'Required parameter "addMetricToTableParams" was null or undefined when calling addMetricToTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/tables/{table_uuid}/metrics`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMetricToTableParamsToJSON(requestParameters['addMetricToTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableDataResponseFromJSON(jsonValue));
    }

    /**
     * Add Metric To Report Table
     */
    async addMetricToTable(requestParameters: AddMetricToTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TableDataResponse> {
        const response = await this.addMetricToTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create New Report
     */
    async createNewReportRaw(requestParameters: CreateNewReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createNewReport().'
            );
        }

        if (requestParameters['newReportParams'] == null) {
            throw new runtime.RequiredError(
                'newReportParams',
                'Required parameter "newReportParams" was null or undefined when calling createNewReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewReportParamsToJSON(requestParameters['newReportParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     * Create New Report
     */
    async createNewReport(requestParameters: CreateNewReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.createNewReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Table To Report
     */
    async createReportTableRaw(requestParameters: CreateReportTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Table>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling createReportTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createReportTable().'
            );
        }

        if (requestParameters['createReportTableParams'] == null) {
            throw new runtime.RequiredError(
                'createReportTableParams',
                'Required parameter "createReportTableParams" was null or undefined when calling createReportTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/tables`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReportTableParamsToJSON(requestParameters['createReportTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableFromJSON(jsonValue));
    }

    /**
     * Add Table To Report
     */
    async createReportTable(requestParameters: CreateReportTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Table> {
        const response = await this.createReportTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Report
     */
    async deleteReportRaw(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling deleteReport().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/delete/{report_uuid}`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Report
     */
    async deleteReport(requestParameters: DeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Table From Report
     */
    async deleteReportTableRaw(requestParameters: DeleteReportTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling deleteReportTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling deleteReportTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteReportTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/tables/{table_uuid}/delete`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Table From Report
     */
    async deleteReportTable(requestParameters: DeleteReportTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteReportTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export Report Table Csv
     */
    async downloadTableCSVRaw(requestParameters: DownloadTableCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling downloadTableCSV().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadTableCSV().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/table/{table_uuid}/csv`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export Report Table Csv
     */
    async downloadTableCSV(requestParameters: DownloadTableCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadTableCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export Report Table Xls
     */
    async downloadTableXLSRaw(requestParameters: DownloadTableXLSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling downloadTableXLS().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadTableXLS().'
            );
        }

        if (requestParameters['downloadTableXlsParams'] == null) {
            throw new runtime.RequiredError(
                'downloadTableXlsParams',
                'Required parameter "downloadTableXlsParams" was null or undefined when calling downloadTableXLS().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/table/{table_uuid}/xls`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadTableXlsParamsToJSON(requestParameters['downloadTableXlsParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export Report Table Xls
     */
    async downloadTableXLS(requestParameters: DownloadTableXLSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadTableXLSRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Report
     */
    async duplicateReportRaw(requestParameters: DuplicateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateReportResponse>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling duplicateReport().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling duplicateReport().'
            );
        }

        if (requestParameters['duplicateReportParams'] == null) {
            throw new runtime.RequiredError(
                'duplicateReportParams',
                'Required parameter "duplicateReportParams" was null or undefined when calling duplicateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/duplicate`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateReportParamsToJSON(requestParameters['duplicateReportParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DuplicateReportResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate Report
     */
    async duplicateReport(requestParameters: DuplicateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateReportResponse> {
        const response = await this.duplicateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Table
     */
    async duplicateTableRaw(requestParameters: DuplicateTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Table>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling duplicateTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling duplicateTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling duplicateTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/tables/{table_uuid}/duplicate`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableFromJSON(jsonValue));
    }

    /**
     * Duplicate Table
     */
    async duplicateTable(requestParameters: DuplicateTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Table> {
        const response = await this.duplicateTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Report Details
     */
    async fetchReportDetailsRaw(requestParameters: FetchReportDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling fetchReportDetails().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchReportDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     * Fetch Report Details
     */
    async fetchReportDetails(requestParameters: FetchReportDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.fetchReportDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Table Data
     */
    async fetchTableDataRaw(requestParameters: FetchTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TableDataResponse>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling fetchTableData().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchTableData().'
            );
        }

        if (requestParameters['tableDataParams'] == null) {
            throw new runtime.RequiredError(
                'tableDataParams',
                'Required parameter "tableDataParams" was null or undefined when calling fetchTableData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/table/{table_uuid}`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TableDataParamsToJSON(requestParameters['tableDataParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableDataResponseFromJSON(jsonValue));
    }

    /**
     * Fetch Table Data
     */
    async fetchTableData(requestParameters: FetchTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TableDataResponse> {
        const response = await this.fetchTableDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Workspace Reports
     */
    async listWorkspaceReportsRaw(requestParameters: ListWorkspaceReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Report>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listWorkspaceReports().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportFromJSON));
    }

    /**
     * List Workspace Reports
     */
    async listWorkspaceReports(requestParameters: ListWorkspaceReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Report>> {
        const response = await this.listWorkspaceReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Metric From Report Table
     */
    async removeMetricFromTableRaw(requestParameters: RemoveMetricFromTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TableDataResponse>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling removeMetricFromTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling removeMetricFromTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeMetricFromTable().'
            );
        }

        if (requestParameters['addMetricToTableParams'] == null) {
            throw new runtime.RequiredError(
                'addMetricToTableParams',
                'Required parameter "addMetricToTableParams" was null or undefined when calling removeMetricFromTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/tables/{table_uuid}/metrics/remove`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMetricToTableParamsToJSON(requestParameters['addMetricToTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableDataResponseFromJSON(jsonValue));
    }

    /**
     * Remove Metric From Report Table
     */
    async removeMetricFromTable(requestParameters: RemoveMetricFromTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TableDataResponse> {
        const response = await this.removeMetricFromTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Report Contributors
     */
    async setReportContributorsRaw(requestParameters: SetReportContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling setReportContributors().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setReportContributors().'
            );
        }

        if (requestParameters['setReportContributorsParams'] == null) {
            throw new runtime.RequiredError(
                'setReportContributorsParams',
                'Required parameter "setReportContributorsParams" was null or undefined when calling setReportContributors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/{report_uuid}/contributors`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetReportContributorsParamsToJSON(requestParameters['setReportContributorsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     * Set Report Contributors
     */
    async setReportContributors(requestParameters: SetReportContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.setReportContributorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Favorite
     */
    async toggleFavoriteRaw(requestParameters: ToggleFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling toggleFavorite().'
            );
        }

        if (requestParameters['favoriteReportParams'] == null) {
            throw new runtime.RequiredError(
                'favoriteReportParams',
                'Required parameter "favoriteReportParams" was null or undefined when calling toggleFavorite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/favorite`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteReportParamsToJSON(requestParameters['favoriteReportParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Favorite
     */
    async toggleFavorite(requestParameters: ToggleFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.toggleFavoriteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Report
     */
    async updateReportRaw(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['reportUuid'] == null) {
            throw new runtime.RequiredError(
                'reportUuid',
                'Required parameter "reportUuid" was null or undefined when calling updateReport().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateReport().'
            );
        }

        if (requestParameters['updateReportParams'] == null) {
            throw new runtime.RequiredError(
                'updateReportParams',
                'Required parameter "updateReportParams" was null or undefined when calling updateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/update/{report_uuid}`.replace(`{${"report_uuid"}}`, encodeURIComponent(String(requestParameters['reportUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReportParamsToJSON(requestParameters['updateReportParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Report
     */
    async updateReport(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Table Attributes
     */
    async updateTableAttributesRaw(requestParameters: UpdateTableAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling updateTableAttributes().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateTableAttributes().'
            );
        }

        if (requestParameters['updateTableAttributesParams'] == null) {
            throw new runtime.RequiredError(
                'updateTableAttributesParams',
                'Required parameter "updateTableAttributesParams" was null or undefined when calling updateTableAttributes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/reports/tables/{table_uuid}/attributes/update`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTableAttributesParamsToJSON(requestParameters['updateTableAttributesParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Table Attributes
     */
    async updateTableAttributes(requestParameters: UpdateTableAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateTableAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
