/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Discussion } from './Discussion';
import {
    DiscussionFromJSON,
    DiscussionFromJSONTyped,
    DiscussionToJSON,
} from './Discussion';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { ReportContributor } from './ReportContributor';
import {
    ReportContributorFromJSON,
    ReportContributorFromJSONTyped,
    ReportContributorToJSON,
} from './ReportContributor';
import type { Table } from './Table';
import {
    TableFromJSON,
    TableFromJSONTyped,
    TableToJSON,
} from './Table';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    emoji?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    createdByUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    lastAccessedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    localId: number;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    isPublic?: boolean;
    /**
     * 
     * @type {Array<Discussion>}
     * @memberof Report
     */
    unresolvedDiscussions?: Array<Discussion>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Report
     */
    favoritedBy?: Array<User>;
    /**
     * 
     * @type {Array<Table>}
     * @memberof Report
     */
    tables: Array<Table>;
    /**
     * 
     * @type {Array<ReportContributor>}
     * @memberof Report
     */
    contributors?: Array<ReportContributor>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    groupUuid?: string;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof Report
     */
    filters?: Array<Filter>;
}

/**
 * Check if a given object implements the Report interface.
 */
export function instanceOfReport(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('createdByUuid' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('lastAccessedAt' in value)) return false;
    if (!('localId' in value)) return false;
    if (!('tables' in value)) return false;
    return true;
}

export function ReportFromJSON(json: any): Report {
    return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Report {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'emoji': json['emoji'] == null ? undefined : json['emoji'],
        'createdByUuid': json['created_by_uuid'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'lastAccessedAt': (new Date(json['last_accessed_at'])),
        'localId': json['local_id'],
        'isPublic': json['is_public'] == null ? undefined : json['is_public'],
        'unresolvedDiscussions': json['unresolved_discussions'] == null ? undefined : ((json['unresolved_discussions'] as Array<any>).map(DiscussionFromJSON)),
        'favoritedBy': json['favorited_by'] == null ? undefined : ((json['favorited_by'] as Array<any>).map(UserFromJSON)),
        'tables': ((json['tables'] as Array<any>).map(TableFromJSON)),
        'contributors': json['contributors'] == null ? undefined : ((json['contributors'] as Array<any>).map(ReportContributorFromJSON)),
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
    };
}

export function ReportToJSON(value?: Report | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'description': value['description'],
        'emoji': value['emoji'],
        'created_by_uuid': value['createdByUuid'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'last_accessed_at': ((value['lastAccessedAt']).toISOString()),
        'local_id': value['localId'],
        'is_public': value['isPublic'],
        'unresolved_discussions': value['unresolvedDiscussions'] == null ? undefined : ((value['unresolvedDiscussions'] as Array<any>).map(DiscussionToJSON)),
        'favorited_by': value['favoritedBy'] == null ? undefined : ((value['favoritedBy'] as Array<any>).map(UserToJSON)),
        'tables': ((value['tables'] as Array<any>).map(TableToJSON)),
        'contributors': value['contributors'] == null ? undefined : ((value['contributors'] as Array<any>).map(ReportContributorToJSON)),
        'group_uuid': value['groupUuid'],
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
    };
}

