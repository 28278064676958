import * as Y from "yjs";
import { useConfig } from './useConfig';
import { Extension as BaseExt, Node, Content } from '@tiptap/core';
import { EditorView } from "@tiptap/pm/view";
import { Editor } from '@tiptap/vue-3';
import { WebsocketProvider } from "y-websocket";
import { DateTime } from "luxon";
import {Editor as CoreEditor} from "@tiptap/core";
import {Transaction} from "prosemirror-state";
import { defaultPeriod } from "./usePreferences";

import Paragraph from '@tiptap/extension-paragraph';
import Typography from "@tiptap/extension-typography";
import Heading from "@tiptap/extension-heading";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Code from '@tiptap/extension-code';
import CodeBlock from "@tiptap/extension-code-block";
import BlockQuote from "@tiptap/extension-blockquote";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Text from "@tiptap/extension-text";
import TextStyle from '@tiptap/extension-text-style';
import Bold from "@tiptap/extension-bold";
import HardBreak from "@tiptap/extension-hard-break";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Focus from '@tiptap/extension-focus';
import Highlight from '@tiptap/extension-highlight';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import TextAlign from '@tiptap/extension-text-align';
import UniqueID from '@tiptap/extension-unique-id';
import Link from "@tiptap/extension-link";
import { AllSelection } from "@tiptap/pm/state";


type Extension = BaseExt | Node;

const config = useConfig();

const CONNECT_MAX_ATTEMPTS = 10;
const CONNECT_RETRY_INTERVAL = 500;

const SYNC_MAX_ATTEMPTS = 100;
const SYNC_RETRY_INTERVAL = 1000;

async function waitForYjsProviderConnected(
  provider: WebsocketProvider, 
  num_attempts = 0,
  interval = CONNECT_RETRY_INTERVAL
): Promise<boolean> {
  if (num_attempts > CONNECT_MAX_ATTEMPTS) return false;
  provider.connect();
  await new Promise(resolve => setTimeout(resolve, interval));
  const connected = provider.wsconnected;
  if (connected) return true;
  return waitForYjsProviderConnected(provider, num_attempts + 1);
}
async function waitForYjsProviderSync(
  provider: WebsocketProvider, 
  num_attempts = 0,
  interval = SYNC_RETRY_INTERVAL
): Promise<boolean> {
  if (num_attempts > SYNC_MAX_ATTEMPTS) return false;
  const synced = provider.synced;
  if (synced) return true;
  await new Promise(resolve => setTimeout(resolve, interval));
  return waitForYjsProviderSync(provider, num_attempts + 1);
}

export const COLLAB_ENTITY_TYPES = ['workspace', 'report', 'model'] as const;

interface ProviderExtraParams {
  workspaceUuid?: string
  reportUuid?: string
  modelUuid?: string
  schemaVersion?: string
  copyFrom?: string
  updates?: string
}

export const useProvider = async (
  room: string,
  doc: Y.Doc,
  entityType: typeof COLLAB_ENTITY_TYPES[number],
  waitSync = false,
  extraParams: ProviderExtraParams = {}
) => {
  const provider = new WebsocketProvider(
    config.collabServer,
    room,
    doc,
    {
      connect: false, // Don't try to connect automatically
      params: { 
        schemaVersion: config.schemaVersion,
        entityType,
        ...extraParams
      }
    }
  );
  // Unlike the HocuspocusProvider, the WebsocketProvider does not 
  // have a maxAttempts option. So we need to implement our own
  // retry logic.
  const connectedSuccesfully = await waitForYjsProviderConnected(provider);
  if (!connectedSuccesfully) {
    provider.ws?.close();
    provider.disconnect();
    provider.destroy();
    throw new Error("Could not connect to collab server");
  }
  if (waitSync && !(await waitForYjsProviderSync(provider))) {
    provider.ws?.close();
    provider.disconnect();
    provider.destroy();
    throw new Error("Could not sync with collab server");
  }
  return provider;
};

// The .configure wasnt overriding the 'content' attribute to row,
// looking at the schema I found it was always block+
const Doc = Node.create({
  name: 'doc',
  topNode: true,
  content: 'row*',
  priority: 9999
});


const baseExtensions = [
  Doc,
  Typography,
  Focus,
  Heading,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Highlight,
  Code,
  CodeBlock,
  BlockQuote,
  HorizontalRule,
  Link,
  TextStyle,
  Text,
  Bold,
  HardBreak,
  Italic,
  Strike,
  TaskList,
  TaskItem,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'right', 'center', 'justify']
  }),
  UniqueID.configure({ types: ["row", "fragment"] })
];

const blockDefaultPeriod = defaultPeriod();
const defaults = {
  tableId: "",
  tableTitle: "",
  chartTitle: "",
  metricId: "",
  scenarioId: "",
  selectedScenario: "",
  comparisonScenario: "",
  totalDeviation: false,
  percentageDeviation: false,
  selectedScenarios: [],
  selectedFilters: [],
  selectedDimensions: [],
  selectedMetrics: [],
  selectedPeriod: [blockDefaultPeriod.start?.toJSON(), blockDefaultPeriod.end?.toJSON()],
  selectedPeriodSize: "month",
  rowStyles: {},
  metricsDimensions: '{}',
  tableStructure: '[]',
  columnDimensions: [],
  rowDimensions: [],
  includePeriod: true,
  removeColSubtotals: true,
  lastUpdatedAt: DateTime.utc().toMillis(),
  periodSeparator: DateTime.now().startOf('month').toISODate(),
  chartType: "bar",
  useFiscalYear: true,
} as const;

export function getDefault<T extends keyof typeof defaults>(field: T) {
  return defaults[field];
}

interface UseEditorParams {
  extensions?: Extension[];
  content?: Content;
  disabled?: boolean;
  readonly?: boolean;
  onUpdate?: (props: { editor: CoreEditor, transaction: Transaction} ) => void
  onTransaction?: (props: { editor: CoreEditor, transaction: Transaction} ) => void
  onCreate?: (props: { editor: CoreEditor } ) => void,
  onSelectionUpdate?: (props: { editor: CoreEditor, transaction: Transaction }) => void,
  onBlur?: (props: { editor: CoreEditor, event: FocusEvent, transaction: Transaction }) => void
}
export const useEditor = ({
  extensions = [],
  disabled = false,
  readonly = false,
  onUpdate = () => ({}),
  onTransaction = () => ({}),
  onCreate = () => ({}),
  onBlur = () => ({}),
  onSelectionUpdate = () => ({})
}: UseEditorParams) => {
  const allExtensions = [
    ...baseExtensions,
    ...extensions
  ];

  const editable = !disabled && !readonly;
  return new Editor({ 
    extensions: allExtensions, 
    injectCSS: true, 
    editable,
    onSelectionUpdate,
    onUpdate,
    onTransaction,
    onCreate,
    onBlur,
    editorProps: {
      handleDrop(view, event, slice, moved) {
        console.log("DROP", {view, event, slice, moved});
      },
      handleTextInput(view) {
        const startSelectionNode = view.state.selection.$head.parent;
        const endSelectionNode = view.state.selection.$anchor.parent;
        // Prevent input if selection is not in paragraph or
        // selection is selecting diferent paragraphs.
        if (
          startSelectionNode != endSelectionNode
          || startSelectionNode.type.name != 'paragraph'
          || endSelectionNode.type.name != 'paragraph'
        ) {
          return true;
        }
      },
      handleDOMEvents: {
        drop(view, event) {
          // Allow dropping text into paragraphs
          const text = event.dataTransfer?.getData('text');
          if (text && event.target?.nodeName === 'P') return false;

          // Other drop events are captured in fragment drop zones
          // for drag-dropping of blocks
          event.preventDefault();
        },
        keydown: (view, event) => {
          if (event.key === "Enter") {
            const hasTextBlock = view.state.doc.content?.content?.some((n) => 
              n.content?.content.some( n => 
                n.content?.content.some(sn => ["paragraph", "heading", "codeBlock", "taskList", "bulletList", "orderedList", "blockquote"].includes(sn.type?.name))
              )
            );
            if (!hasTextBlock) {
              event.preventDefault();
              event.stopPropagation();
            }
          }
        }
      }
    },
  });
};
