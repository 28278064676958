/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MappingOperation
 */
export interface MappingOperation {
    /**
     * 
     * @type {string}
     * @memberof MappingOperation
     */
    operation: MappingOperationOperationEnum;
    /**
     * 
     * @type {number}
     * @memberof MappingOperation
     */
    value: number;
}


/**
 * @export
 */
export const MappingOperationOperationEnum = {
    First: 'first',
    Last: 'last'
} as const;
export type MappingOperationOperationEnum = typeof MappingOperationOperationEnum[keyof typeof MappingOperationOperationEnum];


/**
 * Check if a given object implements the MappingOperation interface.
 */
export function instanceOfMappingOperation(value: object): boolean {
    if (!('operation' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function MappingOperationFromJSON(json: any): MappingOperation {
    return MappingOperationFromJSONTyped(json, false);
}

export function MappingOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'value': json['value'],
    };
}

export function MappingOperationToJSON(value?: MappingOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operation': value['operation'],
        'value': value['value'],
    };
}

