/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateGroupParams,
  Group,
  HTTPValidationError,
  UpdateGroupParams,
  UserNavigationSections,
} from '../models/index';
import {
    CreateGroupParamsFromJSON,
    CreateGroupParamsToJSON,
    GroupFromJSON,
    GroupToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    UpdateGroupParamsFromJSON,
    UpdateGroupParamsToJSON,
    UserNavigationSectionsFromJSON,
    UserNavigationSectionsToJSON,
} from '../models/index';

export interface CreateGroupRequest {
    workspaceCode: string;
    createGroupParams: CreateGroupParams;
}

export interface DeleteGroupRequest {
    groupUuid: string;
    workspaceCode: string;
}

export interface FetchUserNavigationSectionsRequest {
    workspaceCode: string;
}

export interface UpdateGroupRequest {
    groupUuid: string;
    workspaceCode: string;
    updateGroupParams: UpdateGroupParams;
}

/**
 * 
 */
export class NavApi extends runtime.BaseAPI {

    /**
     * Create Group
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Group>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createGroup().'
            );
        }

        if (requestParameters['createGroupParams'] == null) {
            throw new runtime.RequiredError(
                'createGroupParams',
                'Required parameter "createGroupParams" was null or undefined when calling createGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/nav/groups`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGroupParamsToJSON(requestParameters['createGroupParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
    }

    /**
     * Create Group
     */
    async createGroup(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Group> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Reports Group
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['groupUuid'] == null) {
            throw new runtime.RequiredError(
                'groupUuid',
                'Required parameter "groupUuid" was null or undefined when calling deleteGroup().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/nav/groups/{group_uuid}`.replace(`{${"group_uuid"}}`, encodeURIComponent(String(requestParameters['groupUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Reports Group
     */
    async deleteGroup(requestParameters: DeleteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch User Navigation Sections
     */
    async fetchUserNavigationSectionsRaw(requestParameters: FetchUserNavigationSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserNavigationSections>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchUserNavigationSections().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/nav`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserNavigationSectionsFromJSON(jsonValue));
    }

    /**
     * Fetch User Navigation Sections
     */
    async fetchUserNavigationSections(requestParameters: FetchUserNavigationSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserNavigationSections> {
        const response = await this.fetchUserNavigationSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Reports Group
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['groupUuid'] == null) {
            throw new runtime.RequiredError(
                'groupUuid',
                'Required parameter "groupUuid" was null or undefined when calling updateGroup().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateGroup().'
            );
        }

        if (requestParameters['updateGroupParams'] == null) {
            throw new runtime.RequiredError(
                'updateGroupParams',
                'Required parameter "updateGroupParams" was null or undefined when calling updateGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/nav/groups/{group_uuid}`.replace(`{${"group_uuid"}}`, encodeURIComponent(String(requestParameters['groupUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGroupParamsToJSON(requestParameters['updateGroupParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Reports Group
     */
    async updateGroup(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
