/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface UpdateModelPeriodParams
 */
export interface UpdateModelPeriodParams {
    /**
     * 
     * @type {Period}
     * @memberof UpdateModelPeriodParams
     */
    period: Period;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModelPeriodParams
     */
    fillExpressions: boolean;
}

/**
 * Check if a given object implements the UpdateModelPeriodParams interface.
 */
export function instanceOfUpdateModelPeriodParams(value: object): boolean {
    if (!('period' in value)) return false;
    if (!('fillExpressions' in value)) return false;
    return true;
}

export function UpdateModelPeriodParamsFromJSON(json: any): UpdateModelPeriodParams {
    return UpdateModelPeriodParamsFromJSONTyped(json, false);
}

export function UpdateModelPeriodParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelPeriodParams {
    if (json == null) {
        return json;
    }
    return {
        
        'period': PeriodFromJSON(json['period']),
        'fillExpressions': json['fill_expressions'],
    };
}

export function UpdateModelPeriodParamsToJSON(value?: UpdateModelPeriodParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': PeriodToJSON(value['period']),
        'fill_expressions': value['fillExpressions'],
    };
}

