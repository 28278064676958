/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DuplicateReportResponse
 */
export interface DuplicateReportResponse {
    /**
     * 
     * @type {string}
     * @memberof DuplicateReportResponse
     */
    newReport: string;
    /**
     * 
     * @type {object}
     * @memberof DuplicateReportResponse
     */
    mapping: object;
}

/**
 * Check if a given object implements the DuplicateReportResponse interface.
 */
export function instanceOfDuplicateReportResponse(value: object): boolean {
    if (!('newReport' in value)) return false;
    if (!('mapping' in value)) return false;
    return true;
}

export function DuplicateReportResponseFromJSON(json: any): DuplicateReportResponse {
    return DuplicateReportResponseFromJSONTyped(json, false);
}

export function DuplicateReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateReportResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'newReport': json['new_report'],
        'mapping': json['mapping'],
    };
}

export function DuplicateReportResponseToJSON(value?: DuplicateReportResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'new_report': value['newReport'],
        'mapping': value['mapping'],
    };
}

