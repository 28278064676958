/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConnectionStream } from './ConnectionStream';
import {
    ConnectionStreamFromJSON,
    ConnectionStreamFromJSONTyped,
    ConnectionStreamToJSON,
} from './ConnectionStream';
import type { MetricAllowRule } from './MetricAllowRule';
import {
    MetricAllowRuleFromJSON,
    MetricAllowRuleFromJSONTyped,
    MetricAllowRuleToJSON,
} from './MetricAllowRule';

/**
 * 
 * @export
 * @interface ExtendedConnection
 */
export interface ExtendedConnection {
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    code: string;
    /**
     * 
     * @type {Array<MetricAllowRule>}
     * @memberof ExtendedConnection
     */
    metrics: Array<MetricAllowRule>;
    /**
     * 
     * @type {Array<ConnectionStream>}
     * @memberof ExtendedConnection
     */
    streams?: Array<ConnectionStream>;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedConnection
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedConnection
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    type: ExtendedConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    connectorCode: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedConnection
     */
    connectorName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedConnection
     */
    canReset?: boolean;
}


/**
 * @export
 */
export const ExtendedConnectionTypeEnum = {
    Custom: 'custom',
    Hris: 'hris',
    Accounting: 'accounting',
    Ats: 'ats',
    Crm: 'crm',
    Ticketing: 'ticketing'
} as const;
export type ExtendedConnectionTypeEnum = typeof ExtendedConnectionTypeEnum[keyof typeof ExtendedConnectionTypeEnum];


/**
 * Check if a given object implements the ExtendedConnection interface.
 */
export function instanceOfExtendedConnection(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('metrics' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('type' in value)) return false;
    if (!('connectorCode' in value)) return false;
    if (!('connectorName' in value)) return false;
    return true;
}

export function ExtendedConnectionFromJSON(json: any): ExtendedConnection {
    return ExtendedConnectionFromJSONTyped(json, false);
}

export function ExtendedConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedConnection {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'code': json['code'],
        'metrics': ((json['metrics'] as Array<any>).map(MetricAllowRuleFromJSON)),
        'streams': json['streams'] == null ? undefined : ((json['streams'] as Array<any>).map(ConnectionStreamFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'type': json['type'],
        'connectorCode': json['connector_code'],
        'connectorName': json['connector_name'],
        'canReset': json['can_reset'] == null ? undefined : json['can_reset'],
    };
}

export function ExtendedConnectionToJSON(value?: ExtendedConnection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'code': value['code'],
        'metrics': ((value['metrics'] as Array<any>).map(MetricAllowRuleToJSON)),
        'streams': value['streams'] == null ? undefined : ((value['streams'] as Array<any>).map(ConnectionStreamToJSON)),
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'type': value['type'],
        'connector_code': value['connectorCode'],
        'connector_name': value['connectorName'],
        'can_reset': value['canReset'],
    };
}

