/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelParams
 */
export interface UpdateModelParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateModelParams
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelParams
     */
    yearTotals?: UpdateModelParamsYearTotalsEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelParams
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelParams
     */
    emoji?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelParams
     */
    groupUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModelParams
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModelParams
     */
    isHidden?: boolean;
}


/**
 * @export
 */
export const UpdateModelParamsYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type UpdateModelParamsYearTotalsEnum = typeof UpdateModelParamsYearTotalsEnum[keyof typeof UpdateModelParamsYearTotalsEnum];


/**
 * Check if a given object implements the UpdateModelParams interface.
 */
export function instanceOfUpdateModelParams(value: object): boolean {
    return true;
}

export function UpdateModelParamsFromJSON(json: any): UpdateModelParams {
    return UpdateModelParamsFromJSONTyped(json, false);
}

export function UpdateModelParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
        'description': json['description'] == null ? undefined : json['description'],
        'emoji': json['emoji'] == null ? undefined : json['emoji'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'isPublic': json['is_public'] == null ? undefined : json['is_public'],
        'isHidden': json['is_hidden'] == null ? undefined : json['is_hidden'],
    };
}

export function UpdateModelParamsToJSON(value?: UpdateModelParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'year_totals': value['yearTotals'],
        'description': value['description'],
        'emoji': value['emoji'],
        'group_uuid': value['groupUuid'],
        'is_public': value['isPublic'],
        'is_hidden': value['isHidden'],
    };
}

