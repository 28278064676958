/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateVariableDimensionValueParams
 */
export interface UpdateVariableDimensionValueParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateVariableDimensionValueParams
     */
    value?: string;
}

/**
 * Check if a given object implements the UpdateVariableDimensionValueParams interface.
 */
export function instanceOfUpdateVariableDimensionValueParams(value: object): boolean {
    return true;
}

export function UpdateVariableDimensionValueParamsFromJSON(json: any): UpdateVariableDimensionValueParams {
    return UpdateVariableDimensionValueParamsFromJSONTyped(json, false);
}

export function UpdateVariableDimensionValueParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateVariableDimensionValueParams {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function UpdateVariableDimensionValueParamsToJSON(value?: UpdateVariableDimensionValueParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

