/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMetricAggregateFunctionParams
 */
export interface UpdateMetricAggregateFunctionParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateMetricAggregateFunctionParams
     */
    value: UpdateMetricAggregateFunctionParamsValueEnum;
}


/**
 * @export
 */
export const UpdateMetricAggregateFunctionParamsValueEnum = {
    Sum: 'sum',
    Avg: 'avg',
    First: 'first',
    Last: 'last',
    Count: 'count',
    Min: 'min',
    Max: 'max',
    Formula: 'formula',
    Cumsum: 'cumsum',
    CumsumYear: 'cumsum_year',
    CumsumQuarter: 'cumsum_quarter'
} as const;
export type UpdateMetricAggregateFunctionParamsValueEnum = typeof UpdateMetricAggregateFunctionParamsValueEnum[keyof typeof UpdateMetricAggregateFunctionParamsValueEnum];


/**
 * Check if a given object implements the UpdateMetricAggregateFunctionParams interface.
 */
export function instanceOfUpdateMetricAggregateFunctionParams(value: object): boolean {
    if (!('value' in value)) return false;
    return true;
}

export function UpdateMetricAggregateFunctionParamsFromJSON(json: any): UpdateMetricAggregateFunctionParams {
    return UpdateMetricAggregateFunctionParamsFromJSONTyped(json, false);
}

export function UpdateMetricAggregateFunctionParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMetricAggregateFunctionParams {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function UpdateMetricAggregateFunctionParamsToJSON(value?: UpdateMetricAggregateFunctionParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

