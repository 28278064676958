/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishModelToCommunityParams
 */
export interface PublishModelToCommunityParams {
    /**
     * 
     * @type {string}
     * @memberof PublishModelToCommunityParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PublishModelToCommunityParams
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublishModelToCommunityParams
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PublishModelToCommunityParams
     */
    likes: number;
    /**
     * 
     * @type {number}
     * @memberof PublishModelToCommunityParams
     */
    usedByCount: number;
    /**
     * 
     * @type {string}
     * @memberof PublishModelToCommunityParams
     */
    previewImageUrl?: string;
}

/**
 * Check if a given object implements the PublishModelToCommunityParams interface.
 */
export function instanceOfPublishModelToCommunityParams(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('likes' in value)) return false;
    if (!('usedByCount' in value)) return false;
    return true;
}

export function PublishModelToCommunityParamsFromJSON(json: any): PublishModelToCommunityParams {
    return PublishModelToCommunityParamsFromJSONTyped(json, false);
}

export function PublishModelToCommunityParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishModelToCommunityParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'likes': json['likes'],
        'usedByCount': json['used_by_count'],
        'previewImageUrl': json['preview_image_url'] == null ? undefined : json['preview_image_url'],
    };
}

export function PublishModelToCommunityParamsToJSON(value?: PublishModelToCommunityParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'tags': value['tags'],
        'likes': value['likes'],
        'used_by_count': value['usedByCount'],
        'preview_image_url': value['previewImageUrl'],
    };
}

