/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteUploaderParams
 */
export interface FavoriteUploaderParams {
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteUploaderParams
     */
    favorited: boolean;
}

/**
 * Check if a given object implements the FavoriteUploaderParams interface.
 */
export function instanceOfFavoriteUploaderParams(value: object): boolean {
    if (!('favorited' in value)) return false;
    return true;
}

export function FavoriteUploaderParamsFromJSON(json: any): FavoriteUploaderParams {
    return FavoriteUploaderParamsFromJSONTyped(json, false);
}

export function FavoriteUploaderParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteUploaderParams {
    if (json == null) {
        return json;
    }
    return {
        
        'favorited': json['favorited'],
    };
}

export function FavoriteUploaderParamsToJSON(value?: FavoriteUploaderParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'favorited': value['favorited'],
    };
}

