/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Data } from './Data';
import {
    DataFromJSON,
    DataFromJSONTyped,
    DataToJSON,
} from './Data';

/**
 * 
 * @export
 * @interface TableDataResponse
 */
export interface TableDataResponse {
    /**
     * 
     * @type {Data}
     * @memberof TableDataResponse
     */
    data: Data | null;
    /**
     * 
     * @type {Date}
     * @memberof TableDataResponse
     */
    computedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TableDataResponse
     */
    error?: boolean;
}

/**
 * Check if a given object implements the TableDataResponse interface.
 */
export function instanceOfTableDataResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function TableDataResponseFromJSON(json: any): TableDataResponse {
    return TableDataResponseFromJSONTyped(json, false);
}

export function TableDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableDataResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DataFromJSON(json['data']),
        'computedAt': json['computed_at'] == null ? undefined : (new Date(json['computed_at'])),
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function TableDataResponseToJSON(value?: TableDataResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': DataToJSON(value['data']),
        'computed_at': value['computedAt'] == null ? undefined : ((value['computedAt'] as any).toISOString()),
        'error': value['error'],
    };
}

