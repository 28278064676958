/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsRecomputingResponse
 */
export interface IsRecomputingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsRecomputingResponse
     */
    isRecomputing: boolean;
}

/**
 * Check if a given object implements the IsRecomputingResponse interface.
 */
export function instanceOfIsRecomputingResponse(value: object): boolean {
    if (!('isRecomputing' in value)) return false;
    return true;
}

export function IsRecomputingResponseFromJSON(json: any): IsRecomputingResponse {
    return IsRecomputingResponseFromJSONTyped(json, false);
}

export function IsRecomputingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsRecomputingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isRecomputing': json['is_recomputing'],
    };
}

export function IsRecomputingResponseToJSON(value?: IsRecomputingResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'is_recomputing': value['isRecomputing'],
    };
}

