/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosingDate } from './ClosingDate';
import {
    ClosingDateFromJSON,
    ClosingDateFromJSONTyped,
    ClosingDateToJSON,
} from './ClosingDate';

/**
 * 
 * @export
 * @interface CreateScenario
 */
export interface CreateScenario {
    /**
     * 
     * @type {string}
     * @memberof CreateScenario
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScenario
     */
    color: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateScenario
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScenario
     */
    sourceScenario?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateScenario
     */
    periodOffset?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScenario
     */
    periodSize?: CreateScenarioPeriodSizeEnum;
    /**
     * 
     * @type {ClosingDate}
     * @memberof CreateScenario
     */
    closingDate?: ClosingDate;
    /**
     * 
     * @type {string}
     * @memberof CreateScenario
     */
    actualsScenarioUuid?: string;
}


/**
 * @export
 */
export const CreateScenarioPeriodSizeEnum = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year'
} as const;
export type CreateScenarioPeriodSizeEnum = typeof CreateScenarioPeriodSizeEnum[keyof typeof CreateScenarioPeriodSizeEnum];


/**
 * Check if a given object implements the CreateScenario interface.
 */
export function instanceOfCreateScenario(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('color' in value)) return false;
    return true;
}

export function CreateScenarioFromJSON(json: any): CreateScenario {
    return CreateScenarioFromJSONTyped(json, false);
}

export function CreateScenarioFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScenario {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'color': json['color'],
        'description': json['description'] == null ? undefined : json['description'],
        'sourceScenario': json['source_scenario'] == null ? undefined : json['source_scenario'],
        'periodOffset': json['period_offset'] == null ? undefined : json['period_offset'],
        'periodSize': json['period_size'] == null ? undefined : json['period_size'],
        'closingDate': json['closing_date'] == null ? undefined : ClosingDateFromJSON(json['closing_date']),
        'actualsScenarioUuid': json['actuals_scenario_uuid'] == null ? undefined : json['actuals_scenario_uuid'],
    };
}

export function CreateScenarioToJSON(value?: CreateScenario | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'color': value['color'],
        'description': value['description'],
        'source_scenario': value['sourceScenario'],
        'period_offset': value['periodOffset'],
        'period_size': value['periodSize'],
        'closing_date': ClosingDateToJSON(value['closingDate']),
        'actuals_scenario_uuid': value['actualsScenarioUuid'],
    };
}

