/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetricCategory
 */
export interface MetricCategory {
    /**
     * 
     * @type {string}
     * @memberof MetricCategory
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof MetricCategory
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof MetricCategory
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricCategory
     */
    name: string;
}

/**
 * Check if a given object implements the MetricCategory interface.
 */
export function instanceOfMetricCategory(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function MetricCategoryFromJSON(json: any): MetricCategory {
    return MetricCategoryFromJSONTyped(json, false);
}

export function MetricCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'color': json['color'] == null ? undefined : json['color'],
        'name': json['name'],
    };
}

export function MetricCategoryToJSON(value?: MetricCategory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'color': value['color'],
        'name': value['name'],
    };
}

