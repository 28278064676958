/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Updates } from './Updates';
import {
    UpdatesFromJSON,
    UpdatesFromJSONTyped,
    UpdatesToJSON,
} from './Updates';

/**
 * 
 * @export
 * @interface UpdateDatabaseParams
 */
export interface UpdateDatabaseParams {
    /**
     * 
     * @type {{ [key: string]: Updates; }}
     * @memberof UpdateDatabaseParams
     */
    updates: { [key: string]: Updates; };
}

/**
 * Check if a given object implements the UpdateDatabaseParams interface.
 */
export function instanceOfUpdateDatabaseParams(value: object): boolean {
    if (!('updates' in value)) return false;
    return true;
}

export function UpdateDatabaseParamsFromJSON(json: any): UpdateDatabaseParams {
    return UpdateDatabaseParamsFromJSONTyped(json, false);
}

export function UpdateDatabaseParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDatabaseParams {
    if (json == null) {
        return json;
    }
    return {
        
        'updates': (mapValues(json['updates'], UpdatesFromJSON)),
    };
}

export function UpdateDatabaseParamsToJSON(value?: UpdateDatabaseParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'updates': (mapValues(value['updates'], UpdatesToJSON)),
    };
}

