/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetricUnit
 */
export interface MetricUnit {
    /**
     * 
     * @type {string}
     * @memberof MetricUnit
     */
    key: MetricUnitKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof MetricUnit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MetricUnit
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof MetricUnit
     */
    colorClass: string;
}


/**
 * @export
 */
export const MetricUnitKeyEnum = {
    CurrencyEuro: 'currency_euro',
    CurrencyDollar: 'currency_dollar',
    Count: 'count',
    Percentage: 'percentage',
    Date: 'date'
} as const;
export type MetricUnitKeyEnum = typeof MetricUnitKeyEnum[keyof typeof MetricUnitKeyEnum];


/**
 * Check if a given object implements the MetricUnit interface.
 */
export function instanceOfMetricUnit(value: object): boolean {
    if (!('key' in value)) return false;
    if (!('name' in value)) return false;
    if (!('symbol' in value)) return false;
    if (!('colorClass' in value)) return false;
    return true;
}

export function MetricUnitFromJSON(json: any): MetricUnit {
    return MetricUnitFromJSONTyped(json, false);
}

export function MetricUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricUnit {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'symbol': json['symbol'],
        'colorClass': json['color_class'],
    };
}

export function MetricUnitToJSON(value?: MetricUnit | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'name': value['name'],
        'symbol': value['symbol'],
        'color_class': value['colorClass'],
    };
}

