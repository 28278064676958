/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';

/**
 * 
 * @export
 * @interface ConnectorStream
 */
export interface ConnectorStream {
    /**
     * 
     * @type {string}
     * @memberof ConnectorStream
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorStream
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorStream
     */
    name: string;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof ConnectorStream
     */
    schema?: Array<SchemaColumn>;
    /**
     * 
     * @type {string}
     * @memberof ConnectorStream
     */
    connectorName: string;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof ConnectorStream
     */
    rawSchema?: Array<SchemaColumn>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectorStream
     */
    dependencies?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectorStream
     */
    available?: boolean;
}

/**
 * Check if a given object implements the ConnectorStream interface.
 */
export function instanceOfConnectorStream(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('code' in value)) return false;
    if (!('name' in value)) return false;
    if (!('connectorName' in value)) return false;
    return true;
}

export function ConnectorStreamFromJSON(json: any): ConnectorStream {
    return ConnectorStreamFromJSONTyped(json, false);
}

export function ConnectorStreamFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorStream {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'code': json['code'],
        'name': json['name'],
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'connectorName': json['connector_name'],
        'rawSchema': json['raw_schema'] == null ? undefined : ((json['raw_schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'dependencies': json['dependencies'] == null ? undefined : json['dependencies'],
        'available': json['available'] == null ? undefined : json['available'],
    };
}

export function ConnectorStreamToJSON(value?: ConnectorStream | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'code': value['code'],
        'name': value['name'],
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
        'connector_name': value['connectorName'],
        'raw_schema': value['rawSchema'] == null ? undefined : ((value['rawSchema'] as Array<any>).map(SchemaColumnToJSON)),
        'dependencies': value['dependencies'],
        'available': value['available'],
    };
}

