/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkspaceMembership } from './WorkspaceMembership';
import {
    WorkspaceMembershipFromJSON,
    WorkspaceMembershipFromJSONTyped,
    WorkspaceMembershipToJSON,
} from './WorkspaceMembership';

/**
 * 
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    lastName: string;
    /**
     * 
     * @type {Blob}
     * @memberof AuthUser
     */
    avatar?: Blob;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    registeredOrgName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUser
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUser
     */
    isHidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    decimalsSeparator?: string;
    /**
     * 
     * @type {Array<WorkspaceMembership>}
     * @memberof AuthUser
     */
    memberships?: Array<WorkspaceMembership>;
}

/**
 * Check if a given object implements the AuthUser interface.
 */
export function instanceOfAuthUser(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('lastName' in value)) return false;
    if (!('color' in value)) return false;
    if (!('email' in value)) return false;
    return true;
}

export function AuthUserFromJSON(json: any): AuthUser {
    return AuthUserFromJSONTyped(json, false);
}

export function AuthUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUser {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'lastName': json['last_name'],
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'color': json['color'],
        'email': json['email'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'phoneCountryCode': json['phone_country_code'] == null ? undefined : json['phone_country_code'],
        'registeredOrgName': json['registered_org_name'] == null ? undefined : json['registered_org_name'],
        'isAdmin': json['is_admin'] == null ? undefined : json['is_admin'],
        'isHidden': json['is_hidden'] == null ? undefined : json['is_hidden'],
        'decimalsSeparator': json['decimals_separator'] == null ? undefined : json['decimals_separator'],
        'memberships': json['memberships'] == null ? undefined : ((json['memberships'] as Array<any>).map(WorkspaceMembershipFromJSON)),
    };
}

export function AuthUserToJSON(value?: AuthUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'last_name': value['lastName'],
        'avatar': value['avatar'],
        'color': value['color'],
        'email': value['email'],
        'phone_number': value['phoneNumber'],
        'phone_country_code': value['phoneCountryCode'],
        'registered_org_name': value['registeredOrgName'],
        'is_admin': value['isAdmin'],
        'is_hidden': value['isHidden'],
        'decimals_separator': value['decimalsSeparator'],
        'memberships': value['memberships'] == null ? undefined : ((value['memberships'] as Array<any>).map(WorkspaceMembershipToJSON)),
    };
}

