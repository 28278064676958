/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SourcesTableRow } from './SourcesTableRow';
import {
    SourcesTableRowFromJSON,
    SourcesTableRowFromJSONTyped,
    SourcesTableRowToJSON,
} from './SourcesTableRow';

/**
 * 
 * @export
 * @interface SerializedQueryComputeResponse
 */
export interface SerializedQueryComputeResponse {
    /**
     * 
     * @type {object}
     * @memberof SerializedQueryComputeResponse
     */
    query: object;
    /**
     * 
     * @type {Array<SourcesTableRow>}
     * @memberof SerializedQueryComputeResponse
     */
    results: Array<SourcesTableRow>;
}

/**
 * Check if a given object implements the SerializedQueryComputeResponse interface.
 */
export function instanceOfSerializedQueryComputeResponse(value: object): boolean {
    if (!('query' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function SerializedQueryComputeResponseFromJSON(json: any): SerializedQueryComputeResponse {
    return SerializedQueryComputeResponseFromJSONTyped(json, false);
}

export function SerializedQueryComputeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializedQueryComputeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'results': ((json['results'] as Array<any>).map(SourcesTableRowFromJSON)),
    };
}

export function SerializedQueryComputeResponseToJSON(value?: SerializedQueryComputeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'results': ((value['results'] as Array<any>).map(SourcesTableRowToJSON)),
    };
}

