/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models/index';

export interface GetAffectedEntitiesRequest {
    entityUuid: string;
    workspaceCode: string;
    scenarioUuid?: string;
}

/**
 * 
 */
export class DagApi extends runtime.BaseAPI {

    /**
     * Get all entities affected by the given entity.
     * Affected Entities
     */
    async getAffectedEntitiesRaw(requestParameters: GetAffectedEntitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Array<string>>>> {
        if (requestParameters['entityUuid'] == null) {
            throw new runtime.RequiredError(
                'entityUuid',
                'Required parameter "entityUuid" was null or undefined when calling getAffectedEntities().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling getAffectedEntities().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['scenarioUuid'] != null) {
            queryParameters['scenario_uuid'] = requestParameters['scenarioUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dag/dag/{entity_uuid}/affected`.replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters['entityUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all entities affected by the given entity.
     * Affected Entities
     */
    async getAffectedEntities(requestParameters: GetAffectedEntitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Array<string>>> {
        const response = await this.getAffectedEntitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
