/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayConnectionStream } from './DisplayConnectionStream';
import {
    DisplayConnectionStreamFromJSON,
    DisplayConnectionStreamFromJSONTyped,
    DisplayConnectionStreamToJSON,
} from './DisplayConnectionStream';

/**
 * 
 * @export
 * @interface DisplayConnection
 */
export interface DisplayConnection {
    /**
     * 
     * @type {string}
     * @memberof DisplayConnection
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayConnection
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayConnection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayConnection
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayConnection
     */
    type: DisplayConnectionTypeEnum;
    /**
     * 
     * @type {Array<DisplayConnectionStream>}
     * @memberof DisplayConnection
     */
    streams?: Array<DisplayConnectionStream>;
    /**
     * 
     * @type {Date}
     * @memberof DisplayConnection
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayConnection
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const DisplayConnectionTypeEnum = {
    Custom: 'custom',
    Hris: 'hris',
    Accounting: 'accounting',
    Ats: 'ats',
    Crm: 'crm',
    Ticketing: 'ticketing'
} as const;
export type DisplayConnectionTypeEnum = typeof DisplayConnectionTypeEnum[keyof typeof DisplayConnectionTypeEnum];


/**
 * Check if a given object implements the DisplayConnection interface.
 */
export function instanceOfDisplayConnection(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('type' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function DisplayConnectionFromJSON(json: any): DisplayConnection {
    return DisplayConnectionFromJSONTyped(json, false);
}

export function DisplayConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayConnection {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'code': json['code'],
        'type': json['type'],
        'streams': json['streams'] == null ? undefined : ((json['streams'] as Array<any>).map(DisplayConnectionStreamFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function DisplayConnectionToJSON(value?: DisplayConnection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'code': value['code'],
        'type': value['type'],
        'streams': value['streams'] == null ? undefined : ((value['streams'] as Array<any>).map(DisplayConnectionStreamToJSON)),
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
    };
}

