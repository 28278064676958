/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConnectorMetric } from './ConnectorMetric';
import {
    ConnectorMetricFromJSON,
    ConnectorMetricFromJSONTyped,
    ConnectorMetricToJSON,
} from './ConnectorMetric';
import type { ConnectorStream } from './ConnectorStream';
import {
    ConnectorStreamFromJSON,
    ConnectorStreamFromJSONTyped,
    ConnectorStreamToJSON,
} from './ConnectorStream';

/**
 * 
 * @export
 * @interface BaseConnector
 */
export interface BaseConnector {
    /**
     * 
     * @type {string}
     * @memberof BaseConnector
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof BaseConnector
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseConnector
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof BaseConnector
     */
    logo: string;
    /**
     * 
     * @type {Array<ConnectorMetric>}
     * @memberof BaseConnector
     */
    metrics: Array<ConnectorMetric>;
    /**
     * 
     * @type {Array<ConnectorStream>}
     * @memberof BaseConnector
     */
    streams: Array<ConnectorStream>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseConnector
     */
    categories: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseConnector
     */
    type: BaseConnectorTypeEnum;
}


/**
 * @export
 */
export const BaseConnectorTypeEnum = {
    Custom: 'custom',
    Hris: 'hris',
    Accounting: 'accounting',
    Ats: 'ats',
    Crm: 'crm',
    Ticketing: 'ticketing'
} as const;
export type BaseConnectorTypeEnum = typeof BaseConnectorTypeEnum[keyof typeof BaseConnectorTypeEnum];


/**
 * Check if a given object implements the BaseConnector interface.
 */
export function instanceOfBaseConnector(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('name' in value)) return false;
    if (!('uuid' in value)) return false;
    if (!('logo' in value)) return false;
    if (!('metrics' in value)) return false;
    if (!('streams' in value)) return false;
    if (!('categories' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function BaseConnectorFromJSON(json: any): BaseConnector {
    return BaseConnectorFromJSONTyped(json, false);
}

export function BaseConnectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseConnector {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'uuid': json['uuid'],
        'logo': json['logo'],
        'metrics': ((json['metrics'] as Array<any>).map(ConnectorMetricFromJSON)),
        'streams': ((json['streams'] as Array<any>).map(ConnectorStreamFromJSON)),
        'categories': json['categories'],
        'type': json['type'],
    };
}

export function BaseConnectorToJSON(value?: BaseConnector | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'name': value['name'],
        'uuid': value['uuid'],
        'logo': value['logo'],
        'metrics': ((value['metrics'] as Array<any>).map(ConnectorMetricToJSON)),
        'streams': ((value['streams'] as Array<any>).map(ConnectorStreamToJSON)),
        'categories': value['categories'],
        'type': value['type'],
    };
}

