/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DuplicateModelParams
 */
export interface DuplicateModelParams {
    /**
     * 
     * @type {string}
     * @memberof DuplicateModelParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateModelParams
     */
    originalVersionUuid: string;
}

/**
 * Check if a given object implements the DuplicateModelParams interface.
 */
export function instanceOfDuplicateModelParams(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('originalVersionUuid' in value)) return false;
    return true;
}

export function DuplicateModelParamsFromJSON(json: any): DuplicateModelParams {
    return DuplicateModelParamsFromJSONTyped(json, false);
}

export function DuplicateModelParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateModelParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'originalVersionUuid': json['original_version_uuid'],
    };
}

export function DuplicateModelParamsToJSON(value?: DuplicateModelParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'original_version_uuid': value['originalVersionUuid'],
    };
}

