/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CofiApiRoutersModelsFavoriteParams
 */
export interface CofiApiRoutersModelsFavoriteParams {
    /**
     * 
     * @type {string}
     * @memberof CofiApiRoutersModelsFavoriteParams
     */
    modelUuid: string;
    /**
     * 
     * @type {boolean}
     * @memberof CofiApiRoutersModelsFavoriteParams
     */
    state: boolean;
}

/**
 * Check if a given object implements the CofiApiRoutersModelsFavoriteParams interface.
 */
export function instanceOfCofiApiRoutersModelsFavoriteParams(value: object): boolean {
    if (!('modelUuid' in value)) return false;
    if (!('state' in value)) return false;
    return true;
}

export function CofiApiRoutersModelsFavoriteParamsFromJSON(json: any): CofiApiRoutersModelsFavoriteParams {
    return CofiApiRoutersModelsFavoriteParamsFromJSONTyped(json, false);
}

export function CofiApiRoutersModelsFavoriteParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CofiApiRoutersModelsFavoriteParams {
    if (json == null) {
        return json;
    }
    return {
        
        'modelUuid': json['model_uuid'],
        'state': json['state'],
    };
}

export function CofiApiRoutersModelsFavoriteParamsToJSON(value?: CofiApiRoutersModelsFavoriteParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'model_uuid': value['modelUuid'],
        'state': value['state'],
    };
}

