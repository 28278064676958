/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AskQuestionParams
 */
export interface AskQuestionParams {
    /**
     * 
     * @type {string}
     * @memberof AskQuestionParams
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof AskQuestionParams
     */
    context: string;
    /**
     * 
     * @type {string}
     * @memberof AskQuestionParams
     */
    conversationUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AskQuestionParams
     */
    entityUuid?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AskQuestionParams
     */
    extraData?: { [key: string]: string; };
}

/**
 * Check if a given object implements the AskQuestionParams interface.
 */
export function instanceOfAskQuestionParams(value: object): boolean {
    if (!('question' in value)) return false;
    if (!('context' in value)) return false;
    return true;
}

export function AskQuestionParamsFromJSON(json: any): AskQuestionParams {
    return AskQuestionParamsFromJSONTyped(json, false);
}

export function AskQuestionParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AskQuestionParams {
    if (json == null) {
        return json;
    }
    return {
        
        'question': json['question'],
        'context': json['context'],
        'conversationUuid': json['conversation_uuid'] == null ? undefined : json['conversation_uuid'],
        'entityUuid': json['entity_uuid'] == null ? undefined : json['entity_uuid'],
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
    };
}

export function AskQuestionParamsToJSON(value?: AskQuestionParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'question': value['question'],
        'context': value['context'],
        'conversation_uuid': value['conversationUuid'],
        'entity_uuid': value['entityUuid'],
        'extra_data': value['extraData'],
    };
}

