/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddExpressionToVariableDimensionValueParams
 */
export interface AddExpressionToVariableDimensionValueParams {
    /**
     * 
     * @type {Date}
     * @memberof AddExpressionToVariableDimensionValueParams
     */
    periodStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof AddExpressionToVariableDimensionValueParams
     */
    periodEnd: Date;
    /**
     * 
     * @type {string}
     * @memberof AddExpressionToVariableDimensionValueParams
     */
    versionUuid: string;
    /**
     * 
     * @type {string}
     * @memberof AddExpressionToVariableDimensionValueParams
     */
    scenarioUuid: string;
    /**
     * 
     * @type {string}
     * @memberof AddExpressionToVariableDimensionValueParams
     */
    formula?: string;
}

/**
 * Check if a given object implements the AddExpressionToVariableDimensionValueParams interface.
 */
export function instanceOfAddExpressionToVariableDimensionValueParams(value: object): boolean {
    if (!('periodStart' in value)) return false;
    if (!('periodEnd' in value)) return false;
    if (!('versionUuid' in value)) return false;
    if (!('scenarioUuid' in value)) return false;
    return true;
}

export function AddExpressionToVariableDimensionValueParamsFromJSON(json: any): AddExpressionToVariableDimensionValueParams {
    return AddExpressionToVariableDimensionValueParamsFromJSONTyped(json, false);
}

export function AddExpressionToVariableDimensionValueParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddExpressionToVariableDimensionValueParams {
    if (json == null) {
        return json;
    }
    return {
        
        'periodStart': (new Date(json['period_start'])),
        'periodEnd': (new Date(json['period_end'])),
        'versionUuid': json['version_uuid'],
        'scenarioUuid': json['scenario_uuid'],
        'formula': json['formula'] == null ? undefined : json['formula'],
    };
}

export function AddExpressionToVariableDimensionValueParamsToJSON(value?: AddExpressionToVariableDimensionValueParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period_start': ((value['periodStart']).toISOString()),
        'period_end': ((value['periodEnd']).toISOString()),
        'version_uuid': value['versionUuid'],
        'scenario_uuid': value['scenarioUuid'],
        'formula': value['formula'],
    };
}

