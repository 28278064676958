/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TableAttributes } from './TableAttributes';
import {
    TableAttributesFromJSON,
    TableAttributesFromJSONTyped,
    TableAttributesToJSON,
} from './TableAttributes';

/**
 * 
 * @export
 * @interface TableDataParams
 */
export interface TableDataParams {
    /**
     * 
     * @type {TableAttributes}
     * @memberof TableDataParams
     */
    attributes: TableAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof TableDataParams
     */
    refresh: boolean;
}

/**
 * Check if a given object implements the TableDataParams interface.
 */
export function instanceOfTableDataParams(value: object): boolean {
    if (!('attributes' in value)) return false;
    if (!('refresh' in value)) return false;
    return true;
}

export function TableDataParamsFromJSON(json: any): TableDataParams {
    return TableDataParamsFromJSONTyped(json, false);
}

export function TableDataParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableDataParams {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': TableAttributesFromJSON(json['attributes']),
        'refresh': json['refresh'],
    };
}

export function TableDataParamsToJSON(value?: TableDataParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': TableAttributesToJSON(value['attributes']),
        'refresh': value['refresh'],
    };
}

