/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateFormulaParams
 */
export interface ValidateFormulaParams {
    /**
     * 
     * @type {string}
     * @memberof ValidateFormulaParams
     */
    formula: string;
}

/**
 * Check if a given object implements the ValidateFormulaParams interface.
 */
export function instanceOfValidateFormulaParams(value: object): boolean {
    if (!('formula' in value)) return false;
    return true;
}

export function ValidateFormulaParamsFromJSON(json: any): ValidateFormulaParams {
    return ValidateFormulaParamsFromJSONTyped(json, false);
}

export function ValidateFormulaParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateFormulaParams {
    if (json == null) {
        return json;
    }
    return {
        
        'formula': json['formula'],
    };
}

export function ValidateFormulaParamsToJSON(value?: ValidateFormulaParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'formula': value['formula'],
    };
}

