/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMetric
 */
export interface CreateMetric {
    /**
     * 
     * @type {string}
     * @memberof CreateMetric
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMetric
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMetric
     */
    catalogMetricCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMetric
     */
    connectionUuid?: string;
}

/**
 * Check if a given object implements the CreateMetric interface.
 */
export function instanceOfCreateMetric(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function CreateMetricFromJSON(json: any): CreateMetric {
    return CreateMetricFromJSONTyped(json, false);
}

export function CreateMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMetric {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'categoryId': json['category_id'] == null ? undefined : json['category_id'],
        'catalogMetricCode': json['catalog_metric_code'] == null ? undefined : json['catalog_metric_code'],
        'connectionUuid': json['connection_uuid'] == null ? undefined : json['connection_uuid'],
    };
}

export function CreateMetricToJSON(value?: CreateMetric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'category_id': value['categoryId'],
        'catalog_metric_code': value['catalogMetricCode'],
        'connection_uuid': value['connectionUuid'],
    };
}

