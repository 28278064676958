/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModelTableHeader } from './ModelTableHeader';
import {
    ModelTableHeaderFromJSON,
    ModelTableHeaderFromJSONTyped,
    ModelTableHeaderToJSON,
} from './ModelTableHeader';
import type { ModelTableRow } from './ModelTableRow';
import {
    ModelTableRowFromJSON,
    ModelTableRowFromJSONTyped,
    ModelTableRowToJSON,
} from './ModelTableRow';

/**
 * 
 * @export
 * @interface ModelTableData
 */
export interface ModelTableData {
    /**
     * 
     * @type {any}
     * @memberof ModelTableData
     */
    type?: any;
    /**
     * 
     * @type {Array<Array<ModelTableHeader>>}
     * @memberof ModelTableData
     */
    headers?: Array<Array<ModelTableHeader>>;
    /**
     * 
     * @type {Array<ModelTableRow>}
     * @memberof ModelTableData
     */
    rows?: Array<ModelTableRow>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelTableData
     */
    errors?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ModelTableData interface.
 */
export function instanceOfModelTableData(value: object): boolean {
    return true;
}

export function ModelTableDataFromJSON(json: any): ModelTableData {
    return ModelTableDataFromJSONTyped(json, false);
}

export function ModelTableDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelTableData {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'headers': json['headers'] == null ? undefined : json['headers'],
        'rows': json['rows'] == null ? undefined : ((json['rows'] as Array<any>).map(ModelTableRowFromJSON)),
        'errors': json['errors'] == null ? undefined : json['errors'],
    };
}

export function ModelTableDataToJSON(value?: ModelTableData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'headers': value['headers'],
        'rows': value['rows'] == null ? undefined : ((value['rows'] as Array<any>).map(ModelTableRowToJSON)),
        'errors': value['errors'],
    };
}

