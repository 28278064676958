import { RouteLocationRaw } from "vue-router";
import { Workspace } from "~/gen";

const ENTITY_TYPE_URL_SECTION = {
  report: "reports",
  model: "models"
};

function buildEntityUrl(
  entityType: keyof typeof ENTITY_TYPE_URL_SECTION,
  code: string
) {
  return `/${ENTITY_TYPE_URL_SECTION[entityType]}/${code}`;
}

export function defaultRedirect(
  workspace: Workspace,
  queryRedirect?: string
) {
  let path: RouteLocationRaw = "";
  if (queryRedirect) {
    path = queryRedirect as string;
  } else if (workspace.lastAccessedEntity) {
    const entityUrl = buildEntityUrl(
      workspace.lastAccessedEntity.type,
      workspace.lastAccessedEntity.code
    );
    path = ('/' + workspace.code + entityUrl) as string;
  } else if (workspace?.defaultRedirectEntity) {
    const entityUrl = buildEntityUrl(
      workspace.defaultRedirectEntity.type,
      workspace.defaultRedirectEntity.code
    );
    path = ("/" + workspace.code + entityUrl) as string;
  } else if (workspace) {
    path = {
      name: "code-metrics",
      params: { code: workspace.code }
    };
  }

  return path;
}
