export function useDownload(blob: Blob, name: string) {
  const anchor = document.createElement("a");
  const url = URL.createObjectURL(blob);

  anchor.setAttribute("href", url);
  anchor.setAttribute("download", name);
  anchor.style.visibility = "hidden";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
