/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DebugMetricParams
 */
export interface DebugMetricParams {
    /**
     * 
     * @type {string}
     * @memberof DebugMetricParams
     */
    scenarioUuid: string;
}

/**
 * Check if a given object implements the DebugMetricParams interface.
 */
export function instanceOfDebugMetricParams(value: object): boolean {
    if (!('scenarioUuid' in value)) return false;
    return true;
}

export function DebugMetricParamsFromJSON(json: any): DebugMetricParams {
    return DebugMetricParamsFromJSONTyped(json, false);
}

export function DebugMetricParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebugMetricParams {
    if (json == null) {
        return json;
    }
    return {
        
        'scenarioUuid': json['scenario_uuid'],
    };
}

export function DebugMetricParamsToJSON(value?: DebugMetricParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scenario_uuid': value['scenarioUuid'],
    };
}

