/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadDetails
 */
export interface UploadDetails {
    /**
     * 
     * @type {string}
     * @memberof UploadDetails
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDetails
     */
    uploadedByUuid: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDetails
     */
    uploaderUuid: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDetails
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDetails
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDetails
     */
    statusDetails?: string;
    /**
     * 
     * @type {Date}
     * @memberof UploadDetails
     */
    processedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UploadDetails
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the UploadDetails interface.
 */
export function instanceOfUploadDetails(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('uploadedByUuid' in value)) return false;
    if (!('uploaderUuid' in value)) return false;
    if (!('filename' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function UploadDetailsFromJSON(json: any): UploadDetails {
    return UploadDetailsFromJSONTyped(json, false);
}

export function UploadDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'uploadedByUuid': json['uploaded_by_uuid'],
        'uploaderUuid': json['uploader_uuid'],
        'filename': json['filename'],
        'status': json['status'],
        'statusDetails': json['status_details'] == null ? undefined : json['status_details'],
        'processedAt': json['processed_at'] == null ? undefined : (new Date(json['processed_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}

export function UploadDetailsToJSON(value?: UploadDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'uploaded_by_uuid': value['uploadedByUuid'],
        'uploader_uuid': value['uploaderUuid'],
        'filename': value['filename'],
        'status': value['status'],
        'status_details': value['statusDetails'],
        'processed_at': value['processedAt'] == null ? undefined : ((value['processedAt'] as any).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
    };
}

