/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TableAttributes } from './TableAttributes';
import {
    TableAttributesFromJSON,
    TableAttributesFromJSONTyped,
    TableAttributesToJSON,
} from './TableAttributes';

/**
 * 
 * @export
 * @interface CreateReportTableParams
 */
export interface CreateReportTableParams {
    /**
     * 
     * @type {TableAttributes}
     * @memberof CreateReportTableParams
     */
    attributes?: TableAttributes;
}

/**
 * Check if a given object implements the CreateReportTableParams interface.
 */
export function instanceOfCreateReportTableParams(value: object): boolean {
    return true;
}

export function CreateReportTableParamsFromJSON(json: any): CreateReportTableParams {
    return CreateReportTableParamsFromJSONTyped(json, false);
}

export function CreateReportTableParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReportTableParams {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': json['attributes'] == null ? undefined : TableAttributesFromJSON(json['attributes']),
    };
}

export function CreateReportTableParamsToJSON(value?: CreateReportTableParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': TableAttributesToJSON(value['attributes']),
    };
}

