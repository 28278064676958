import { escapeRegExp } from "lodash";
import { DateTime } from "luxon";
import { useWorkspaceApi } from "~/composables/useApi";

export function convertIsoDateToUtcDate(isoString: string) {
  const dttime = DateTime.fromISO(isoString, { zone: 'utc', setZone: true }).toJSDate();
  return new Date(dttime.getUTCFullYear(), dttime.getUTCMonth(), dttime.getUTCDate());
}

export const jsDateToCorrectUtcDate = (dt: Date) => {
  const result = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000);
  return result;
};

/**
 * Used when we have a UTC date that we want to show as a local date. Basicaly is
 * the inverse of the method jsDateToCorrectUtcDate
 * @param dt 
 * @returns 
 */
export const utcDateToCorrectJsDate = (dt: Date) => {
  const result = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
  return result;
};
/**
 * 
 * @param date Javascript Date object to transform
 * @param hourFormat HH:mm:ss, HH:mm, etc
 * @returns string
 */
export function formatDate(date: Date, hourFormat?: string, toLocal = true){
  /*
  * The frontend always thinks that the parsed hours are hours in its time zone. However, 
  * we always store these datetimes in UTC. In order for the user to see the real times in 
  * their zone, we have to build the new date as UTC and then use .toLocal() to adapt the 
  * hours to the user's time zone.
  * */ 
  if (!date) return "Never";
  const utc = DateTime.utc(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  const wsFormat = useWorkspaceApi()._current?.dateFormat;
  const format = hourFormat ? `${wsFormat} ${hourFormat}` : wsFormat;
  if (toLocal) return utc.toLocal().toFormat(format);
  return utc.toFormat(format);
}

// https://stackoverflow.com/a/11665915
export function findDecimalSeparator() {
  const num = 1.2;
  if (typeof Intl === "object" && Intl && Intl.NumberFormat) {
      // I'm surprised it's this much of a pain and am hoping I'm missing
      // something in the API
      const formatter = new Intl.NumberFormat();
      const parts = formatter.formatToParts(num);
      const decimal = parts.find(({ type }) => type === "decimal").value;
      return decimal;
  }
  // Doesn't support `Intl.NumberFormat`, fall back to dodgy means
  const str = num.toLocaleString();
  const parts = /1(\D+)2/.exec(str);
  return parts[1];
}
export function convertToFloat(str: string, decimalsSeparator: string) {
  let body = str.replaceAll('\r', '\n');
  let sign = "";
  const signMatch = /^\s*(-|\+)/.exec(str);
  // Or if you don't want to support unary +:
  // const signMatch = /^\s*(-)/.exec(str);
  if (signMatch) {
      body = str.substring(signMatch.index + 1);
      sign = signMatch[1];
  }
  const rex = new RegExp(`${escapeRegExp(decimalsSeparator)}|-|\\+|\\D`, "g");
  const updatedBody = body.replace(
      rex,
      (match) => match === decimalsSeparator ? "." : ""
  );
  const num = parseFloat(sign + updatedBody);
  return num;
}