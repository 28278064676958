import { useLocalStorage } from "@vueuse/core";
import { DateTime, DateTimeUnit, Interval } from "luxon";
import { defineStore } from "pinia";
import { Period, PeriodToJSON } from '~/gen';
import { useAuthApi, useWorkspaceApi } from "./useApi";
import { createColumns, storedPeriod } from "./useTableValues";

type OptionalPeriod = Period | undefined;

export const periodStorage = useLocalStorage('period', '');

const defaultGranularity = 'month';

export const defaultPeriod = (start = -3, end = 9): Period => {
  const build = (amount: number) => {
    const dt = new Date();
    dt.setMonth(dt.getMonth() + amount);
    return dt;
  };
  const startDate = DateTime.fromJSDate(build(start)).startOf("month");
  const endDate = DateTime.fromJSDate(build(end)).endOf("month");
  return {
    start: new Date(startDate.toISODate()),
    end: new Date(endDate.toISODate()) ,
    grain: defaultGranularity
  };
};

export default defineStore('store/preferences', {
  state: () => ({
    navIsOpen: false,
    _period: undefined as OptionalPeriod,
    reports: [] as string[],
    interval: undefined as Interval | undefined,
  }),
  getters: {
    period: ({ _period = storedPeriod() }) => {
      return _period;
    },
    columns: ({ _period }) => createColumns(_period),
    timePeriods: ({
      _period: { grain, start: pStart, end: pEnd } = defaultPeriod(),
      interval: { start, end } = Interval.fromDateTimes(pStart as Date, pEnd as Date)
    }) => {
      const returnArr = [];
      let cursor = start.startOf(grain as DateTimeUnit);
      while (cursor <= end) {
        returnArr.push(cursor.toFormat("yyyy-MM-dd"));
        cursor = cursor.plus({ [grain as string]: 1 });
      }
      return returnArr;
    },
    decimalsSeparator: () => {
      const user = useAuthApi().user;
      if (!user?.decimalsSeparator) return useWorkspaceApi()._current?.decimalsSeparator;
      return user.decimalsSeparator;
    }
  },
  actions: {
    persistPeriod() {
      periodStorage.value = JSON.stringify(PeriodToJSON(this._period));
    }
  }
});
