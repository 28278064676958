/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PivotTableHeader
 */
export interface PivotTableHeader {
    /**
     * 
     * @type {string}
     * @memberof PivotTableHeader
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof PivotTableHeader
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PivotTableHeader
     */
    parentId?: string;
    /**
     * 
     * @type {Array<PivotTableHeader>}
     * @memberof PivotTableHeader
     */
    children?: Array<PivotTableHeader>;
    /**
     * 
     * @type {number}
     * @memberof PivotTableHeader
     */
    depth?: number;
}

/**
 * Check if a given object implements the PivotTableHeader interface.
 */
export function instanceOfPivotTableHeader(value: object): boolean {
    if (!('label' in value)) return false;
    if (!('id' in value)) return false;
    return true;
}

export function PivotTableHeaderFromJSON(json: any): PivotTableHeader {
    return PivotTableHeaderFromJSONTyped(json, false);
}

export function PivotTableHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTableHeader {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'id': json['id_'],
        'parentId': json['parent_id'] == null ? undefined : json['parent_id'],
        'children': json['children'] == null ? undefined : ((json['children'] as Array<any>).map(PivotTableHeaderFromJSON)),
        'depth': json['depth'] == null ? undefined : json['depth'],
    };
}

export function PivotTableHeaderToJSON(value?: PivotTableHeader | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'id_': value['id'],
        'parent_id': value['parentId'],
        'children': value['children'] == null ? undefined : ((value['children'] as Array<any>).map(PivotTableHeaderToJSON)),
        'depth': value['depth'],
    };
}

