/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SourcesTableRow
 */
export interface SourcesTableRow {
    /**
     * 
     * @type {string}
     * @memberof SourcesTableRow
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesTableRow
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesTableRow
     */
    dimension: string;
    /**
     * 
     * @type {string}
     * @memberof SourcesTableRow
     */
    label: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SourcesTableRow
     */
    values: { [key: string]: number; };
    /**
     * 
     * @type {Array<SourcesTableRow>}
     * @memberof SourcesTableRow
     */
    children?: Array<SourcesTableRow>;
    /**
     * 
     * @type {string}
     * @memberof SourcesTableRow
     */
    modelUuid?: string;
}

/**
 * Check if a given object implements the SourcesTableRow interface.
 */
export function instanceOfSourcesTableRow(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('code' in value)) return false;
    if (!('dimension' in value)) return false;
    if (!('label' in value)) return false;
    if (!('values' in value)) return false;
    return true;
}

export function SourcesTableRowFromJSON(json: any): SourcesTableRow {
    return SourcesTableRowFromJSONTyped(json, false);
}

export function SourcesTableRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourcesTableRow {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'code': json['code'],
        'dimension': json['dimension'],
        'label': json['label'],
        'values': json['values'],
        'children': json['children'] == null ? undefined : ((json['children'] as Array<any>).map(SourcesTableRowFromJSON)),
        'modelUuid': json['model_uuid'] == null ? undefined : json['model_uuid'],
    };
}

export function SourcesTableRowToJSON(value?: SourcesTableRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'code': value['code'],
        'dimension': value['dimension'],
        'label': value['label'],
        'values': value['values'],
        'children': value['children'] == null ? undefined : ((value['children'] as Array<any>).map(SourcesTableRowToJSON)),
        'model_uuid': value['modelUuid'],
    };
}

