/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddVariableToModelParams
 */
export interface AddVariableToModelParams {
    /**
     * 
     * @type {string}
     * @memberof AddVariableToModelParams
     */
    variableName?: string;
}

/**
 * Check if a given object implements the AddVariableToModelParams interface.
 */
export function instanceOfAddVariableToModelParams(value: object): boolean {
    return true;
}

export function AddVariableToModelParamsFromJSON(json: any): AddVariableToModelParams {
    return AddVariableToModelParamsFromJSONTyped(json, false);
}

export function AddVariableToModelParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddVariableToModelParams {
    if (json == null) {
        return json;
    }
    return {
        
        'variableName': json['variable_name'] == null ? undefined : json['variable_name'],
    };
}

export function AddVariableToModelParamsToJSON(value?: AddVariableToModelParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variable_name': value['variableName'],
    };
}

