/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddDimensionValueToVariableParams
 */
export interface AddDimensionValueToVariableParams {
    /**
     * 
     * @type {string}
     * @memberof AddDimensionValueToVariableParams
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof AddDimensionValueToVariableParams
     */
    parentDimensionValueUuid?: string;
}

/**
 * Check if a given object implements the AddDimensionValueToVariableParams interface.
 */
export function instanceOfAddDimensionValueToVariableParams(value: object): boolean {
    if (!('value' in value)) return false;
    return true;
}

export function AddDimensionValueToVariableParamsFromJSON(json: any): AddDimensionValueToVariableParams {
    return AddDimensionValueToVariableParamsFromJSONTyped(json, false);
}

export function AddDimensionValueToVariableParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDimensionValueToVariableParams {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'parentDimensionValueUuid': json['parent_dimension_value_uuid'] == null ? undefined : json['parent_dimension_value_uuid'],
    };
}

export function AddDimensionValueToVariableParamsToJSON(value?: AddDimensionValueToVariableParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'parent_dimension_value_uuid': value['parentDimensionValueUuid'],
    };
}

