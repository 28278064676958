/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateWorkspaceParams
 */
export interface CreateWorkspaceParams {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceParams
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceParams
     */
    templateWorkspaceUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkspaceParams
     */
    includeUploads?: boolean;
}

/**
 * Check if a given object implements the CreateWorkspaceParams interface.
 */
export function instanceOfCreateWorkspaceParams(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function CreateWorkspaceParamsFromJSON(json: any): CreateWorkspaceParams {
    return CreateWorkspaceParamsFromJSONTyped(json, false);
}

export function CreateWorkspaceParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkspaceParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'code': json['code'] == null ? undefined : json['code'],
        'templateWorkspaceUuid': json['template_workspace_uuid'] == null ? undefined : json['template_workspace_uuid'],
        'includeUploads': json['include_uploads'] == null ? undefined : json['include_uploads'],
    };
}

export function CreateWorkspaceParamsToJSON(value?: CreateWorkspaceParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'code': value['code'],
        'template_workspace_uuid': value['templateWorkspaceUuid'],
        'include_uploads': value['includeUploads'],
    };
}

