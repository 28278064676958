/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MappingFilter
 */
export interface MappingFilter {
    /**
     * 
     * @type {string}
     * @memberof MappingFilter
     */
    _boolean?: MappingFilterBooleanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MappingFilter
     */
    negated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MappingFilter
     */
    operation: MappingFilterOperationEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof MappingFilter
     */
    values: Array<string>;
}


/**
 * @export
 */
export const MappingFilterBooleanEnum = {
    And: 'and',
    Or: 'or'
} as const;
export type MappingFilterBooleanEnum = typeof MappingFilterBooleanEnum[keyof typeof MappingFilterBooleanEnum];

/**
 * @export
 */
export const MappingFilterOperationEnum = {
    Eq: 'eq',
    Contains: 'contains',
    StartsWith: 'startsWith',
    EndsWith: 'endsWith',
    Neq: 'neq',
    Before: 'before',
    After: 'after'
} as const;
export type MappingFilterOperationEnum = typeof MappingFilterOperationEnum[keyof typeof MappingFilterOperationEnum];


/**
 * Check if a given object implements the MappingFilter interface.
 */
export function instanceOfMappingFilter(value: object): boolean {
    if (!('operation' in value)) return false;
    if (!('values' in value)) return false;
    return true;
}

export function MappingFilterFromJSON(json: any): MappingFilter {
    return MappingFilterFromJSONTyped(json, false);
}

export function MappingFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingFilter {
    if (json == null) {
        return json;
    }
    return {
        
        '_boolean': json['boolean'] == null ? undefined : json['boolean'],
        'negated': json['negated'] == null ? undefined : json['negated'],
        'operation': json['operation'],
        'values': json['values'],
    };
}

export function MappingFilterToJSON(value?: MappingFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'boolean': value['_boolean'],
        'negated': value['negated'],
        'operation': value['operation'],
        'values': value['values'],
    };
}

