import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import { useAuthApi, useWorkspaceApi } from '~/composables/useApi';
import routes from '~pages'; // Ignore error, this is some magic done by the pages plugin
import { getRoleAsync } from './permissions';
import { defaultRedirect } from '~/composables/useRedirect';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to: RouteLocationNormalized, _from: RouteLocationNormalized, savedPosition: ScrollOptions) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.name === 'community-asset-id') {
      const layout = document.querySelector('.layout');
      layout?.scroll(0,0);
    }
    return { top: 0, behavior: 'smooth' };
  }
});

// router guards
router.beforeEach(async (to, from, next) => {
  const api = useAuthApi();
  const wsApi = useWorkspaceApi();
  if (to.path === '/logout') {
    await api.logout();
    next('/login');
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!await api.check()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
      return;
    }
    if (!wsApi._current){
      await wsApi.current(to.params.code as string);
    }
  }
  if (to.matched.some(record => record.meta.requiresRegular)) {
    const user = api.user;
    const role = await getRoleAsync(user, to.params.code);
    if (!user?.isAdmin && (!role || role === 'guest')) {
      next(defaultRedirect(await wsApi.current(), to.query.redirect as string));
      return;
    }
  }
  // If we get here, we always have a logged in user
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    const user = api.user;
    const role = await getRoleAsync(user, to.params.code);
    if (!user?.isAdmin && (!role || role !== 'admin')) {
      next(defaultRedirect(await wsApi.current(), to.query.redirect as string));
      return;
    }
  }
  if (to.matched.some(record => record.meta.requiresCofiAdmin)) {
    const user = api.user;
    if (!user?.isAdmin) {
      next(defaultRedirect(await wsApi.current(), to.query.redirect as string));
      return;
    }
  }
  /*
    * User is going to '/<ws_code>' or '/<ws_code>/'
    * If slug.length == 1, this is the first case, if it is 2, it is the second one, and the second parameter is ''
    * Either way, get the first parameter (ws code) and override the current WS with the new code
  */
  const rootWsRoute = to.name == 'slug' && (to.params.slug && to.params.slug.length == 1 || (to.params.slug.length == 2 && to.params.slug[1] == ''))
  if (to.path === '/' || rootWsRoute) {
    try {
      const wsCode = rootWsRoute ? to.params.slug[0] : undefined;
      next(defaultRedirect(await wsApi.current(wsCode), to.query.redirect as string));
      return;
    } catch (e) {
      next("/login");
      return;
    }
  }
  // Reload the login page, see if user is already aythenticated
  if (to.path === '/login' && from.path === '/' && await api.check()) {
    try {
      next(defaultRedirect(await wsApi.current(), to.query.redirect as string));
      return;
    } catch (e) {
      console.log("Error redirecting to login: ", e);
    }
  }
  next(); // make sure to always call next()!
});

export function replaceQueryParam(key: string, value: string, push = false) {
  const query = {...router.currentRoute.value.query};
  query[key] = value;

  const pushFn = push ? router.push : router.replace;
  pushFn({ path: router.currentRoute.value.path, query });
}

export default router;
