/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelContributorsParams
 */
export interface UpdateModelContributorsParams {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateModelContributorsParams
     */
    contributors: { [key: string]: string; };
}


/**
 * @export
 */
export const UpdateModelContributorsParamsContributorsEnum = {
    Owner: 'owner',
    Contributor: 'contributor',
    Guest: 'guest'
} as const;
export type UpdateModelContributorsParamsContributorsEnum = typeof UpdateModelContributorsParamsContributorsEnum[keyof typeof UpdateModelContributorsParamsContributorsEnum];


/**
 * Check if a given object implements the UpdateModelContributorsParams interface.
 */
export function instanceOfUpdateModelContributorsParams(value: object): boolean {
    if (!('contributors' in value)) return false;
    return true;
}

export function UpdateModelContributorsParamsFromJSON(json: any): UpdateModelContributorsParams {
    return UpdateModelContributorsParamsFromJSONTyped(json, false);
}

export function UpdateModelContributorsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelContributorsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'contributors': json['contributors'],
    };
}

export function UpdateModelContributorsParamsToJSON(value?: UpdateModelContributorsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contributors': value['contributors'],
    };
}

