/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginParams
 */
export interface LoginParams {
    /**
     * 
     * @type {string}
     * @memberof LoginParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginParams
     */
    password: string;
}

/**
 * Check if a given object implements the LoginParams interface.
 */
export function instanceOfLoginParams(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('password' in value)) return false;
    return true;
}

export function LoginParamsFromJSON(json: any): LoginParams {
    return LoginParamsFromJSONTyped(json, false);
}

export function LoginParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginParams {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function LoginParamsToJSON(value?: LoginParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

