/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';

/**
 * 
 * @export
 * @interface UpdateSchemaParams
 */
export interface UpdateSchemaParams {
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof UpdateSchemaParams
     */
    schema?: Array<SchemaColumn>;
}

/**
 * Check if a given object implements the UpdateSchemaParams interface.
 */
export function instanceOfUpdateSchemaParams(value: object): boolean {
    return true;
}

export function UpdateSchemaParamsFromJSON(json: any): UpdateSchemaParams {
    return UpdateSchemaParamsFromJSONTyped(json, false);
}

export function UpdateSchemaParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSchemaParams {
    if (json == null) {
        return json;
    }
    return {
        
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
    };
}

export function UpdateSchemaParamsToJSON(value?: UpdateSchemaParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
    };
}

