/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddUploaderOwnersParams,
  BatchProcessTask,
  CreateDatasourceParams,
  DownloadCSVParams,
  FavoriteUploaderParams,
  FetchUploaderDfParams,
  HTTPValidationError,
  OverviewTableData,
  RemoveUploaderOwnersParams,
  UpdateDataSourceParams,
  UpdateSchemaParams,
  UploadDetails,
  Uploader,
} from '../models/index';
import {
    AddUploaderOwnersParamsFromJSON,
    AddUploaderOwnersParamsToJSON,
    BatchProcessTaskFromJSON,
    BatchProcessTaskToJSON,
    CreateDatasourceParamsFromJSON,
    CreateDatasourceParamsToJSON,
    DownloadCSVParamsFromJSON,
    DownloadCSVParamsToJSON,
    FavoriteUploaderParamsFromJSON,
    FavoriteUploaderParamsToJSON,
    FetchUploaderDfParamsFromJSON,
    FetchUploaderDfParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    OverviewTableDataFromJSON,
    OverviewTableDataToJSON,
    RemoveUploaderOwnersParamsFromJSON,
    RemoveUploaderOwnersParamsToJSON,
    UpdateDataSourceParamsFromJSON,
    UpdateDataSourceParamsToJSON,
    UpdateSchemaParamsFromJSON,
    UpdateSchemaParamsToJSON,
    UploadDetailsFromJSON,
    UploadDetailsToJSON,
    UploaderFromJSON,
    UploaderToJSON,
} from '../models/index';

export interface AddUploaderOwnersRequest {
    uploaderUuid: string;
    workspaceCode: string;
    addUploaderOwnersParams: AddUploaderOwnersParams;
}

export interface CreateDatasourceRequest {
    workspaceCode: string;
    createDatasourceParams: CreateDatasourceParams;
}

export interface DeleteBatchRequest {
    batchId: string;
    workspaceCode: string;
}

export interface DeleteDatasourceRequest {
    uploaderId: string;
    workspaceCode: string;
}

export interface DetectUploaderFileSchemaRequest {
    uploaderId: string;
    workspaceCode: string;
    file: Blob;
}

export interface DownloadBatchRequest {
    batchId: string;
    workspaceCode: string;
}

export interface DownloadUploaderAsCsvRequest {
    uploaderUuid: string;
    workspaceCode: string;
    downloadCSVParams: DownloadCSVParams;
}

export interface FavoriteDatasourceRequest {
    uploaderId: string;
    workspaceCode: string;
    favoriteUploaderParams: FavoriteUploaderParams;
}

export interface FetchBatchDetailsRequest {
    batchId: string;
    workspaceCode: string;
}

export interface FetchDatasourcesRequest {
    workspaceCode: string;
}

export interface FetchTaskStatusRequest {
    taskId: string;
    workspaceCode: string;
}

export interface FetchUploaderDfRequest {
    uploaderId: string;
    workspaceCode: string;
    fetchUploaderDfParams: FetchUploaderDfParams;
}

export interface RemoveUploaderOwnerRequest {
    uploaderUuid: string;
    workspaceCode: string;
    removeUploaderOwnersParams: RemoveUploaderOwnersParams;
}

export interface UpdateDatasourceRequest {
    uploaderId: string;
    workspaceCode: string;
    updateDataSourceParams: UpdateDataSourceParams;
}

export interface UpdateDatasourceSchemaRequest {
    uploaderId: string;
    workspaceCode: string;
    updateSchemaParams: UpdateSchemaParams;
}

export interface UploadDatasourceBatchesRequest {
    uploaderUuid: string;
    workspaceCode: string;
    file: Array<Blob>;
}

/**
 * 
 */
export class UploadersApi extends runtime.BaseAPI {

    /**
     * Add Uploader Owners
     */
    async addUploaderOwnersRaw(requestParameters: AddUploaderOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Uploader>> {
        if (requestParameters['uploaderUuid'] == null) {
            throw new runtime.RequiredError(
                'uploaderUuid',
                'Required parameter "uploaderUuid" was null or undefined when calling addUploaderOwners().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addUploaderOwners().'
            );
        }

        if (requestParameters['addUploaderOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'addUploaderOwnersParams',
                'Required parameter "addUploaderOwnersParams" was null or undefined when calling addUploaderOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/{uploader_uuid}/owners`.replace(`{${"uploader_uuid"}}`, encodeURIComponent(String(requestParameters['uploaderUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUploaderOwnersParamsToJSON(requestParameters['addUploaderOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploaderFromJSON(jsonValue));
    }

    /**
     * Add Uploader Owners
     */
    async addUploaderOwners(requestParameters: AddUploaderOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Uploader> {
        const response = await this.addUploaderOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Datasource
     */
    async createDatasourceRaw(requestParameters: CreateDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Uploader>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createDatasource().'
            );
        }

        if (requestParameters['createDatasourceParams'] == null) {
            throw new runtime.RequiredError(
                'createDatasourceParams',
                'Required parameter "createDatasourceParams" was null or undefined when calling createDatasource().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDatasourceParamsToJSON(requestParameters['createDatasourceParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploaderFromJSON(jsonValue));
    }

    /**
     * Create Datasource
     */
    async createDatasource(requestParameters: CreateDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Uploader> {
        const response = await this.createDatasourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a single batch from the datasource
     * Remove Batch
     */
    async deleteBatchRaw(requestParameters: DeleteBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['batchId'] == null) {
            throw new runtime.RequiredError(
                'batchId',
                'Required parameter "batchId" was null or undefined when calling deleteBatch().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/batch/{batch_id}/delete`.replace(`{${"batch_id"}}`, encodeURIComponent(String(requestParameters['batchId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Removes a single batch from the datasource
     * Remove Batch
     */
    async deleteBatch(requestParameters: DeleteBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a datasource completely
     * Delete Datasource
     */
    async deleteDatasourceRaw(requestParameters: DeleteDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uploaderId'] == null) {
            throw new runtime.RequiredError(
                'uploaderId',
                'Required parameter "uploaderId" was null or undefined when calling deleteDatasource().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteDatasource().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_id}`.replace(`{${"uploader_id"}}`, encodeURIComponent(String(requestParameters['uploaderId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Removes a datasource completely
     * Delete Datasource
     */
    async deleteDatasource(requestParameters: DeleteDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDatasourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of the headers in a CSV file
     * Detect File Headers
     */
    async detectUploaderFileSchemaRaw(requestParameters: DetectUploaderFileSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uploaderId'] == null) {
            throw new runtime.RequiredError(
                'uploaderId',
                'Required parameter "uploaderId" was null or undefined when calling detectUploaderFileSchema().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling detectUploaderFileSchema().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling detectUploaderFileSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_id}/schema/detect`.replace(`{${"uploader_id"}}`, encodeURIComponent(String(requestParameters['uploaderId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns a list of the headers in a CSV file
     * Detect File Headers
     */
    async detectUploaderFileSchema(requestParameters: DetectUploaderFileSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.detectUploaderFileSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Downloads a specific batch from the datasource
     * Download Batch
     */
    async downloadBatchRaw(requestParameters: DownloadBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['batchId'] == null) {
            throw new runtime.RequiredError(
                'batchId',
                'Required parameter "batchId" was null or undefined when calling downloadBatch().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/batch/{batch_id}/download`.replace(`{${"batch_id"}}`, encodeURIComponent(String(requestParameters['batchId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Downloads a specific batch from the datasource
     * Download Batch
     */
    async downloadBatch(requestParameters: DownloadBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Downloads a specific datasource as a CSV file
     * Download Uploader As Csv
     */
    async downloadUploaderAsCsvRaw(requestParameters: DownloadUploaderAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uploaderUuid'] == null) {
            throw new runtime.RequiredError(
                'uploaderUuid',
                'Required parameter "uploaderUuid" was null or undefined when calling downloadUploaderAsCsv().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadUploaderAsCsv().'
            );
        }

        if (requestParameters['downloadCSVParams'] == null) {
            throw new runtime.RequiredError(
                'downloadCSVParams',
                'Required parameter "downloadCSVParams" was null or undefined when calling downloadUploaderAsCsv().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_uuid}/csv`.replace(`{${"uploader_uuid"}}`, encodeURIComponent(String(requestParameters['uploaderUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadCSVParamsToJSON(requestParameters['downloadCSVParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Downloads a specific datasource as a CSV file
     * Download Uploader As Csv
     */
    async downloadUploaderAsCsv(requestParameters: DownloadUploaderAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadUploaderAsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle Uploader Favorite
     */
    async favoriteDatasourceRaw(requestParameters: FavoriteDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uploaderId'] == null) {
            throw new runtime.RequiredError(
                'uploaderId',
                'Required parameter "uploaderId" was null or undefined when calling favoriteDatasource().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling favoriteDatasource().'
            );
        }

        if (requestParameters['favoriteUploaderParams'] == null) {
            throw new runtime.RequiredError(
                'favoriteUploaderParams',
                'Required parameter "favoriteUploaderParams" was null or undefined when calling favoriteDatasource().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_id}/favorite`.replace(`{${"uploader_id"}}`, encodeURIComponent(String(requestParameters['uploaderId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteUploaderParamsToJSON(requestParameters['favoriteUploaderParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Toggle Uploader Favorite
     */
    async favoriteDatasource(requestParameters: FavoriteDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.favoriteDatasourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Batch Details
     */
    async fetchBatchDetailsRaw(requestParameters: FetchBatchDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadDetails>> {
        if (requestParameters['batchId'] == null) {
            throw new runtime.RequiredError(
                'batchId',
                'Required parameter "batchId" was null or undefined when calling fetchBatchDetails().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchBatchDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/batch/{batch_id}/details`.replace(`{${"batch_id"}}`, encodeURIComponent(String(requestParameters['batchId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadDetailsFromJSON(jsonValue));
    }

    /**
     * Fetch Batch Details
     */
    async fetchBatchDetails(requestParameters: FetchBatchDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadDetails> {
        const response = await this.fetchBatchDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all datasources available
     * Workspace Datasources
     */
    async fetchDatasourcesRaw(requestParameters: FetchDatasourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Uploader>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchDatasources().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasources`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploaderFromJSON));
    }

    /**
     * Retrieves all datasources available
     * Workspace Datasources
     */
    async fetchDatasources(requestParameters: FetchDatasourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Uploader>> {
        const response = await this.fetchDatasourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Task Status
     */
    async fetchTaskStatusRaw(requestParameters: FetchTaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchProcessTask>> {
        if (requestParameters['taskId'] == null) {
            throw new runtime.RequiredError(
                'taskId',
                'Required parameter "taskId" was null or undefined when calling fetchTaskStatus().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchTaskStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{task_id}/status`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters['taskId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchProcessTaskFromJSON(jsonValue));
    }

    /**
     * Fetch Task Status
     */
    async fetchTaskStatus(requestParameters: FetchTaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchProcessTask> {
        const response = await this.fetchTaskStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Uploader Data
     */
    async fetchUploaderDfRaw(requestParameters: FetchUploaderDfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OverviewTableData>> {
        if (requestParameters['uploaderId'] == null) {
            throw new runtime.RequiredError(
                'uploaderId',
                'Required parameter "uploaderId" was null or undefined when calling fetchUploaderDf().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchUploaderDf().'
            );
        }

        if (requestParameters['fetchUploaderDfParams'] == null) {
            throw new runtime.RequiredError(
                'fetchUploaderDfParams',
                'Required parameter "fetchUploaderDfParams" was null or undefined when calling fetchUploaderDf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_id}/df`.replace(`{${"uploader_id"}}`, encodeURIComponent(String(requestParameters['uploaderId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchUploaderDfParamsToJSON(requestParameters['fetchUploaderDfParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverviewTableDataFromJSON(jsonValue));
    }

    /**
     * Fetch Uploader Data
     */
    async fetchUploaderDf(requestParameters: FetchUploaderDfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OverviewTableData> {
        const response = await this.fetchUploaderDfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Uploader Owner
     */
    async removeUploaderOwnerRaw(requestParameters: RemoveUploaderOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Uploader>> {
        if (requestParameters['uploaderUuid'] == null) {
            throw new runtime.RequiredError(
                'uploaderUuid',
                'Required parameter "uploaderUuid" was null or undefined when calling removeUploaderOwner().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeUploaderOwner().'
            );
        }

        if (requestParameters['removeUploaderOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'removeUploaderOwnersParams',
                'Required parameter "removeUploaderOwnersParams" was null or undefined when calling removeUploaderOwner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/{uploader_uuid}/owners/remove`.replace(`{${"uploader_uuid"}}`, encodeURIComponent(String(requestParameters['uploaderUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveUploaderOwnersParamsToJSON(requestParameters['removeUploaderOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploaderFromJSON(jsonValue));
    }

    /**
     * Remove Uploader Owner
     */
    async removeUploaderOwner(requestParameters: RemoveUploaderOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Uploader> {
        const response = await this.removeUploaderOwnerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Datasource Details
     */
    async updateDatasourceRaw(requestParameters: UpdateDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uploaderId'] == null) {
            throw new runtime.RequiredError(
                'uploaderId',
                'Required parameter "uploaderId" was null or undefined when calling updateDatasource().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDatasource().'
            );
        }

        if (requestParameters['updateDataSourceParams'] == null) {
            throw new runtime.RequiredError(
                'updateDataSourceParams',
                'Required parameter "updateDataSourceParams" was null or undefined when calling updateDatasource().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_id}/update`.replace(`{${"uploader_id"}}`, encodeURIComponent(String(requestParameters['uploaderId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDataSourceParamsToJSON(requestParameters['updateDataSourceParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Datasource Details
     */
    async updateDatasource(requestParameters: UpdateDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateDatasourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the schema associated to a specific uploader
     * Update Datasource Schema
     */
    async updateDatasourceSchemaRaw(requestParameters: UpdateDatasourceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uploaderId'] == null) {
            throw new runtime.RequiredError(
                'uploaderId',
                'Required parameter "uploaderId" was null or undefined when calling updateDatasourceSchema().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDatasourceSchema().'
            );
        }

        if (requestParameters['updateSchemaParams'] == null) {
            throw new runtime.RequiredError(
                'updateSchemaParams',
                'Required parameter "updateSchemaParams" was null or undefined when calling updateDatasourceSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_id}/schema`.replace(`{${"uploader_id"}}`, encodeURIComponent(String(requestParameters['uploaderId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchemaParamsToJSON(requestParameters['updateSchemaParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the schema associated to a specific uploader
     * Update Datasource Schema
     */
    async updateDatasourceSchema(requestParameters: UpdateDatasourceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateDatasourceSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads a batch of information usually a csv file and appends to the datasource. This endpoint creates the uploader if missing
     * Upload Datasource Batches
     */
    async uploadDatasourceBatchesRaw(requestParameters: UploadDatasourceBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchProcessTask>>> {
        if (requestParameters['uploaderUuid'] == null) {
            throw new runtime.RequiredError(
                'uploaderUuid',
                'Required parameter "uploaderUuid" was null or undefined when calling uploadDatasourceBatches().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling uploadDatasourceBatches().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling uploadDatasourceBatches().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            requestParameters['file'].forEach((element) => {
                formParams.append('file', element as any);
            })
        }

        const response = await this.request({
            path: `/{workspace_code}/uploads/datasource/{uploader_uuid}/process`.replace(`{${"uploader_uuid"}}`, encodeURIComponent(String(requestParameters['uploaderUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchProcessTaskFromJSON));
    }

    /**
     * Uploads a batch of information usually a csv file and appends to the datasource. This endpoint creates the uploader if missing
     * Upload Datasource Batches
     */
    async uploadDatasourceBatches(requestParameters: UploadDatasourceBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchProcessTask>> {
        const response = await this.uploadDatasourceBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
