/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportModelParams
 */
export interface ImportModelParams {
    /**
     * 
     * @type {string}
     * @memberof ImportModelParams
     */
    targetWorkspaceUuid: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportModelParams
     */
    dimensionsMap?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportModelParams
     */
    scenariosMap?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ImportModelParams interface.
 */
export function instanceOfImportModelParams(value: object): boolean {
    if (!('targetWorkspaceUuid' in value)) return false;
    return true;
}

export function ImportModelParamsFromJSON(json: any): ImportModelParams {
    return ImportModelParamsFromJSONTyped(json, false);
}

export function ImportModelParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportModelParams {
    if (json == null) {
        return json;
    }
    return {
        
        'targetWorkspaceUuid': json['target_workspace_uuid'],
        'dimensionsMap': json['dimensions_map'] == null ? undefined : json['dimensions_map'],
        'scenariosMap': json['scenarios_map'] == null ? undefined : json['scenarios_map'],
    };
}

export function ImportModelParamsToJSON(value?: ImportModelParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'target_workspace_uuid': value['targetWorkspaceUuid'],
        'dimensions_map': value['dimensionsMap'],
        'scenarios_map': value['scenariosMap'],
    };
}

