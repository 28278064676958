/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDiscussion
 */
export interface CreateDiscussion {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscussion
     */
    comment: string;
}

/**
 * Check if a given object implements the CreateDiscussion interface.
 */
export function instanceOfCreateDiscussion(value: object): boolean {
    if (!('comment' in value)) return false;
    return true;
}

export function CreateDiscussionFromJSON(json: any): CreateDiscussion {
    return CreateDiscussionFromJSONTyped(json, false);
}

export function CreateDiscussionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDiscussion {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'],
    };
}

export function CreateDiscussionToJSON(value?: CreateDiscussion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
    };
}

