/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelPeriodGrainParams
 */
export interface UpdateModelPeriodGrainParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateModelPeriodGrainParams
     */
    grain: UpdateModelPeriodGrainParamsGrainEnum;
}


/**
 * @export
 */
export const UpdateModelPeriodGrainParamsGrainEnum = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year'
} as const;
export type UpdateModelPeriodGrainParamsGrainEnum = typeof UpdateModelPeriodGrainParamsGrainEnum[keyof typeof UpdateModelPeriodGrainParamsGrainEnum];


/**
 * Check if a given object implements the UpdateModelPeriodGrainParams interface.
 */
export function instanceOfUpdateModelPeriodGrainParams(value: object): boolean {
    if (!('grain' in value)) return false;
    return true;
}

export function UpdateModelPeriodGrainParamsFromJSON(json: any): UpdateModelPeriodGrainParams {
    return UpdateModelPeriodGrainParamsFromJSONTyped(json, false);
}

export function UpdateModelPeriodGrainParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelPeriodGrainParams {
    if (json == null) {
        return json;
    }
    return {
        
        'grain': json['grain'],
    };
}

export function UpdateModelPeriodGrainParamsToJSON(value?: UpdateModelPeriodGrainParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'grain': value['grain'],
    };
}

