/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelTableRow
 */
export interface ModelTableRow {
    /**
     * 
     * @type {string}
     * @memberof ModelTableRow
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTableRow
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ModelTableRow
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelTableRow
     */
    parentId?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelTableRow
     */
    idx?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelTableRow
     */
    depth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelTableRow
     */
    editable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelTableRow
     */
    unit: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelTableRow
     */
    isInherited?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModelTableRow
     */
    dimensionsValues?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ModelTableRow
     */
    variableUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTableRow
     */
    label: string;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ModelTableRow
     */
    cells: Array<Array<string>>;
}

/**
 * Check if a given object implements the ModelTableRow interface.
 */
export function instanceOfModelTableRow(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('id' in value)) return false;
    if (!('unit' in value)) return false;
    if (!('variableUuid' in value)) return false;
    if (!('label' in value)) return false;
    if (!('cells' in value)) return false;
    return true;
}

export function ModelTableRowFromJSON(json: any): ModelTableRow {
    return ModelTableRowFromJSONTyped(json, false);
}

export function ModelTableRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelTableRow {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'id': json['id_'],
        'orderId': json['order_id'] == null ? undefined : json['order_id'],
        'parentId': json['parent_id'] == null ? undefined : json['parent_id'],
        'idx': json['idx'] == null ? undefined : json['idx'],
        'depth': json['depth'] == null ? undefined : json['depth'],
        'editable': json['editable'] == null ? undefined : json['editable'],
        'unit': json['unit'],
        'isInherited': json['is_inherited'] == null ? undefined : json['is_inherited'],
        'dimensionsValues': json['dimensions_values'] == null ? undefined : json['dimensions_values'],
        'variableUuid': json['variable_uuid'],
        'label': json['label'],
        'cells': json['cells'],
    };
}

export function ModelTableRowToJSON(value?: ModelTableRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'id_': value['id'],
        'order_id': value['orderId'],
        'parent_id': value['parentId'],
        'idx': value['idx'],
        'depth': value['depth'],
        'editable': value['editable'],
        'unit': value['unit'],
        'is_inherited': value['isInherited'],
        'dimensions_values': value['dimensionsValues'],
        'variable_uuid': value['variableUuid'],
        'label': value['label'],
        'cells': value['cells'],
    };
}

