/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';

/**
 * 
 * @export
 * @interface UpdateDatabaseSchemaParams
 */
export interface UpdateDatabaseSchemaParams {
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof UpdateDatabaseSchemaParams
     */
    customSchema: Array<SchemaColumn>;
}

/**
 * Check if a given object implements the UpdateDatabaseSchemaParams interface.
 */
export function instanceOfUpdateDatabaseSchemaParams(value: object): boolean {
    if (!('customSchema' in value)) return false;
    return true;
}

export function UpdateDatabaseSchemaParamsFromJSON(json: any): UpdateDatabaseSchemaParams {
    return UpdateDatabaseSchemaParamsFromJSONTyped(json, false);
}

export function UpdateDatabaseSchemaParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDatabaseSchemaParams {
    if (json == null) {
        return json;
    }
    return {
        
        'customSchema': ((json['custom_schema'] as Array<any>).map(SchemaColumnFromJSON)),
    };
}

export function UpdateDatabaseSchemaParamsToJSON(value?: UpdateDatabaseSchemaParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'custom_schema': ((value['customSchema'] as Array<any>).map(SchemaColumnToJSON)),
    };
}

