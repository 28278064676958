/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommunityAsset,
  CommunityModelPreviewResponse,
  Discussion,
  HTTPValidationError,
  ImportModelParams,
  Model,
  PublishModelToCommunityParams,
  UpdateAssetDetailsParams,
} from '../models/index';
import {
    CommunityAssetFromJSON,
    CommunityAssetToJSON,
    CommunityModelPreviewResponseFromJSON,
    CommunityModelPreviewResponseToJSON,
    DiscussionFromJSON,
    DiscussionToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ImportModelParamsFromJSON,
    ImportModelParamsToJSON,
    ModelFromJSON,
    ModelToJSON,
    PublishModelToCommunityParamsFromJSON,
    PublishModelToCommunityParamsToJSON,
    UpdateAssetDetailsParamsFromJSON,
    UpdateAssetDetailsParamsToJSON,
} from '../models/index';

export interface FetchAssetDetailsRequest {
    assetUuid: string;
}

export interface FetchCommunityAssetsRequest {
    tags?: Array<string>;
}

export interface FetchCommunityTargetDiscussionsRequest {
    assetUuid: string;
}

export interface FetchModelPreviewDataRequest {
    modelUuid: string;
    versionUuid: string;
    scenarioUuid?: string;
}

export interface ImportModelToWorkspaceRequest {
    modelUuid: string;
    importModelParams: ImportModelParams;
}

export interface PublishModelToCommunityRequest {
    workspaceCode: string;
    modelUuid: string;
    publishModelToCommunityParams: PublishModelToCommunityParams;
}

export interface UnpublishCommunityAssetRequest {
    workspaceCode: string;
    assetUuid: string;
}

export interface UpdateAssetDetailsRequest {
    workspaceCode: string;
    assetUuid: string;
    updateAssetDetailsParams: UpdateAssetDetailsParams;
}

/**
 * 
 */
export class CommunityApi extends runtime.BaseAPI {

    /**
     * Fetch Asset Details
     */
    async fetchAssetDetailsRaw(requestParameters: FetchAssetDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommunityAsset>> {
        if (requestParameters['assetUuid'] == null) {
            throw new runtime.RequiredError(
                'assetUuid',
                'Required parameter "assetUuid" was null or undefined when calling fetchAssetDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/community/{asset_uuid}`.replace(`{${"asset_uuid"}}`, encodeURIComponent(String(requestParameters['assetUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunityAssetFromJSON(jsonValue));
    }

    /**
     * Fetch Asset Details
     */
    async fetchAssetDetails(requestParameters: FetchAssetDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommunityAsset> {
        const response = await this.fetchAssetDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Community Assets
     */
    async fetchCommunityAssetsRaw(requestParameters: FetchCommunityAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CommunityAsset>>> {
        const queryParameters: any = {};

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/community`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommunityAssetFromJSON));
    }

    /**
     * Fetch Community Assets
     */
    async fetchCommunityAssets(requestParameters: FetchCommunityAssetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CommunityAsset>> {
        const response = await this.fetchCommunityAssetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Target Discussions
     */
    async fetchCommunityTargetDiscussionsRaw(requestParameters: FetchCommunityTargetDiscussionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['assetUuid'] == null) {
            throw new runtime.RequiredError(
                'assetUuid',
                'Required parameter "assetUuid" was null or undefined when calling fetchCommunityTargetDiscussions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/community/{asset_uuid}/discussions`.replace(`{${"asset_uuid"}}`, encodeURIComponent(String(requestParameters['assetUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Target Discussions
     */
    async fetchCommunityTargetDiscussions(requestParameters: FetchCommunityTargetDiscussionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.fetchCommunityTargetDiscussionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Model Preview Data
     */
    async fetchModelPreviewDataRaw(requestParameters: FetchModelPreviewDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommunityModelPreviewResponse>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling fetchModelPreviewData().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling fetchModelPreviewData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['scenarioUuid'] != null) {
            queryParameters['scenario_uuid'] = requestParameters['scenarioUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/community/models/{model_uuid}/version/{version_uuid}/preview`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunityModelPreviewResponseFromJSON(jsonValue));
    }

    /**
     * Fetch Model Preview Data
     */
    async fetchModelPreviewData(requestParameters: FetchModelPreviewDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommunityModelPreviewResponse> {
        const response = await this.fetchModelPreviewDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import Model To Workspace
     */
    async importModelToWorkspaceRaw(requestParameters: ImportModelToWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling importModelToWorkspace().'
            );
        }

        if (requestParameters['importModelParams'] == null) {
            throw new runtime.RequiredError(
                'importModelParams',
                'Required parameter "importModelParams" was null or undefined when calling importModelToWorkspace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/community/models/{model_uuid}/import`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportModelParamsToJSON(requestParameters['importModelParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Import Model To Workspace
     */
    async importModelToWorkspace(requestParameters: ImportModelToWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.importModelToWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish Model To Community
     */
    async publishModelToCommunityRaw(requestParameters: PublishModelToCommunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommunityAsset>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling publishModelToCommunity().'
            );
        }

        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling publishModelToCommunity().'
            );
        }

        if (requestParameters['publishModelToCommunityParams'] == null) {
            throw new runtime.RequiredError(
                'publishModelToCommunityParams',
                'Required parameter "publishModelToCommunityParams" was null or undefined when calling publishModelToCommunity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/community/{workspace_code}/models/{model_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishModelToCommunityParamsToJSON(requestParameters['publishModelToCommunityParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunityAssetFromJSON(jsonValue));
    }

    /**
     * Publish Model To Community
     */
    async publishModelToCommunity(requestParameters: PublishModelToCommunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommunityAsset> {
        const response = await this.publishModelToCommunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unpublish Asset
     */
    async unpublishCommunityAssetRaw(requestParameters: UnpublishCommunityAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling unpublishCommunityAsset().'
            );
        }

        if (requestParameters['assetUuid'] == null) {
            throw new runtime.RequiredError(
                'assetUuid',
                'Required parameter "assetUuid" was null or undefined when calling unpublishCommunityAsset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/community/{workspace_code}/assets/{asset_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"asset_uuid"}}`, encodeURIComponent(String(requestParameters['assetUuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Unpublish Asset
     */
    async unpublishCommunityAsset(requestParameters: UnpublishCommunityAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.unpublishCommunityAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Asset Details
     */
    async updateAssetDetailsRaw(requestParameters: UpdateAssetDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateAssetDetails().'
            );
        }

        if (requestParameters['assetUuid'] == null) {
            throw new runtime.RequiredError(
                'assetUuid',
                'Required parameter "assetUuid" was null or undefined when calling updateAssetDetails().'
            );
        }

        if (requestParameters['updateAssetDetailsParams'] == null) {
            throw new runtime.RequiredError(
                'updateAssetDetailsParams',
                'Required parameter "updateAssetDetailsParams" was null or undefined when calling updateAssetDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/community/{workspace_code}/assets/{asset_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"asset_uuid"}}`, encodeURIComponent(String(requestParameters['assetUuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAssetDetailsParamsToJSON(requestParameters['updateAssetDetailsParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Asset Details
     */
    async updateAssetDetails(requestParameters: UpdateAssetDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateAssetDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
