/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDatabaseOwnersParams
 */
export interface UpdateDatabaseOwnersParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDatabaseOwnersParams
     */
    owners: Array<string>;
}

/**
 * Check if a given object implements the UpdateDatabaseOwnersParams interface.
 */
export function instanceOfUpdateDatabaseOwnersParams(value: object): boolean {
    if (!('owners' in value)) return false;
    return true;
}

export function UpdateDatabaseOwnersParamsFromJSON(json: any): UpdateDatabaseOwnersParams {
    return UpdateDatabaseOwnersParamsFromJSONTyped(json, false);
}

export function UpdateDatabaseOwnersParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDatabaseOwnersParams {
    if (json == null) {
        return json;
    }
    return {
        
        'owners': json['owners'],
    };
}

export function UpdateDatabaseOwnersParamsToJSON(value?: UpdateDatabaseOwnersParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owners': value['owners'],
    };
}

