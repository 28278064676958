import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import router from './lib/router';
import useRollbar from './composables/useRollbar';
import '~/assets/css/tailwind.css';
import '~/assets/css/global.css';

const pinia = createPinia();
const head = createHead();
const app = createApp(App);
const rollbar = useRollbar();

app.config.warnHandler = (msg, _instance, trace) => {
    if (process.env.NODE_ENV === 'production') {
        rollbar.warning(msg, trace);
    } else {
        console.warn(msg, trace);
    }

};
app.config.errorHandler = (err, _instance) => {
    if (process.env.NODE_ENV === 'production') {
        rollbar.error(err as Error);
    } else {
        throw err;
    }
};

app.use(head);
app.use(router);
app.use(pinia);
app.mount('#app');
