/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';

/**
 * 
 * @export
 * @interface Discussion
 */
export interface Discussion {
    /**
     * 
     * @type {string}
     * @memberof Discussion
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Discussion
     */
    targetUuid: string;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Discussion
     */
    comments: Array<Comment>;
    /**
     * 
     * @type {Date}
     * @memberof Discussion
     */
    resolvedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Discussion
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Discussion interface.
 */
export function instanceOfDiscussion(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('targetUuid' in value)) return false;
    if (!('comments' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function DiscussionFromJSON(json: any): Discussion {
    return DiscussionFromJSONTyped(json, false);
}

export function DiscussionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discussion {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'targetUuid': json['target_uuid'],
        'comments': ((json['comments'] as Array<any>).map(CommentFromJSON)),
        'resolvedAt': json['resolved_at'] == null ? undefined : (new Date(json['resolved_at'])),
        'createdAt': (new Date(json['created_at'])),
    };
}

export function DiscussionToJSON(value?: Discussion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'target_uuid': value['targetUuid'],
        'comments': ((value['comments'] as Array<any>).map(CommentToJSON)),
        'resolved_at': value['resolvedAt'] == null ? undefined : ((value['resolvedAt'] as any).toISOString()),
        'created_at': ((value['createdAt']).toISOString()),
    };
}

