/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosingDate } from './ClosingDate';
import {
    ClosingDateFromJSON,
    ClosingDateFromJSONTyped,
    ClosingDateToJSON,
} from './ClosingDate';

/**
 * 
 * @export
 * @interface ScenarioTreeNode
 */
export interface ScenarioTreeNode {
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    sourceScenario?: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    sourceScenarioUuid?: string;
    /**
     * 
     * @type {Array<ScenarioTreeNode>}
     * @memberof ScenarioTreeNode
     */
    branchedScenarios?: Array<ScenarioTreeNode>;
    /**
     * 
     * @type {number}
     * @memberof ScenarioTreeNode
     */
    periodNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    periodSize?: ScenarioTreeNodePeriodSizeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScenarioTreeNode
     */
    changes?: number;
    /**
     * 
     * @type {ClosingDate}
     * @memberof ScenarioTreeNode
     */
    closingDate?: ClosingDate;
    /**
     * 
     * @type {string}
     * @memberof ScenarioTreeNode
     */
    actualsScenarioUuid?: string;
}


/**
 * @export
 */
export const ScenarioTreeNodePeriodSizeEnum = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year'
} as const;
export type ScenarioTreeNodePeriodSizeEnum = typeof ScenarioTreeNodePeriodSizeEnum[keyof typeof ScenarioTreeNodePeriodSizeEnum];


/**
 * Check if a given object implements the ScenarioTreeNode interface.
 */
export function instanceOfScenarioTreeNode(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function ScenarioTreeNodeFromJSON(json: any): ScenarioTreeNode {
    return ScenarioTreeNodeFromJSONTyped(json, false);
}

export function ScenarioTreeNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioTreeNode {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'color': json['color'] == null ? undefined : json['color'],
        'description': json['description'] == null ? undefined : json['description'],
        'sourceScenario': json['source_scenario'] == null ? undefined : json['source_scenario'],
        'sourceScenarioUuid': json['source_scenario_uuid'] == null ? undefined : json['source_scenario_uuid'],
        'branchedScenarios': json['branched_scenarios'] == null ? undefined : ((json['branched_scenarios'] as Array<any>).map(ScenarioTreeNodeFromJSON)),
        'periodNumber': json['period_number'] == null ? undefined : json['period_number'],
        'periodSize': json['period_size'] == null ? undefined : json['period_size'],
        'changes': json['changes'] == null ? undefined : json['changes'],
        'closingDate': json['closing_date'] == null ? undefined : ClosingDateFromJSON(json['closing_date']),
        'actualsScenarioUuid': json['actuals_scenario_uuid'] == null ? undefined : json['actuals_scenario_uuid'],
    };
}

export function ScenarioTreeNodeToJSON(value?: ScenarioTreeNode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'color': value['color'],
        'description': value['description'],
        'source_scenario': value['sourceScenario'],
        'source_scenario_uuid': value['sourceScenarioUuid'],
        'branched_scenarios': value['branchedScenarios'] == null ? undefined : ((value['branchedScenarios'] as Array<any>).map(ScenarioTreeNodeToJSON)),
        'period_number': value['periodNumber'],
        'period_size': value['periodSize'],
        'changes': value['changes'],
        'closing_date': ClosingDateToJSON(value['closingDate']),
        'actuals_scenario_uuid': value['actualsScenarioUuid'],
    };
}

