/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VariableExpression } from './VariableExpression';
import {
    VariableExpressionFromJSON,
    VariableExpressionFromJSONTyped,
    VariableExpressionToJSON,
} from './VariableExpression';

/**
 * 
 * @export
 * @interface VariableDimension
 */
export interface VariableDimension {
    /**
     * 
     * @type {string}
     * @memberof VariableDimension
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof VariableDimension
     */
    variableUuid: string;
    /**
     * 
     * @type {string}
     * @memberof VariableDimension
     */
    parentDimensionUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VariableDimension
     */
    wsDimensionUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VariableDimension
     */
    isInherited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VariableDimension
     */
    value?: string;
    /**
     * 
     * @type {Array<VariableDimension>}
     * @memberof VariableDimension
     */
    childrenDimensions?: Array<VariableDimension>;
    /**
     * 
     * @type {number}
     * @memberof VariableDimension
     */
    orderId?: number;
    /**
     * 
     * @type {Array<VariableExpression>}
     * @memberof VariableDimension
     */
    expressions?: Array<VariableExpression>;
}

/**
 * Check if a given object implements the VariableDimension interface.
 */
export function instanceOfVariableDimension(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('variableUuid' in value)) return false;
    return true;
}

export function VariableDimensionFromJSON(json: any): VariableDimension {
    return VariableDimensionFromJSONTyped(json, false);
}

export function VariableDimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableDimension {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'variableUuid': json['variable_uuid'],
        'parentDimensionUuid': json['parent_dimension_uuid'] == null ? undefined : json['parent_dimension_uuid'],
        'wsDimensionUuid': json['ws_dimension_uuid'] == null ? undefined : json['ws_dimension_uuid'],
        'isInherited': json['is_inherited'] == null ? undefined : json['is_inherited'],
        'value': json['value'] == null ? undefined : json['value'],
        'childrenDimensions': json['children_dimensions'] == null ? undefined : ((json['children_dimensions'] as Array<any>).map(VariableDimensionFromJSON)),
        'orderId': json['order_id'] == null ? undefined : json['order_id'],
        'expressions': json['expressions'] == null ? undefined : ((json['expressions'] as Array<any>).map(VariableExpressionFromJSON)),
    };
}

export function VariableDimensionToJSON(value?: VariableDimension | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'variable_uuid': value['variableUuid'],
        'parent_dimension_uuid': value['parentDimensionUuid'],
        'ws_dimension_uuid': value['wsDimensionUuid'],
        'is_inherited': value['isInherited'],
        'value': value['value'],
        'children_dimensions': value['childrenDimensions'] == null ? undefined : ((value['childrenDimensions'] as Array<any>).map(VariableDimensionToJSON)),
        'order_id': value['orderId'],
        'expressions': value['expressions'] == null ? undefined : ((value['expressions'] as Array<any>).map(VariableExpressionToJSON)),
    };
}

