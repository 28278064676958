/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Helper class to upload a mapping file and determine the
 * columns of the file that will be used as mapping options.
 * A MappingUploadSettings is each of the mappings that the
 * user wants to extract from a mapping upload.
 * @export
 * @interface MappingUploadSettings
 */
export interface MappingUploadSettings {
    /**
     * 
     * @type {string}
     * @memberof MappingUploadSettings
     */
    originCol: string;
    /**
     * 
     * @type {string}
     * @memberof MappingUploadSettings
     */
    destCol: string;
    /**
     * 
     * @type {string}
     * @memberof MappingUploadSettings
     */
    operation: MappingUploadSettingsOperationEnum;
}


/**
 * @export
 */
export const MappingUploadSettingsOperationEnum = {
    Eq: 'eq',
    Contains: 'contains',
    StartsWith: 'startsWith',
    EndsWith: 'endsWith',
    Neq: 'neq',
    Before: 'before',
    After: 'after'
} as const;
export type MappingUploadSettingsOperationEnum = typeof MappingUploadSettingsOperationEnum[keyof typeof MappingUploadSettingsOperationEnum];


/**
 * Check if a given object implements the MappingUploadSettings interface.
 */
export function instanceOfMappingUploadSettings(value: object): boolean {
    if (!('originCol' in value)) return false;
    if (!('destCol' in value)) return false;
    if (!('operation' in value)) return false;
    return true;
}

export function MappingUploadSettingsFromJSON(json: any): MappingUploadSettings {
    return MappingUploadSettingsFromJSONTyped(json, false);
}

export function MappingUploadSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingUploadSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'originCol': json['origin_col'],
        'destCol': json['dest_col'],
        'operation': json['operation'],
    };
}

export function MappingUploadSettingsToJSON(value?: MappingUploadSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'origin_col': value['originCol'],
        'dest_col': value['destCol'],
        'operation': value['operation'],
    };
}

