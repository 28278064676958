/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportContributor
 */
export interface ReportContributor {
    /**
     * 
     * @type {string}
     * @memberof ReportContributor
     */
    reportUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ReportContributor
     */
    userUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ReportContributor
     */
    name: ReportContributorNameEnum;
}


/**
 * @export
 */
export const ReportContributorNameEnum = {
    Owner: 'owner',
    Contributor: 'contributor',
    Guest: 'guest'
} as const;
export type ReportContributorNameEnum = typeof ReportContributorNameEnum[keyof typeof ReportContributorNameEnum];


/**
 * Check if a given object implements the ReportContributor interface.
 */
export function instanceOfReportContributor(value: object): boolean {
    if (!('reportUuid' in value)) return false;
    if (!('userUuid' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function ReportContributorFromJSON(json: any): ReportContributor {
    return ReportContributorFromJSONTyped(json, false);
}

export function ReportContributorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportContributor {
    if (json == null) {
        return json;
    }
    return {
        
        'reportUuid': json['report_uuid'],
        'userUuid': json['user_uuid'],
        'name': json['name'],
    };
}

export function ReportContributorToJSON(value?: ReportContributor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'report_uuid': value['reportUuid'],
        'user_uuid': value['userUuid'],
        'name': value['name'],
    };
}

