/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisplayModelVersion
 */
export interface DisplayModelVersion {
    /**
     * 
     * @type {string}
     * @memberof DisplayModelVersion
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModelVersion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModelVersion
     */
    modelUuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModelVersion
     */
    publicToken?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModelVersion
     */
    excelDownloadUrl?: string;
}

/**
 * Check if a given object implements the DisplayModelVersion interface.
 */
export function instanceOfDisplayModelVersion(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('modelUuid' in value)) return false;
    return true;
}

export function DisplayModelVersionFromJSON(json: any): DisplayModelVersion {
    return DisplayModelVersionFromJSONTyped(json, false);
}

export function DisplayModelVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayModelVersion {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'modelUuid': json['model_uuid'],
        'publicToken': json['public_token'] == null ? undefined : json['public_token'],
        'excelDownloadUrl': json['excel_download_url'] == null ? undefined : json['excel_download_url'],
    };
}

export function DisplayModelVersionToJSON(value?: DisplayModelVersion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'model_uuid': value['modelUuid'],
        'public_token': value['publicToken'],
        'excel_download_url': value['excelDownloadUrl'],
    };
}

