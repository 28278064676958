/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CofiApiRoutersMetricsFavoriteParams
 */
export interface CofiApiRoutersMetricsFavoriteParams {
    /**
     * 
     * @type {string}
     * @memberof CofiApiRoutersMetricsFavoriteParams
     */
    metricUuid: string;
    /**
     * 
     * @type {boolean}
     * @memberof CofiApiRoutersMetricsFavoriteParams
     */
    state: boolean;
}

/**
 * Check if a given object implements the CofiApiRoutersMetricsFavoriteParams interface.
 */
export function instanceOfCofiApiRoutersMetricsFavoriteParams(value: object): boolean {
    if (!('metricUuid' in value)) return false;
    if (!('state' in value)) return false;
    return true;
}

export function CofiApiRoutersMetricsFavoriteParamsFromJSON(json: any): CofiApiRoutersMetricsFavoriteParams {
    return CofiApiRoutersMetricsFavoriteParamsFromJSONTyped(json, false);
}

export function CofiApiRoutersMetricsFavoriteParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CofiApiRoutersMetricsFavoriteParams {
    if (json == null) {
        return json;
    }
    return {
        
        'metricUuid': json['metric_uuid'],
        'state': json['state'],
    };
}

export function CofiApiRoutersMetricsFavoriteParamsToJSON(value?: CofiApiRoutersMetricsFavoriteParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metric_uuid': value['metricUuid'],
        'state': value['state'],
    };
}

