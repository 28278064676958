/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BridgeData } from './BridgeData';
import {
    BridgeDataFromJSON,
    BridgeDataFromJSONTyped,
    BridgeDataToJSON,
} from './BridgeData';
import type { HistogramData } from './HistogramData';
import {
    HistogramDataFromJSON,
    HistogramDataFromJSONTyped,
    HistogramDataToJSON,
} from './HistogramData';
import type { PiechartData } from './PiechartData';
import {
    PiechartDataFromJSON,
    PiechartDataFromJSONTyped,
    PiechartDataToJSON,
} from './PiechartData';
import type { PivotTableData } from './PivotTableData';
import {
    PivotTableDataFromJSON,
    PivotTableDataFromJSONTyped,
    PivotTableDataToJSON,
} from './PivotTableData';
import type { PivotTableHeader } from './PivotTableHeader';
import {
    PivotTableHeaderFromJSON,
    PivotTableHeaderFromJSONTyped,
    PivotTableHeaderToJSON,
} from './PivotTableHeader';
import type { PivotTableRow } from './PivotTableRow';
import {
    PivotTableRowFromJSON,
    PivotTableRowFromJSONTyped,
    PivotTableRowToJSON,
} from './PivotTableRow';
import type { ReportComparativeTableData } from './ReportComparativeTableData';
import {
    ReportComparativeTableDataFromJSON,
    ReportComparativeTableDataFromJSONTyped,
    ReportComparativeTableDataToJSON,
} from './ReportComparativeTableData';
import type { ReportTableData } from './ReportTableData';
import {
    ReportTableDataFromJSON,
    ReportTableDataFromJSONTyped,
    ReportTableDataToJSON,
} from './ReportTableData';

/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {object}
     * @memberof Data
     */
    type?: object;
    /**
     * 
     * @type {Array<Array<object>>}
     * @memberof Data
     */
    userRequested?: Array<Array<object>>;
    /**
     * 
     * @type {Array<Array<object>>}
     * @memberof Data
     */
    yearlyRecords?: Array<Array<object>>;
    /**
     * 
     * @type {Array<PivotTableHeader>}
     * @memberof Data
     */
    headers?: Array<PivotTableHeader>;
    /**
     * 
     * @type {Array<PivotTableRow>}
     * @memberof Data
     */
    rows?: Array<PivotTableRow>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Data
     */
    structure?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Data
     */
    data?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Data
     */
    diffs?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof Data
     */
    firstScenario?: object;
    /**
     * 
     * @type {object}
     * @memberof Data
     */
    secondScenario?: object;
    /**
     * 
     * @type {string}
     * @memberof Data
     */
    scenarioName?: string;
    /**
     * 
     * @type {string}
     * @memberof Data
     */
    scenarioUuid?: string;
}

/**
 * Check if a given object implements the Data interface.
 */
export function instanceOfData(value: object): boolean {
    return true;
}

export function DataFromJSON(json: any): Data {
    return DataFromJSONTyped(json, false);
}

export function DataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Data {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'userRequested': json['user_requested'] == null ? undefined : json['user_requested'],
        'yearlyRecords': json['yearly_records'] == null ? undefined : json['yearly_records'],
        'headers': json['headers'] == null ? undefined : ((json['headers'] as Array<any>).map(PivotTableHeaderFromJSON)),
        'rows': json['rows'] == null ? undefined : ((json['rows'] as Array<any>).map(PivotTableRowFromJSON)),
        'structure': json['structure'] == null ? undefined : json['structure'],
        'data': json['data'] == null ? undefined : json['data'],
        'diffs': json['diffs'] == null ? undefined : json['diffs'],
        'firstScenario': json['first_scenario'] == null ? undefined : json['first_scenario'],
        'secondScenario': json['second_scenario'] == null ? undefined : json['second_scenario'],
        'scenarioName': json['scenario_name'] == null ? undefined : json['scenario_name'],
        'scenarioUuid': json['scenario_uuid'] == null ? undefined : json['scenario_uuid'],
    };
}

export function DataToJSON(value?: Data | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'user_requested': value['userRequested'],
        'yearly_records': value['yearlyRecords'],
        'headers': value['headers'] == null ? undefined : ((value['headers'] as Array<any>).map(PivotTableHeaderToJSON)),
        'rows': value['rows'] == null ? undefined : ((value['rows'] as Array<any>).map(PivotTableRowToJSON)),
        'structure': value['structure'],
        'data': value['data'],
        'diffs': value['diffs'],
        'first_scenario': value['firstScenario'],
        'second_scenario': value['secondScenario'],
        'scenario_name': value['scenarioName'],
        'scenario_uuid': value['scenarioUuid'],
    };
}

