/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AskQuestionResponse
 */
export interface AskQuestionResponse {
    /**
     * 
     * @type {string}
     * @memberof AskQuestionResponse
     */
    conversationUuid: string;
    /**
     * 
     * @type {string}
     * @memberof AskQuestionResponse
     */
    answer: string;
}

/**
 * Check if a given object implements the AskQuestionResponse interface.
 */
export function instanceOfAskQuestionResponse(value: object): boolean {
    if (!('conversationUuid' in value)) return false;
    if (!('answer' in value)) return false;
    return true;
}

export function AskQuestionResponseFromJSON(json: any): AskQuestionResponse {
    return AskQuestionResponseFromJSONTyped(json, false);
}

export function AskQuestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AskQuestionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'conversationUuid': json['conversation_uuid'],
        'answer': json['answer'],
    };
}

export function AskQuestionResponseToJSON(value?: AskQuestionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conversation_uuid': value['conversationUuid'],
        'answer': value['answer'],
    };
}

