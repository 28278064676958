/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SourcesChartNode } from './SourcesChartNode';
import {
    SourcesChartNodeFromJSON,
    SourcesChartNodeFromJSONTyped,
    SourcesChartNodeToJSON,
} from './SourcesChartNode';
import type { SourcesTableRow } from './SourcesTableRow';
import {
    SourcesTableRowFromJSON,
    SourcesTableRowFromJSONTyped,
    SourcesTableRowToJSON,
} from './SourcesTableRow';

/**
 * 
 * @export
 * @interface SourcesData
 */
export interface SourcesData {
    /**
     * 
     * @type {Array<SourcesTableRow>}
     * @memberof SourcesData
     */
    table: Array<SourcesTableRow>;
    /**
     * 
     * @type {SourcesChartNode}
     * @memberof SourcesData
     */
    chart: SourcesChartNode;
}

/**
 * Check if a given object implements the SourcesData interface.
 */
export function instanceOfSourcesData(value: object): boolean {
    if (!('table' in value)) return false;
    if (!('chart' in value)) return false;
    return true;
}

export function SourcesDataFromJSON(json: any): SourcesData {
    return SourcesDataFromJSONTyped(json, false);
}

export function SourcesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourcesData {
    if (json == null) {
        return json;
    }
    return {
        
        'table': ((json['table'] as Array<any>).map(SourcesTableRowFromJSON)),
        'chart': SourcesChartNodeFromJSON(json['chart']),
    };
}

export function SourcesDataToJSON(value?: SourcesData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'table': ((value['table'] as Array<any>).map(SourcesTableRowToJSON)),
        'chart': SourcesChartNodeToJSON(value['chart']),
    };
}

