/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DatabaseOwner } from './DatabaseOwner';
import {
    DatabaseOwnerFromJSON,
    DatabaseOwnerFromJSONTyped,
    DatabaseOwnerToJSON,
} from './DatabaseOwner';
import type { DatabaseRelationship } from './DatabaseRelationship';
import {
    DatabaseRelationshipFromJSON,
    DatabaseRelationshipFromJSONTyped,
    DatabaseRelationshipToJSON,
} from './DatabaseRelationship';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';

/**
 * Database model.
 * @export
 * @interface Database
 */
export interface Database {
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    description: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Database
     */
    isPublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    createdByUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Database
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Database
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    groupUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    status?: DatabaseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    statusDetails?: string;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof Database
     */
    schema?: Array<SchemaColumn>;
    /**
     * 
     * @type {Array<DatabaseRelationship>}
     * @memberof Database
     */
    relationships?: Array<DatabaseRelationship>;
    /**
     * 
     * @type {Array<DatabaseOwner>}
     * @memberof Database
     */
    owners?: Array<DatabaseOwner>;
}


/**
 * @export
 */
export const DatabaseStatusEnum = {
    Ok: 'ok',
    Error: 'error',
    Pending: 'pending'
} as const;
export type DatabaseStatusEnum = typeof DatabaseStatusEnum[keyof typeof DatabaseStatusEnum];


/**
 * Check if a given object implements the Database interface.
 */
export function instanceOfDatabase(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('description' in value)) return false;
    if (!('isPublic' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('createdByUuid' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function DatabaseFromJSON(json: any): Database {
    return DatabaseFromJSONTyped(json, false);
}

export function DatabaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Database {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'code': json['code'],
        'description': json['description'],
        'isPublic': json['is_public'],
        'workspaceUuid': json['workspace_uuid'],
        'createdByUuid': json['created_by_uuid'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'status': json['status'] == null ? undefined : json['status'],
        'statusDetails': json['status_details'] == null ? undefined : json['status_details'],
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'relationships': json['relationships'] == null ? undefined : ((json['relationships'] as Array<any>).map(DatabaseRelationshipFromJSON)),
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(DatabaseOwnerFromJSON)),
    };
}

export function DatabaseToJSON(value?: Database | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'code': value['code'],
        'description': value['description'],
        'is_public': value['isPublic'],
        'workspace_uuid': value['workspaceUuid'],
        'created_by_uuid': value['createdByUuid'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'group_uuid': value['groupUuid'],
        'status': value['status'],
        'status_details': value['statusDetails'],
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
        'relationships': value['relationships'] == null ? undefined : ((value['relationships'] as Array<any>).map(DatabaseRelationshipToJSON)),
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(DatabaseOwnerToJSON)),
    };
}

