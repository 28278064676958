/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddMetricOwnersParams,
  CofiApiRoutersMetricsFavoriteParams,
  CreateCategory,
  CreateMetric,
  DrillDownParams,
  DuplicateMetricParams,
  HTTPValidationError,
  IsRecomputingResponse,
  Metric,
  MetricCategory,
  MetricSourcesParams,
  MetricUnit,
  PivotTableCsvParams,
  PivotTableParams,
  RemoveMetricOwnersParams,
  Report,
  SourcesData,
  Summary,
  UpdateCategoryParams,
  UpdateMetric,
  UpdateMetricAggregateFunctionParams,
  UpdateProperty,
  ValidateFormulaParams,
} from '../models/index';
import {
    AddMetricOwnersParamsFromJSON,
    AddMetricOwnersParamsToJSON,
    CofiApiRoutersMetricsFavoriteParamsFromJSON,
    CofiApiRoutersMetricsFavoriteParamsToJSON,
    CreateCategoryFromJSON,
    CreateCategoryToJSON,
    CreateMetricFromJSON,
    CreateMetricToJSON,
    DrillDownParamsFromJSON,
    DrillDownParamsToJSON,
    DuplicateMetricParamsFromJSON,
    DuplicateMetricParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    IsRecomputingResponseFromJSON,
    IsRecomputingResponseToJSON,
    MetricFromJSON,
    MetricToJSON,
    MetricCategoryFromJSON,
    MetricCategoryToJSON,
    MetricSourcesParamsFromJSON,
    MetricSourcesParamsToJSON,
    MetricUnitFromJSON,
    MetricUnitToJSON,
    PivotTableCsvParamsFromJSON,
    PivotTableCsvParamsToJSON,
    PivotTableParamsFromJSON,
    PivotTableParamsToJSON,
    RemoveMetricOwnersParamsFromJSON,
    RemoveMetricOwnersParamsToJSON,
    ReportFromJSON,
    ReportToJSON,
    SourcesDataFromJSON,
    SourcesDataToJSON,
    SummaryFromJSON,
    SummaryToJSON,
    UpdateCategoryParamsFromJSON,
    UpdateCategoryParamsToJSON,
    UpdateMetricFromJSON,
    UpdateMetricToJSON,
    UpdateMetricAggregateFunctionParamsFromJSON,
    UpdateMetricAggregateFunctionParamsToJSON,
    UpdatePropertyFromJSON,
    UpdatePropertyToJSON,
    ValidateFormulaParamsFromJSON,
    ValidateFormulaParamsToJSON,
} from '../models/index';

export interface AddMetricOwnersRequest {
    metricUuid: string;
    workspaceCode: string;
    addMetricOwnersParams: AddMetricOwnersParams;
}

export interface BatchCreateMetricsRequest {
    workspaceCode: string;
    overwrite: boolean;
    file: Blob;
}

export interface CheckWorkspaceIsRecomputingRequest {
    workspaceCode: string;
}

export interface ComputeMetricDrilldownRequest {
    metricUuid: string;
    workspaceCode: string;
    drillDownParams: DrillDownParams;
}

export interface ComputeMetricValuesRequest {
    metricUuid: string;
    workspaceCode: string;
    pivotTableParams: PivotTableParams;
}

export interface CreateCategoryRequest {
    workspaceCode: string;
    createCategory: CreateCategory;
}

export interface CreateMetricRequest {
    workspaceCode: string;
    createMetric: CreateMetric;
}

export interface DeleteCategoryRequest {
    categoryUuid: string;
    workspaceCode: string;
}

export interface DeleteMetricRequest {
    metricUuid: string;
    workspaceCode: string;
}

export interface DuplicateMetricRequest {
    metricUuid: string;
    workspaceCode: string;
    duplicateMetricParams: DuplicateMetricParams;
}

export interface ExportMetricDataCSVRequest {
    metricUuid: string;
    workspaceCode: string;
    pivotTableCsvParams: PivotTableCsvParams;
}

export interface FetchMetricSourcesRequest {
    metricUuid: string;
    scenarioUuid: string;
    workspaceCode: string;
    metricSourcesParams: MetricSourcesParams;
}

export interface FetchMetricUnitsRequest {
    workspaceCode: string;
}

export interface FetchMetricsSummaryRequest {
    workspaceCode: string;
}

export interface FetchMetricsValuesRequest {
    workspaceCode: string;
}

export interface FetchReportsByMetricRequest {
    metricUuid: string;
    workspaceCode: string;
}

export interface ForceMetricsRecomputeRequest {
    workspaceCode: string;
}

export interface RemoveMetricOwnerRequest {
    metricUuid: string;
    workspaceCode: string;
    removeMetricOwnersParams: RemoveMetricOwnersParams;
}

export interface SetMetricFavoriteStatusRequest {
    workspaceCode: string;
    cofiApiRoutersMetricsFavoriteParams: CofiApiRoutersMetricsFavoriteParams;
}

export interface UpdateCategoryRequest {
    categoryUuid: string;
    workspaceCode: string;
    updateCategoryParams: UpdateCategoryParams;
}

export interface UpdateMetricRequest {
    metricUuid: string;
    workspaceCode: string;
    updateMetric: UpdateMetric;
}

export interface UpdateMetricAggregateFunctionRequest {
    metricUuid: string;
    workspaceCode: string;
    updateMetricAggregateFunctionParams: UpdateMetricAggregateFunctionParams;
}

export interface UpdateMetricCodeRequest {
    metricUuid: string;
    workspaceCode: string;
    updateProperty: UpdateProperty;
}

export interface UpdateMetricScenarioFormulaRequest {
    metricUuid: string;
    scenarioUuid: string;
    workspaceCode: string;
    updateProperty: UpdateProperty;
}

export interface ValidateMetricFormulaRequest {
    metricUuid: string;
    scenarioUuid: string;
    workspaceCode: string;
    validateFormulaParams: ValidateFormulaParams;
}

/**
 * 
 */
export class MetricsApi extends runtime.BaseAPI {

    /**
     * Add Metric Owners
     */
    async addMetricOwnersRaw(requestParameters: AddMetricOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Metric>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling addMetricOwners().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addMetricOwners().'
            );
        }

        if (requestParameters['addMetricOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'addMetricOwnersParams',
                'Required parameter "addMetricOwnersParams" was null or undefined when calling addMetricOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/owners`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMetricOwnersParamsToJSON(requestParameters['addMetricOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricFromJSON(jsonValue));
    }

    /**
     * Add Metric Owners
     */
    async addMetricOwners(requestParameters: AddMetricOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Metric> {
        const response = await this.addMetricOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Batch Create Metrics
     */
    async batchCreateMetricsRaw(requestParameters: BatchCreateMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling batchCreateMetrics().'
            );
        }

        if (requestParameters['overwrite'] == null) {
            throw new runtime.RequiredError(
                'overwrite',
                'Required parameter "overwrite" was null or undefined when calling batchCreateMetrics().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling batchCreateMetrics().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['overwrite'] != null) {
            formParams.append('overwrite', requestParameters['overwrite'] as any);
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/batch`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Batch Create Metrics
     */
    async batchCreateMetrics(requestParameters: BatchCreateMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.batchCreateMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check Worker Is Recomputing
     */
    async checkWorkspaceIsRecomputingRaw(requestParameters: CheckWorkspaceIsRecomputingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsRecomputingResponse>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling checkWorkspaceIsRecomputing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/is_recomputing`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsRecomputingResponseFromJSON(jsonValue));
    }

    /**
     * Check Worker Is Recomputing
     */
    async checkWorkspaceIsRecomputing(requestParameters: CheckWorkspaceIsRecomputingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsRecomputingResponse> {
        const response = await this.checkWorkspaceIsRecomputingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Compute Metric Drilldown
     */
    async computeMetricDrilldownRaw(requestParameters: ComputeMetricDrilldownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling computeMetricDrilldown().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling computeMetricDrilldown().'
            );
        }

        if (requestParameters['drillDownParams'] == null) {
            throw new runtime.RequiredError(
                'drillDownParams',
                'Required parameter "drillDownParams" was null or undefined when calling computeMetricDrilldown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/drilldown`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillDownParamsToJSON(requestParameters['drillDownParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Compute Metric Drilldown
     */
    async computeMetricDrilldown(requestParameters: ComputeMetricDrilldownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.computeMetricDrilldownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Compute Metric Values
     */
    async computeMetricValuesRaw(requestParameters: ComputeMetricValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling computeMetricValues().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling computeMetricValues().'
            );
        }

        if (requestParameters['pivotTableParams'] == null) {
            throw new runtime.RequiredError(
                'pivotTableParams',
                'Required parameter "pivotTableParams" was null or undefined when calling computeMetricValues().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/df`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PivotTableParamsToJSON(requestParameters['pivotTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Compute Metric Values
     */
    async computeMetricValues(requestParameters: ComputeMetricValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.computeMetricValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Category
     */
    async createCategoryRaw(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetricCategory>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createCategory().'
            );
        }

        if (requestParameters['createCategory'] == null) {
            throw new runtime.RequiredError(
                'createCategory',
                'Required parameter "createCategory" was null or undefined when calling createCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/categories`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCategoryToJSON(requestParameters['createCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricCategoryFromJSON(jsonValue));
    }

    /**
     * Create Category
     */
    async createCategory(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetricCategory> {
        const response = await this.createCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Metric
     */
    async createMetricRaw(requestParameters: CreateMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Metric>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createMetric().'
            );
        }

        if (requestParameters['createMetric'] == null) {
            throw new runtime.RequiredError(
                'createMetric',
                'Required parameter "createMetric" was null or undefined when calling createMetric().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMetricToJSON(requestParameters['createMetric']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricFromJSON(jsonValue));
    }

    /**
     * Create Metric
     */
    async createMetric(requestParameters: CreateMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Metric> {
        const response = await this.createMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Category
     */
    async deleteCategoryRaw(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['categoryUuid'] == null) {
            throw new runtime.RequiredError(
                'categoryUuid',
                'Required parameter "categoryUuid" was null or undefined when calling deleteCategory().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/category/{category_uuid}/delete`.replace(`{${"category_uuid"}}`, encodeURIComponent(String(requestParameters['categoryUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Category
     */
    async deleteCategory(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Metric
     */
    async deleteMetricRaw(requestParameters: DeleteMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling deleteMetric().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteMetric().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/delete`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Metric
     */
    async deleteMetric(requestParameters: DeleteMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Metric
     */
    async duplicateMetricRaw(requestParameters: DuplicateMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Metric>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling duplicateMetric().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling duplicateMetric().'
            );
        }

        if (requestParameters['duplicateMetricParams'] == null) {
            throw new runtime.RequiredError(
                'duplicateMetricParams',
                'Required parameter "duplicateMetricParams" was null or undefined when calling duplicateMetric().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/duplicate`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateMetricParamsToJSON(requestParameters['duplicateMetricParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricFromJSON(jsonValue));
    }

    /**
     * Duplicate Metric
     */
    async duplicateMetric(requestParameters: DuplicateMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Metric> {
        const response = await this.duplicateMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export Metric Pivot Df To Csv
     */
    async exportMetricDataCSVRaw(requestParameters: ExportMetricDataCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling exportMetricDataCSV().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling exportMetricDataCSV().'
            );
        }

        if (requestParameters['pivotTableCsvParams'] == null) {
            throw new runtime.RequiredError(
                'pivotTableCsvParams',
                'Required parameter "pivotTableCsvParams" was null or undefined when calling exportMetricDataCSV().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/df/csv`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PivotTableCsvParamsToJSON(requestParameters['pivotTableCsvParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export Metric Pivot Df To Csv
     */
    async exportMetricDataCSV(requestParameters: ExportMetricDataCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.exportMetricDataCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Metric Sources Table
     */
    async fetchMetricSourcesRaw(requestParameters: FetchMetricSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SourcesData>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling fetchMetricSources().'
            );
        }

        if (requestParameters['scenarioUuid'] == null) {
            throw new runtime.RequiredError(
                'scenarioUuid',
                'Required parameter "scenarioUuid" was null or undefined when calling fetchMetricSources().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchMetricSources().'
            );
        }

        if (requestParameters['metricSourcesParams'] == null) {
            throw new runtime.RequiredError(
                'metricSourcesParams',
                'Required parameter "metricSourcesParams" was null or undefined when calling fetchMetricSources().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/sources/{scenario_uuid}`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"scenario_uuid"}}`, encodeURIComponent(String(requestParameters['scenarioUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetricSourcesParamsToJSON(requestParameters['metricSourcesParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SourcesDataFromJSON(jsonValue));
    }

    /**
     * Fetch Metric Sources Table
     */
    async fetchMetricSources(requestParameters: FetchMetricSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SourcesData> {
        const response = await this.fetchMetricSourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Metrics Units
     */
    async fetchMetricUnitsRaw(requestParameters: FetchMetricUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetricUnit>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchMetricUnits().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/info/units`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricUnitFromJSON));
    }

    /**
     * Fetch Metrics Units
     */
    async fetchMetricUnits(requestParameters: FetchMetricUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetricUnit>> {
        const response = await this.fetchMetricUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Summary
     */
    async fetchMetricsSummaryRaw(requestParameters: FetchMetricsSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Summary>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchMetricsSummary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/summary`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryFromJSON(jsonValue));
    }

    /**
     * Summary
     */
    async fetchMetricsSummary(requestParameters: FetchMetricsSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Summary> {
        const response = await this.fetchMetricsSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Metrics Index
     */
    async fetchMetricsValuesRaw(requestParameters: FetchMetricsValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchMetricsValues().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/index`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Metrics Index
     */
    async fetchMetricsValues(requestParameters: FetchMetricsValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fetchMetricsValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Reports By Metric
     */
    async fetchReportsByMetricRaw(requestParameters: FetchReportsByMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Report>>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling fetchReportsByMetric().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchReportsByMetric().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/reports`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportFromJSON));
    }

    /**
     * Fetch Reports By Metric
     */
    async fetchReportsByMetric(requestParameters: FetchReportsByMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Report>> {
        const response = await this.fetchReportsByMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Force Metrics Index Recompute
     */
    async forceMetricsRecomputeRaw(requestParameters: ForceMetricsRecomputeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling forceMetricsRecompute().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/recompute`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Force Metrics Index Recompute
     */
    async forceMetricsRecompute(requestParameters: ForceMetricsRecomputeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.forceMetricsRecomputeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Metric Owner
     */
    async removeMetricOwnerRaw(requestParameters: RemoveMetricOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Metric>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling removeMetricOwner().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeMetricOwner().'
            );
        }

        if (requestParameters['removeMetricOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'removeMetricOwnersParams',
                'Required parameter "removeMetricOwnersParams" was null or undefined when calling removeMetricOwner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/owners/remove`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveMetricOwnersParamsToJSON(requestParameters['removeMetricOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricFromJSON(jsonValue));
    }

    /**
     * Remove Metric Owner
     */
    async removeMetricOwner(requestParameters: RemoveMetricOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Metric> {
        const response = await this.removeMetricOwnerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Favorite
     */
    async setMetricFavoriteStatusRaw(requestParameters: SetMetricFavoriteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setMetricFavoriteStatus().'
            );
        }

        if (requestParameters['cofiApiRoutersMetricsFavoriteParams'] == null) {
            throw new runtime.RequiredError(
                'cofiApiRoutersMetricsFavoriteParams',
                'Required parameter "cofiApiRoutersMetricsFavoriteParams" was null or undefined when calling setMetricFavoriteStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/favorite`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CofiApiRoutersMetricsFavoriteParamsToJSON(requestParameters['cofiApiRoutersMetricsFavoriteParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Favorite
     */
    async setMetricFavoriteStatus(requestParameters: SetMetricFavoriteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.setMetricFavoriteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Category
     */
    async updateCategoryRaw(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['categoryUuid'] == null) {
            throw new runtime.RequiredError(
                'categoryUuid',
                'Required parameter "categoryUuid" was null or undefined when calling updateCategory().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateCategory().'
            );
        }

        if (requestParameters['updateCategoryParams'] == null) {
            throw new runtime.RequiredError(
                'updateCategoryParams',
                'Required parameter "updateCategoryParams" was null or undefined when calling updateCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/category/{category_uuid}`.replace(`{${"category_uuid"}}`, encodeURIComponent(String(requestParameters['categoryUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCategoryParamsToJSON(requestParameters['updateCategoryParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Category
     */
    async updateCategory(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Metric
     */
    async updateMetricRaw(requestParameters: UpdateMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling updateMetric().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateMetric().'
            );
        }

        if (requestParameters['updateMetric'] == null) {
            throw new runtime.RequiredError(
                'updateMetric',
                'Required parameter "updateMetric" was null or undefined when calling updateMetric().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/update`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMetricToJSON(requestParameters['updateMetric']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Metric
     */
    async updateMetric(requestParameters: UpdateMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Metric Aggregate Function
     */
    async updateMetricAggregateFunctionRaw(requestParameters: UpdateMetricAggregateFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling updateMetricAggregateFunction().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateMetricAggregateFunction().'
            );
        }

        if (requestParameters['updateMetricAggregateFunctionParams'] == null) {
            throw new runtime.RequiredError(
                'updateMetricAggregateFunctionParams',
                'Required parameter "updateMetricAggregateFunctionParams" was null or undefined when calling updateMetricAggregateFunction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/aggregate`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMetricAggregateFunctionParamsToJSON(requestParameters['updateMetricAggregateFunctionParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Metric Aggregate Function
     */
    async updateMetricAggregateFunction(requestParameters: UpdateMetricAggregateFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateMetricAggregateFunctionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Metric Code
     */
    async updateMetricCodeRaw(requestParameters: UpdateMetricCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling updateMetricCode().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateMetricCode().'
            );
        }

        if (requestParameters['updateProperty'] == null) {
            throw new runtime.RequiredError(
                'updateProperty',
                'Required parameter "updateProperty" was null or undefined when calling updateMetricCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/code`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePropertyToJSON(requestParameters['updateProperty']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Metric Code
     */
    async updateMetricCode(requestParameters: UpdateMetricCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateMetricCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Metric Scenario Formula
     */
    async updateMetricScenarioFormulaRaw(requestParameters: UpdateMetricScenarioFormulaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Metric>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling updateMetricScenarioFormula().'
            );
        }

        if (requestParameters['scenarioUuid'] == null) {
            throw new runtime.RequiredError(
                'scenarioUuid',
                'Required parameter "scenarioUuid" was null or undefined when calling updateMetricScenarioFormula().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateMetricScenarioFormula().'
            );
        }

        if (requestParameters['updateProperty'] == null) {
            throw new runtime.RequiredError(
                'updateProperty',
                'Required parameter "updateProperty" was null or undefined when calling updateMetricScenarioFormula().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/formula/scenarios/{scenario_uuid}`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"scenario_uuid"}}`, encodeURIComponent(String(requestParameters['scenarioUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePropertyToJSON(requestParameters['updateProperty']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricFromJSON(jsonValue));
    }

    /**
     * Update Metric Scenario Formula
     */
    async updateMetricScenarioFormula(requestParameters: UpdateMetricScenarioFormulaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Metric> {
        const response = await this.updateMetricScenarioFormulaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate Metric Formula
     */
    async validateMetricFormulaRaw(requestParameters: ValidateMetricFormulaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling validateMetricFormula().'
            );
        }

        if (requestParameters['scenarioUuid'] == null) {
            throw new runtime.RequiredError(
                'scenarioUuid',
                'Required parameter "scenarioUuid" was null or undefined when calling validateMetricFormula().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling validateMetricFormula().'
            );
        }

        if (requestParameters['validateFormulaParams'] == null) {
            throw new runtime.RequiredError(
                'validateFormulaParams',
                'Required parameter "validateFormulaParams" was null or undefined when calling validateMetricFormula().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/metrics/{metric_uuid}/formula/{scenario_uuid}/validate`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))).replace(`{${"scenario_uuid"}}`, encodeURIComponent(String(requestParameters['scenarioUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateFormulaParamsToJSON(requestParameters['validateFormulaParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Validate Metric Formula
     */
    async validateMetricFormula(requestParameters: ValidateMetricFormulaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.validateMetricFormulaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
