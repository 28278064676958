/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Database owner model.
 * @export
 * @interface DatabaseOwner
 */
export interface DatabaseOwner {
    /**
     * 
     * @type {string}
     * @memberof DatabaseOwner
     */
    databaseUuid: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseOwner
     */
    userUuid: string;
}

/**
 * Check if a given object implements the DatabaseOwner interface.
 */
export function instanceOfDatabaseOwner(value: object): boolean {
    if (!('databaseUuid' in value)) return false;
    if (!('userUuid' in value)) return false;
    return true;
}

export function DatabaseOwnerFromJSON(json: any): DatabaseOwner {
    return DatabaseOwnerFromJSONTyped(json, false);
}

export function DatabaseOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseOwner {
    if (json == null) {
        return json;
    }
    return {
        
        'databaseUuid': json['database_uuid'],
        'userUuid': json['user_uuid'],
    };
}

export function DatabaseOwnerToJSON(value?: DatabaseOwner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'database_uuid': value['databaseUuid'],
        'user_uuid': value['userUuid'],
    };
}

