/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkspaceMembership } from './WorkspaceMembership';
import {
    WorkspaceMembershipFromJSON,
    WorkspaceMembershipFromJSONTyped,
    WorkspaceMembershipToJSON,
} from './WorkspaceMembership';

/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    defaultScenarioUuid?: string;
    /**
     * 
     * @type {Array<WorkspaceMembership>}
     * @memberof Workspace
     */
    userMemberships?: Array<WorkspaceMembership>;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    defaultMetricUnit: WorkspaceDefaultMetricUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    decimalsSeparator?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Workspace
     */
    defaultRedirectEntity?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Workspace
     */
    lastAccessedEntity?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof Workspace
     */
    dataStartDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Workspace
     */
    dataEndDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Workspace
     */
    closingDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    fiscalYearStartMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    dateFormat: WorkspaceDateFormatEnum;
}


/**
 * @export
 */
export const WorkspaceDefaultMetricUnitEnum = {
    CurrencyEuro: 'currency_euro',
    CurrencyDollar: 'currency_dollar',
    Count: 'count',
    Percentage: 'percentage'
} as const;
export type WorkspaceDefaultMetricUnitEnum = typeof WorkspaceDefaultMetricUnitEnum[keyof typeof WorkspaceDefaultMetricUnitEnum];

/**
 * @export
 */
export const WorkspaceDateFormatEnum = {
    DdMmYyyy: 'dd/MM/yyyy',
    MmDdYyyy: 'MM/dd/yyyy',
    YyyyMmDd: 'yyyy/MM/dd'
} as const;
export type WorkspaceDateFormatEnum = typeof WorkspaceDateFormatEnum[keyof typeof WorkspaceDateFormatEnum];


/**
 * Check if a given object implements the Workspace interface.
 */
export function instanceOfWorkspace(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('defaultMetricUnit' in value)) return false;
    if (!('dataStartDate' in value)) return false;
    if (!('dataEndDate' in value)) return false;
    if (!('dateFormat' in value)) return false;
    return true;
}

export function WorkspaceFromJSON(json: any): Workspace {
    return WorkspaceFromJSONTyped(json, false);
}

export function WorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workspace {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'code': json['code'],
        'defaultScenarioUuid': json['default_scenario_uuid'] == null ? undefined : json['default_scenario_uuid'],
        'userMemberships': json['user_memberships'] == null ? undefined : ((json['user_memberships'] as Array<any>).map(WorkspaceMembershipFromJSON)),
        'logo': json['logo'] == null ? undefined : json['logo'],
        'color': json['color'] == null ? undefined : json['color'],
        'defaultMetricUnit': json['default_metric_unit'],
        'decimalsSeparator': json['decimals_separator'] == null ? undefined : json['decimals_separator'],
        'defaultRedirectEntity': json['default_redirect_entity'] == null ? undefined : json['default_redirect_entity'],
        'lastAccessedEntity': json['last_accessed_entity'] == null ? undefined : json['last_accessed_entity'],
        'dataStartDate': (new Date(json['data_start_date'])),
        'dataEndDate': (new Date(json['data_end_date'])),
        'closingDate': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
        'fiscalYearStartMonth': json['fiscal_year_start_month'] == null ? undefined : json['fiscal_year_start_month'],
        'dateFormat': json['date_format'],
    };
}

export function WorkspaceToJSON(value?: Workspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'code': value['code'],
        'default_scenario_uuid': value['defaultScenarioUuid'],
        'user_memberships': value['userMemberships'] == null ? undefined : ((value['userMemberships'] as Array<any>).map(WorkspaceMembershipToJSON)),
        'logo': value['logo'],
        'color': value['color'],
        'default_metric_unit': value['defaultMetricUnit'],
        'decimals_separator': value['decimalsSeparator'],
        'default_redirect_entity': value['defaultRedirectEntity'],
        'last_accessed_entity': value['lastAccessedEntity'],
        'data_start_date': ((value['dataStartDate']).toISOString()),
        'data_end_date': ((value['dataEndDate']).toISOString()),
        'closing_date': value['closingDate'] == null ? undefined : ((value['closingDate'] as any).toISOString()),
        'fiscal_year_start_month': value['fiscalYearStartMonth'],
        'date_format': value['dateFormat'],
    };
}

