/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayModelVersion } from './DisplayModelVersion';
import {
    DisplayModelVersionFromJSON,
    DisplayModelVersionFromJSONTyped,
    DisplayModelVersionToJSON,
} from './DisplayModelVersion';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';
import type { Variable } from './Variable';
import {
    VariableFromJSON,
    VariableFromJSONTyped,
    VariableToJSON,
} from './Variable';

/**
 * 
 * @export
 * @interface DisplayModel
 */
export interface DisplayModel {
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    name: string;
    /**
     * 
     * @type {Period}
     * @memberof DisplayModel
     */
    period: Period;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    yearTotals?: DisplayModelYearTotalsEnum;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    granularity?: DisplayModelGranularityEnum;
    /**
     * 
     * @type {Date}
     * @memberof DisplayModel
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayModel
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    emoji?: string;
    /**
     * 
     * @type {Array<DisplayModelVersion>}
     * @memberof DisplayModel
     */
    versions: Array<DisplayModelVersion>;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof DisplayModel
     */
    variables?: Array<Variable>;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    originModelUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayModel
     */
    communityPublishedAssetUuid?: string;
}


/**
 * @export
 */
export const DisplayModelYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type DisplayModelYearTotalsEnum = typeof DisplayModelYearTotalsEnum[keyof typeof DisplayModelYearTotalsEnum];

/**
 * @export
 */
export const DisplayModelGranularityEnum = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year'
} as const;
export type DisplayModelGranularityEnum = typeof DisplayModelGranularityEnum[keyof typeof DisplayModelGranularityEnum];


/**
 * Check if a given object implements the DisplayModel interface.
 */
export function instanceOfDisplayModel(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('period' in value)) return false;
    if (!('versions' in value)) return false;
    return true;
}

export function DisplayModelFromJSON(json: any): DisplayModel {
    return DisplayModelFromJSONTyped(json, false);
}

export function DisplayModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayModel {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'period': PeriodFromJSON(json['period']),
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
        'granularity': json['granularity'] == null ? undefined : json['granularity'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'description': json['description'] == null ? undefined : json['description'],
        'emoji': json['emoji'] == null ? undefined : json['emoji'],
        'versions': ((json['versions'] as Array<any>).map(DisplayModelVersionFromJSON)),
        'variables': json['variables'] == null ? undefined : ((json['variables'] as Array<any>).map(VariableFromJSON)),
        'originModelUuid': json['origin_model_uuid'] == null ? undefined : json['origin_model_uuid'],
        'communityPublishedAssetUuid': json['community_published_asset_uuid'] == null ? undefined : json['community_published_asset_uuid'],
    };
}

export function DisplayModelToJSON(value?: DisplayModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'period': PeriodToJSON(value['period']),
        'year_totals': value['yearTotals'],
        'granularity': value['granularity'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'description': value['description'],
        'emoji': value['emoji'],
        'versions': ((value['versions'] as Array<any>).map(DisplayModelVersionToJSON)),
        'variables': value['variables'] == null ? undefined : ((value['variables'] as Array<any>).map(VariableToJSON)),
        'origin_model_uuid': value['originModelUuid'],
        'community_published_asset_uuid': value['communityPublishedAssetUuid'],
    };
}

