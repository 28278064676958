/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDataSourceParams
 */
export interface UpdateDataSourceParams {
    /**
     * 
     * @type {object}
     * @memberof UpdateDataSourceParams
     */
    updates: object;
}

/**
 * Check if a given object implements the UpdateDataSourceParams interface.
 */
export function instanceOfUpdateDataSourceParams(value: object): boolean {
    if (!('updates' in value)) return false;
    return true;
}

export function UpdateDataSourceParamsFromJSON(json: any): UpdateDataSourceParams {
    return UpdateDataSourceParamsFromJSONTyped(json, false);
}

export function UpdateDataSourceParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDataSourceParams {
    if (json == null) {
        return json;
    }
    return {
        
        'updates': json['updates'],
    };
}

export function UpdateDataSourceParamsToJSON(value?: UpdateDataSourceParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'updates': value['updates'],
    };
}

