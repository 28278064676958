/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteUserToWorkspaceParams
 */
export interface InviteUserToWorkspaceParams {
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceParams
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceParams
     */
    role: InviteUserToWorkspaceParamsRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceParams
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceParams
     */
    lastName?: string;
}


/**
 * @export
 */
export const InviteUserToWorkspaceParamsRoleEnum = {
    Admin: 'admin',
    Regular: 'regular',
    Guest: 'guest'
} as const;
export type InviteUserToWorkspaceParamsRoleEnum = typeof InviteUserToWorkspaceParamsRoleEnum[keyof typeof InviteUserToWorkspaceParamsRoleEnum];


/**
 * Check if a given object implements the InviteUserToWorkspaceParams interface.
 */
export function instanceOfInviteUserToWorkspaceParams(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('role' in value)) return false;
    return true;
}

export function InviteUserToWorkspaceParamsFromJSON(json: any): InviteUserToWorkspaceParams {
    return InviteUserToWorkspaceParamsFromJSONTyped(json, false);
}

export function InviteUserToWorkspaceParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteUserToWorkspaceParams {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'workspaceUuid': json['workspace_uuid'],
        'role': json['role'],
        'name': json['name'] == null ? undefined : json['name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
    };
}

export function InviteUserToWorkspaceParamsToJSON(value?: InviteUserToWorkspaceParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'workspace_uuid': value['workspaceUuid'],
        'role': value['role'],
        'name': value['name'],
        'last_name': value['lastName'],
    };
}

