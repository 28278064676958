/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploaderOwner
 */
export interface UploaderOwner {
    /**
     * 
     * @type {string}
     * @memberof UploaderOwner
     */
    uploaderUuid: string;
    /**
     * 
     * @type {string}
     * @memberof UploaderOwner
     */
    userUuid: string;
}

/**
 * Check if a given object implements the UploaderOwner interface.
 */
export function instanceOfUploaderOwner(value: object): boolean {
    if (!('uploaderUuid' in value)) return false;
    if (!('userUuid' in value)) return false;
    return true;
}

export function UploaderOwnerFromJSON(json: any): UploaderOwner {
    return UploaderOwnerFromJSONTyped(json, false);
}

export function UploaderOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploaderOwner {
    if (json == null) {
        return json;
    }
    return {
        
        'uploaderUuid': json['uploader_uuid'],
        'userUuid': json['user_uuid'],
    };
}

export function UploaderOwnerToJSON(value?: UploaderOwner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uploader_uuid': value['uploaderUuid'],
        'user_uuid': value['userUuid'],
    };
}

