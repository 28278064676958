/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  ReportBug,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ReportBugFromJSON,
    ReportBugToJSON,
} from '../models/index';

export interface ReportBugWorkspaceCodeBugsPostRequest {
    workspaceCode: string;
    reportBug: ReportBug;
}

/**
 * 
 */
export class SupportApi extends runtime.BaseAPI {

    /**
     * Report Bug
     */
    async reportBugWorkspaceCodeBugsPostRaw(requestParameters: ReportBugWorkspaceCodeBugsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling reportBugWorkspaceCodeBugsPost().'
            );
        }

        if (requestParameters['reportBug'] == null) {
            throw new runtime.RequiredError(
                'reportBug',
                'Required parameter "reportBug" was null or undefined when calling reportBugWorkspaceCodeBugsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/bugs`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportBugToJSON(requestParameters['reportBug']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Report Bug
     */
    async reportBugWorkspaceCodeBugsPost(requestParameters: ReportBugWorkspaceCodeBugsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.reportBugWorkspaceCodeBugsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
