/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModelVersionContributor } from './ModelVersionContributor';
import {
    ModelVersionContributorFromJSON,
    ModelVersionContributorFromJSONTyped,
    ModelVersionContributorToJSON,
} from './ModelVersionContributor';

/**
 * 
 * @export
 * @interface ModelVersion
 */
export interface ModelVersion {
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    modelUuid: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelVersion
     */
    isDefaultVersion: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    versionAuthorUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    modelAuthorUuid?: string;
    /**
     * 
     * @type {Array<ModelVersionContributor>}
     * @memberof ModelVersion
     */
    contributors?: Array<ModelVersionContributor>;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    publicToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    excelDownloadUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    gaCampaignName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersion
     */
    parentUuid?: string;
    /**
     * 
     * @type {ModelVersion}
     * @memberof ModelVersion
     */
    childVersion?: ModelVersion;
    /**
     * 
     * @type {boolean}
     * @memberof ModelVersion
     */
    isPublic: boolean;
}

/**
 * Check if a given object implements the ModelVersion interface.
 */
export function instanceOfModelVersion(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('modelUuid' in value)) return false;
    if (!('isDefaultVersion' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('isPublic' in value)) return false;
    return true;
}

export function ModelVersionFromJSON(json: any): ModelVersion {
    return ModelVersionFromJSONTyped(json, false);
}

export function ModelVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelVersion {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'modelUuid': json['model_uuid'],
        'isDefaultVersion': json['is_default_version'],
        'workspaceUuid': json['workspace_uuid'],
        'versionAuthorUuid': json['version_author_uuid'] == null ? undefined : json['version_author_uuid'],
        'modelAuthorUuid': json['model_author_uuid'] == null ? undefined : json['model_author_uuid'],
        'contributors': json['contributors'] == null ? undefined : ((json['contributors'] as Array<any>).map(ModelVersionContributorFromJSON)),
        'publicToken': json['public_token'] == null ? undefined : json['public_token'],
        'excelDownloadUrl': json['excel_download_url'] == null ? undefined : json['excel_download_url'],
        'gaCampaignName': json['ga_campaign_name'] == null ? undefined : json['ga_campaign_name'],
        'parentUuid': json['parent_uuid'] == null ? undefined : json['parent_uuid'],
        'childVersion': json['child_version'] == null ? undefined : ModelVersionFromJSON(json['child_version']),
        'isPublic': json['is_public'],
    };
}

export function ModelVersionToJSON(value?: ModelVersion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'model_uuid': value['modelUuid'],
        'is_default_version': value['isDefaultVersion'],
        'workspace_uuid': value['workspaceUuid'],
        'version_author_uuid': value['versionAuthorUuid'],
        'model_author_uuid': value['modelAuthorUuid'],
        'contributors': value['contributors'] == null ? undefined : ((value['contributors'] as Array<any>).map(ModelVersionContributorToJSON)),
        'public_token': value['publicToken'],
        'excel_download_url': value['excelDownloadUrl'],
        'ga_campaign_name': value['gaCampaignName'],
        'parent_uuid': value['parentUuid'],
        'child_version': ModelVersionToJSON(value['childVersion']),
        'is_public': value['isPublic'],
    };
}

