/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestTokenParameters
 */
export interface RequestTokenParameters {
    /**
     * 
     * @type {string}
     * @memberof RequestTokenParameters
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RequestTokenParameters
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestTokenParameters
     */
    expiresOn?: number;
}

/**
 * Check if a given object implements the RequestTokenParameters interface.
 */
export function instanceOfRequestTokenParameters(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function RequestTokenParametersFromJSON(json: any): RequestTokenParameters {
    return RequestTokenParametersFromJSONTyped(json, false);
}

export function RequestTokenParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestTokenParameters {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'expiresOn': json['expires_on'] == null ? undefined : json['expires_on'],
    };
}

export function RequestTokenParametersToJSON(value?: RequestTokenParameters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'expires_on': value['expiresOn'],
    };
}

