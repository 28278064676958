/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteCredentialParams
 */
export interface DeleteCredentialParams {
    /**
     * 
     * @type {string}
     * @memberof DeleteCredentialParams
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCredentialParams
     */
    type: DeleteCredentialParamsTypeEnum;
}


/**
 * @export
 */
export const DeleteCredentialParamsTypeEnum = {
    CofiEmail: 'cofi_email',
    AuthGoogle: 'auth_google',
    Auth0Email: 'auth0_email',
    Token: 'token'
} as const;
export type DeleteCredentialParamsTypeEnum = typeof DeleteCredentialParamsTypeEnum[keyof typeof DeleteCredentialParamsTypeEnum];


/**
 * Check if a given object implements the DeleteCredentialParams interface.
 */
export function instanceOfDeleteCredentialParams(value: object): boolean {
    if (!('key' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function DeleteCredentialParamsFromJSON(json: any): DeleteCredentialParams {
    return DeleteCredentialParamsFromJSONTyped(json, false);
}

export function DeleteCredentialParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCredentialParams {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'type': json['type'],
    };
}

export function DeleteCredentialParamsToJSON(value?: DeleteCredentialParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'type': value['type'],
    };
}

