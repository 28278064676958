/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    inviterUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    inviteeUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof Invite
     */
    lastSentAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    role: InviteRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    inviteeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    inviteeLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    workspaceUuid: string;
}


/**
 * @export
 */
export const InviteRoleEnum = {
    Admin: 'admin',
    Regular: 'regular',
    Guest: 'guest'
} as const;
export type InviteRoleEnum = typeof InviteRoleEnum[keyof typeof InviteRoleEnum];


/**
 * Check if a given object implements the Invite interface.
 */
export function instanceOfInvite(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('inviterUuid' in value)) return false;
    if (!('email' in value)) return false;
    if (!('lastSentAt' in value)) return false;
    if (!('role' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    return true;
}

export function InviteFromJSON(json: any): Invite {
    return InviteFromJSONTyped(json, false);
}

export function InviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invite {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'inviterUuid': json['inviter_uuid'],
        'inviteeUuid': json['invitee_uuid'] == null ? undefined : json['invitee_uuid'],
        'email': json['email'],
        'lastSentAt': (new Date(json['last_sent_at'])),
        'role': json['role'],
        'inviteeName': json['invitee_name'] == null ? undefined : json['invitee_name'],
        'inviteeLastName': json['invitee_last_name'] == null ? undefined : json['invitee_last_name'],
        'workspaceUuid': json['workspace_uuid'],
    };
}

export function InviteToJSON(value?: Invite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'inviter_uuid': value['inviterUuid'],
        'invitee_uuid': value['inviteeUuid'],
        'email': value['email'],
        'last_sent_at': ((value['lastSentAt']).toISOString()),
        'role': value['role'],
        'invitee_name': value['inviteeName'],
        'invitee_last_name': value['inviteeLastName'],
        'workspace_uuid': value['workspaceUuid'],
    };
}

