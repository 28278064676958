/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface VariableDrillDownParams
 */
export interface VariableDrillDownParams {
    /**
     * 
     * @type {Period}
     * @memberof VariableDrillDownParams
     */
    period: Period;
    /**
     * 
     * @type {string}
     * @memberof VariableDrillDownParams
     */
    scenarioUuid: string;
    /**
     * 
     * @type {string}
     * @memberof VariableDrillDownParams
     */
    versionUuid: string;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof VariableDrillDownParams
     */
    filters?: Array<Filter>;
}

/**
 * Check if a given object implements the VariableDrillDownParams interface.
 */
export function instanceOfVariableDrillDownParams(value: object): boolean {
    if (!('period' in value)) return false;
    if (!('scenarioUuid' in value)) return false;
    if (!('versionUuid' in value)) return false;
    return true;
}

export function VariableDrillDownParamsFromJSON(json: any): VariableDrillDownParams {
    return VariableDrillDownParamsFromJSONTyped(json, false);
}

export function VariableDrillDownParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableDrillDownParams {
    if (json == null) {
        return json;
    }
    return {
        
        'period': PeriodFromJSON(json['period']),
        'scenarioUuid': json['scenario_uuid'],
        'versionUuid': json['version_uuid'],
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
    };
}

export function VariableDrillDownParamsToJSON(value?: VariableDrillDownParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': PeriodToJSON(value['period']),
        'scenario_uuid': value['scenarioUuid'],
        'version_uuid': value['versionUuid'],
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
    };
}

