/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  NotifyModelVersionExcelDownloadParams,
  PublicModelResponse,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    NotifyModelVersionExcelDownloadParamsFromJSON,
    NotifyModelVersionExcelDownloadParamsToJSON,
    PublicModelResponseFromJSON,
    PublicModelResponseToJSON,
} from '../models/index';

export interface FetchPublishedModelTableDataRequest {
    token: string;
}

export interface NotifyModelVersionExcelDownloadRequest {
    modelUuid: string;
    versionUuid: string;
    notifyModelVersionExcelDownloadParams: NotifyModelVersionExcelDownloadParams;
}

/**
 * 
 */
export class PublicApi extends runtime.BaseAPI {

    /**
     * Fetch Published Model Table Data
     */
    async fetchPublishedModelTableDataRaw(requestParameters: FetchPublishedModelTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicModelResponse>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling fetchPublishedModelTableData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/public/model_table/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters['token']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicModelResponseFromJSON(jsonValue));
    }

    /**
     * Fetch Published Model Table Data
     */
    async fetchPublishedModelTableData(requestParameters: FetchPublishedModelTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicModelResponse> {
        const response = await this.fetchPublishedModelTableDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Notify Model Version Excel Has Been Downloaded
     */
    async notifyModelVersionExcelDownloadRaw(requestParameters: NotifyModelVersionExcelDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling notifyModelVersionExcelDownload().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling notifyModelVersionExcelDownload().'
            );
        }

        if (requestParameters['notifyModelVersionExcelDownloadParams'] == null) {
            throw new runtime.RequiredError(
                'notifyModelVersionExcelDownloadParams',
                'Required parameter "notifyModelVersionExcelDownloadParams" was null or undefined when calling notifyModelVersionExcelDownload().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/public/{model_uuid}/versions/{version_uuid}/download_excel`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotifyModelVersionExcelDownloadParamsToJSON(requestParameters['notifyModelVersionExcelDownloadParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Notify Model Version Excel Has Been Downloaded
     */
    async notifyModelVersionExcelDownload(requestParameters: NotifyModelVersionExcelDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.notifyModelVersionExcelDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
