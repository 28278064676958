/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface DownloadCSVParams
 */
export interface DownloadCSVParams {
    /**
     * 
     * @type {Array<Filter>}
     * @memberof DownloadCSVParams
     */
    filters?: Array<Filter>;
    /**
     * 
     * @type {Period}
     * @memberof DownloadCSVParams
     */
    period?: Period;
}

/**
 * Check if a given object implements the DownloadCSVParams interface.
 */
export function instanceOfDownloadCSVParams(value: object): boolean {
    return true;
}

export function DownloadCSVParamsFromJSON(json: any): DownloadCSVParams {
    return DownloadCSVParamsFromJSONTyped(json, false);
}

export function DownloadCSVParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownloadCSVParams {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
        'period': json['period'] == null ? undefined : PeriodFromJSON(json['period']),
    };
}

export function DownloadCSVParamsToJSON(value?: DownloadCSVParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
        'period': PeriodToJSON(value['period']),
    };
}

