/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentAuthor } from './CommentAuthor';
import {
    CommentAuthorFromJSON,
    CommentAuthorFromJSONTyped,
    CommentAuthorToJSON,
} from './CommentAuthor';

/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    uuid: string;
    /**
     * 
     * @type {CommentAuthor}
     * @memberof Comment
     */
    author: CommentAuthor;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    body: string;
}

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('author' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('body' in value)) return false;
    return true;
}

export function CommentFromJSON(json: any): Comment {
    return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comment {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'author': CommentAuthorFromJSON(json['author']),
        'createdAt': (new Date(json['created_at'])),
        'body': json['body'],
    };
}

export function CommentToJSON(value?: Comment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'author': CommentAuthorToJSON(value['author']),
        'created_at': ((value['createdAt']).toISOString()),
        'body': value['body'],
    };
}

