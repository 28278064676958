/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteReportParams
 */
export interface FavoriteReportParams {
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteReportParams
     */
    state: boolean;
    /**
     * 
     * @type {string}
     * @memberof FavoriteReportParams
     */
    reportUuid: string;
}

/**
 * Check if a given object implements the FavoriteReportParams interface.
 */
export function instanceOfFavoriteReportParams(value: object): boolean {
    if (!('state' in value)) return false;
    if (!('reportUuid' in value)) return false;
    return true;
}

export function FavoriteReportParamsFromJSON(json: any): FavoriteReportParams {
    return FavoriteReportParamsFromJSONTyped(json, false);
}

export function FavoriteReportParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteReportParams {
    if (json == null) {
        return json;
    }
    return {
        
        'state': json['state'],
        'reportUuid': json['report_uuid'],
    };
}

export function FavoriteReportParamsToJSON(value?: FavoriteReportParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'state': value['state'],
        'report_uuid': value['reportUuid'],
    };
}

