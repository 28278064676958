/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCommentToDiscussion,
  CreateDiscussion,
  Discussion,
  HTTPValidationError,
} from '../models/index';
import {
    AddCommentToDiscussionFromJSON,
    AddCommentToDiscussionToJSON,
    CreateDiscussionFromJSON,
    CreateDiscussionToJSON,
    DiscussionFromJSON,
    DiscussionToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models/index';

export interface AddCommentToDiscussionRequest {
    targetId: string;
    discussionId: string;
    workspaceCode: string;
    addCommentToDiscussion: AddCommentToDiscussion;
}

export interface CreateDiscussionRequest {
    targetId: string;
    workspaceCode: string;
    createDiscussion: CreateDiscussion;
}

export interface FetchTargetDiscussionsRequest {
    targetId: string;
    workspaceCode: string;
}

export interface RemoveCommentFromDiscussionRequest {
    targetId: string;
    discussionId: string;
    commentUuid: string;
    workspaceCode: string;
}

export interface ResolveDiscussionRequest {
    targetId: string;
    discussionId: string;
    workspaceCode: string;
}

export interface UnresolveDiscussionRequest {
    targetId: string;
    discussionId: string;
    workspaceCode: string;
}

/**
 * 
 */
export class DiscussionsApi extends runtime.BaseAPI {

    /**
     * Add Message To Discussion
     */
    async addCommentToDiscussionRaw(requestParameters: AddCommentToDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling addCommentToDiscussion().'
            );
        }

        if (requestParameters['discussionId'] == null) {
            throw new runtime.RequiredError(
                'discussionId',
                'Required parameter "discussionId" was null or undefined when calling addCommentToDiscussion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addCommentToDiscussion().'
            );
        }

        if (requestParameters['addCommentToDiscussion'] == null) {
            throw new runtime.RequiredError(
                'addCommentToDiscussion',
                'Required parameter "addCommentToDiscussion" was null or undefined when calling addCommentToDiscussion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/{target_id}/discussions/{discussion_id}/comments`.replace(`{${"target_id"}}`, encodeURIComponent(String(requestParameters['targetId']))).replace(`{${"discussion_id"}}`, encodeURIComponent(String(requestParameters['discussionId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCommentToDiscussionToJSON(requestParameters['addCommentToDiscussion']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Add Message To Discussion
     */
    async addCommentToDiscussion(requestParameters: AddCommentToDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.addCommentToDiscussionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Discussion
     */
    async createDiscussionRaw(requestParameters: CreateDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling createDiscussion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createDiscussion().'
            );
        }

        if (requestParameters['createDiscussion'] == null) {
            throw new runtime.RequiredError(
                'createDiscussion',
                'Required parameter "createDiscussion" was null or undefined when calling createDiscussion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/{target_id}/discussions/`.replace(`{${"target_id"}}`, encodeURIComponent(String(requestParameters['targetId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDiscussionToJSON(requestParameters['createDiscussion']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Create Discussion
     */
    async createDiscussion(requestParameters: CreateDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.createDiscussionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Target Discussions
     */
    async fetchTargetDiscussionsRaw(requestParameters: FetchTargetDiscussionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling fetchTargetDiscussions().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchTargetDiscussions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/{target_id}/discussions/`.replace(`{${"target_id"}}`, encodeURIComponent(String(requestParameters['targetId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Target Discussions
     */
    async fetchTargetDiscussions(requestParameters: FetchTargetDiscussionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.fetchTargetDiscussionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Comment From Discussion
     */
    async removeCommentFromDiscussionRaw(requestParameters: RemoveCommentFromDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling removeCommentFromDiscussion().'
            );
        }

        if (requestParameters['discussionId'] == null) {
            throw new runtime.RequiredError(
                'discussionId',
                'Required parameter "discussionId" was null or undefined when calling removeCommentFromDiscussion().'
            );
        }

        if (requestParameters['commentUuid'] == null) {
            throw new runtime.RequiredError(
                'commentUuid',
                'Required parameter "commentUuid" was null or undefined when calling removeCommentFromDiscussion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeCommentFromDiscussion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/{target_id}/discussions/{discussion_id}/comments/{comment_uuid}`.replace(`{${"target_id"}}`, encodeURIComponent(String(requestParameters['targetId']))).replace(`{${"discussion_id"}}`, encodeURIComponent(String(requestParameters['discussionId']))).replace(`{${"comment_uuid"}}`, encodeURIComponent(String(requestParameters['commentUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Remove Comment From Discussion
     */
    async removeCommentFromDiscussion(requestParameters: RemoveCommentFromDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.removeCommentFromDiscussionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resolve Discussion
     */
    async resolveDiscussionRaw(requestParameters: ResolveDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling resolveDiscussion().'
            );
        }

        if (requestParameters['discussionId'] == null) {
            throw new runtime.RequiredError(
                'discussionId',
                'Required parameter "discussionId" was null or undefined when calling resolveDiscussion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling resolveDiscussion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/{target_id}/discussions/{discussion_id}/resolve`.replace(`{${"target_id"}}`, encodeURIComponent(String(requestParameters['targetId']))).replace(`{${"discussion_id"}}`, encodeURIComponent(String(requestParameters['discussionId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Resolve Discussion
     */
    async resolveDiscussion(requestParameters: ResolveDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.resolveDiscussionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unresolve Discussion
     */
    async unresolveDiscussionRaw(requestParameters: UnresolveDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discussion>>> {
        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling unresolveDiscussion().'
            );
        }

        if (requestParameters['discussionId'] == null) {
            throw new runtime.RequiredError(
                'discussionId',
                'Required parameter "discussionId" was null or undefined when calling unresolveDiscussion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling unresolveDiscussion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/{target_id}/discussions/{discussion_id}/unresolve`.replace(`{${"target_id"}}`, encodeURIComponent(String(requestParameters['targetId']))).replace(`{${"discussion_id"}}`, encodeURIComponent(String(requestParameters['discussionId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscussionFromJSON));
    }

    /**
     * Unresolve Discussion
     */
    async unresolveDiscussion(requestParameters: UnresolveDiscussionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discussion>> {
        const response = await this.unresolveDiscussionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
