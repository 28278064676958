import { DateTime, DateTimeUnit } from "luxon";
import { Cell, Header, Row } from "~/components/PivotTable/types";
import {
  MetricUnitEnum,
  Period,
  PeriodFromJSONTyped,
  Scenario,
  SourcesData,
  SourcesTableRow
} from "~/gen";
import { EnrichedMetric } from "./useApi";
import { defaultPeriod, periodStorage } from "./usePreferences";

export interface MetricValues {
  userRequested: Record<string, unknown>[];
  yearlyRecords: Record<string, unknown>[];
}

export function storedPeriod(): Period {
  return periodStorage.value ? PeriodFromJSONTyped(JSON.parse(periodStorage.value), false) : defaultPeriod();
}

export function createColumns(period?: Period) {
  const stored = storedPeriod();
  const { start = stored.start, end = stored.end, grain = stored.grain } = period || {};
  const lEnd = DateTime.fromJSDate(end as Date).toUTC();
  const returnArr = {} as Record<string, number>;
  let cursor = DateTime.fromJSDate(start as Date).toUTC().startOf(grain as DateTimeUnit);
  while (cursor <= lEnd) {
    returnArr[cursor.toFormat('yyyyMMdd')] = 0;
    cursor = cursor.plus({ [grain as string]: 1 });
  }
  return returnArr;
}

export function extendMetricValues(metric: EnrichedMetric) {
  const period = defaultPeriod();
  period.end = new Date();
  period.start = new Date();
  period.start.setFullYear(period.end.getFullYear() - 1);
  const cols: Record<string, number> = createColumns(period);
  Object.keys(metric.values).forEach(k => {
    cols[k] = metric.values[k];
  });
  return cols;
}

export function dateFormattedByPeriodSize(dt: DateTime, period: Period): string {
  switch (period.grain) {
    case "day":
      return dt.toFormat("yyyy-MM-dd");
    case "week":
      return `${dt.weekYear}-W${dt.weekNumber.toString().padStart(2, "0")}`;
    case "quarter": {
      const quarter = dt.quarter;
      return `${dt.year}-Q${quarter.toString()}`;
    }
    case "year":
      return dt.toFormat("yyyy");
    default:
      return dt.toFormat("yyyy-MM");
  }
}

export function fromFormattedStringToDateTime(date: string, period: Period): DateTime | undefined {
  switch (period.grain) {
    case "day":
      return DateTime.fromFormat(date, "yyyy-MM-dd");
    case "week": {
      const [year, weekstr] = date.split('-');
      const week: string = weekstr.split('W')[1];
      return DateTime.fromFormat(year + week, 'kkkkWW');
    }
    case "month": {
      return DateTime.fromFormat(date, "yyyy-MM");
    }
    case "year": {
      return DateTime.fromFormat(date, "yyyy");
    }
    case "quarter": {
      const [year, quarterstr] = date.split('-');
      const quarter = parseInt(quarterstr.split('Q')[1]);
      const month = ((quarter - 1) * 3) + 1;
      const result = new Date(parseInt(year), month, 1);
      return DateTime.fromJSDate(result);
    }
  }
}

export function adjustPeriod(period: Period, fiscalYearStart: number): Period {
  if (period.grain == "month" || fiscalYearStart === 1) return period;
  // XXX: This is a nasty hack to solve an issue first. We need to revisit how we are dealing
  // with dates coming from backend. Luxon has a very unintuitive way of dealing with time zones.
  const adjustedToUtc = (dt: Date): Date => {
    dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
    const adjusted = DateTime.fromJSDate(dt).plus({ months: 13 - fiscalYearStart }).toJSDate();
    const datestring = DateTime.fromJSDate(adjusted).toISODate() + "T00:00:00Z";
    return DateTime.fromISO(datestring, { zone: 'utc' }).toJSDate();
  };
  period = {
    start: adjustedToUtc(period.start as Date),
    end: adjustedToUtc(period.end as Date),
    grain: period.grain
  };
  return period;
}

export function tableHeadersFromPeriod(period: Period = defaultPeriod(), withTotals = true, fiscalYearStart = 1): Header[] {
  period = adjustPeriod(period, fiscalYearStart);
  function getFiscalYear(date: DateTime, fiscalYearStart: number): number {
    const useFiscalYear = fiscalYearStart !== 1;
    if (useFiscalYear) {
      return date.month >= fiscalYearStart ? date.year + 1 : date.year;
    }
    return date.year;
  }
  const columns = createColumns(period);
  const dates = Object.keys(columns);
  const size = dates.length;

  if (!withTotals) return dates.map(d => dateFormattedByPeriodSize(DateTime.fromISO(d), period));
  const { trail = [] } = dates.map(x => DateTime.fromISO(x))
    .reduce(({ before = undefined, trail }, curr, pos) => {
      const valuesToAdd = [dateFormattedByPeriodSize(curr, period)];
      if (period?.grain === 'month') {
        if (curr?.month === fiscalYearStart && pos > 0) {
          valuesToAdd.splice(0, 0, `TOTAL ${getFiscalYear(curr.minus({ months: 1 }), fiscalYearStart)}`);
        }
        if (pos === size - 1) {
          const label = curr.plus({ months: 1 }).month === fiscalYearStart ?
            `TOTAL ${getFiscalYear(curr, fiscalYearStart)}` : `YTD ${getFiscalYear(curr, fiscalYearStart)}`;
          valuesToAdd.push(label);
        }
      } else if (period?.grain === 'quarter') {
        if (curr?.quarter === 1 && pos > 0) {
          valuesToAdd.splice(0, 0, `TOTAL ${curr.year - 1}`);
        }
        if (pos === size - 1) {
          const label = curr.quarter === 4 ?
            `TOTAL ${curr.year}` : `YTD ${curr.year}`;
          valuesToAdd.push(label);
        }
      }
      return { before: curr, trail: [...trail, ...valuesToAdd] };
    }, { trail: [] }) as unknown as { trail: Cell[] };
  return trail;
}

interface RowsFromMetricValuesParams {
  values: MetricValues;
  scenarios?: Record<string, Scenario>,
  period?: Period;
  showDerived?: boolean;
  withTotals?: boolean;
  fiscalYearStart?: number
  metricUnitsMap: Record<string, string>
  rowUuidList: string[]
}
export function tableRowsFromMetricValues({
  values,
  scenarios = {},
  period = defaultPeriod(),
  showDerived = false,
  withTotals = true,
  fiscalYearStart = 1,
  metricUnitsMap,
  rowUuidList,
}: RowsFromMetricValuesParams): Row[] {

  period = adjustPeriod(period, fiscalYearStart);
  const columns = createColumns(period);
  const useFiscalYear = fiscalYearStart !== 1;
  const format = 'yyyyMMdd';
  const dates = Object.keys(columns);
  const size = dates.length;
  function extractRowValues(values: Record<string, number>, yearly?: Record<string, number>) {
    function getFiscalYear(useFiscalYear: boolean, date: DateTime, fiscalYearStart: number): number {
      if (useFiscalYear) {
        return date.month >= fiscalYearStart ? date.year + 1 : date.year;
      }
      return date.year;
    }
    const { trail = [] } = dates.map(x => DateTime.fromISO(x))
      .reduce(({ before = undefined, trail }, curr, pos) => {
        const value = values[curr.toFormat(format)] || 0;
        const valuesToAdd = [value];
        if (withTotals && period?.grain === 'month') {
          if (curr?.month === fiscalYearStart && pos > 0) {
            valuesToAdd.splice(0, 0, yearly[`${getFiscalYear(useFiscalYear, curr, fiscalYearStart) - 1}0101`] ?? 0);
          }
          if (pos === size - 1) {
            valuesToAdd.push(yearly[`${getFiscalYear(useFiscalYear, curr, fiscalYearStart)}0101`] ?? 0);
          }
        } else if (withTotals && period?.grain === 'quarter') {
          if (curr?.quarter === 1 && pos > 0) {
            valuesToAdd.splice(0, 0, yearly[`${curr.year - 1}0101` ?? 0]);
          }
          if (pos === size - 1) {
            valuesToAdd.push(yearly[`${curr.year}0101`] ?? 0);
          }
          return { before: curr, trail: [...trail, ...valuesToAdd] };
        }
        return { before: curr, trail: [...trail, ...valuesToAdd] };
      }, { trail: [] }) as unknown as { trail: Cell[]; before?: DateTime };
    return trail;
  }

  type RowChild = {
    uuid: string;
    label: string;
    values: Record<string, number>;
    unit: MetricUnitEnum;
    children: RowChild[];
  };
  function asRowData(
    { uuid, label, values, children, unit }: RowChild,
    yearlyRecords: any,
  ): Row {
    return {
      uuid,
      label,
      columns: extractRowValues(values, withTotals ? (yearlyRecords?.values || {}) : {}),
      unit,
      children: children?.map((c, i) => {
        const yearlyData = yearlyRecords?.children?.find(y => y.label === c.label);
        return asRowData(c, withTotals ? yearlyData : {});
      }) || []
    };
  }

  function isDerived(row: Row) {
    return scenarios[row.uuid as string]?.periodNumber && scenarios[row.uuid as string]?.sourceScenario;
  }


  return rowUuidList?.reduce((acc: Row[], uuid: string, i) => {
    if (uuid.match(tableSeparatorRowRegex)) {
      acc.push({
        uuid,
        label: uuid.split(tableSeparatorRowRegex)[1],
        columns: Array(dates.length).fill(0),
        children: []
      });
      return acc;
    }
    const rowData = values.userRequested?.find(r => r.uuid === uuid);
    
    if (!rowData || (!showDerived && isDerived(rowData))) return acc;
    
    const yearlyData = values.yearlyRecords?.find(r => r.uuid === uuid);
    const row = asRowData(rowData as RowChild, withTotals ? yearlyData || [] : undefined);
    acc.push(row);

    return acc;
  }, [] as Row[]) || [];

}


interface ComparativeChild {
  dimension: string
  label: string
  values: { [date: string]: number },
  children: ComparativeChild[]
}
interface ComparativeRowData {
  scenario_name: string,
  scenario_uuid: string,
  dimension: string,
  label: string,
  unit: string,
  uuid: string,
  values: { [date: string]: number },
  children: ComparativeChild[]
}
export function comparativeTableRowsFromMetricValues(
  data: { userRequested: ComparativeRowData[][], yearlyRecords: ComparativeRowData[][] },
  period = defaultPeriod(),
  fiscalYearStart = 1,
  rowUuidList: string[]
) {
  period = adjustPeriod(period, fiscalYearStart);
  const columns = createColumns(period);
  const format = 'yyyyMMdd';
  const dates = Object.keys(columns);
  const size = dates.length;

  function getFiscalYear(useFiscalYear: boolean, date: DateTime, fiscalYearStart: number): number {
    if (useFiscalYear) {
      return date.month >= fiscalYearStart ? date.year + 1 : date.year;
    }
    return date.year;
  }
  function extractRowValues(data: [ComparativeRowData, ComparativeRowData], yearly: [ComparativeRowData, ComparativeRowData]) {
    const useFiscalYear = fiscalYearStart !== 1;
    const { trail = [] } = dates.map(x => DateTime.fromISO(x))
      .reduce(({ before = undefined, trail }, curr, pos) => {
        const scenario1Value = (data[0]?.values || {})[curr.toFormat(format)] || 0.0;
        const scenario2Value = (data[1]?.values || {})[curr.toFormat(format)] || 0.0;
        const deviation = (scenario1Value ? ((scenario2Value - scenario1Value) / scenario1Value) * 100 : 0.0);
        // HACK: Deviation is a string with the % hardcoded to avoid localization to the metric unit
        const valuesToAdd = [scenario1Value, scenario2Value, isNaN(deviation) ? '-' : deviation];
        if (period?.grain === 'month') {

          if (curr.month === fiscalYearStart && pos > 0) {
            const scenario1YearlyValue = yearly[0].values[curr.set({ month: 1, day: 1, year: fiscalYearStart === 1 ? curr.year - 1 : curr.year }).toFormat(format)] || 0.0;
            const scenario2YearlyValue = yearly[1].values[curr.set({ month: 1, day: 1, year: fiscalYearStart === 1 ? curr.year - 1 : curr.year }).toFormat(format)] || 0.0;
            const yearlyDeviation = scenario1YearlyValue ? ((scenario2YearlyValue - scenario1YearlyValue) / scenario1YearlyValue) * 100 : 0.0;
            valuesToAdd.splice(0, 0, ...[
              scenario1YearlyValue,
              scenario2YearlyValue,
              isNaN(yearlyDeviation) ? '-' : yearlyDeviation
            ]
            );
          }
          if (pos === size - 1) {
            const scenario1YearlyValue = yearly[0].values[`${getFiscalYear(useFiscalYear, curr, fiscalYearStart)}0101`] || 0.0;
            const scenario2YearlyValue = yearly[1].values[`${getFiscalYear(useFiscalYear, curr, fiscalYearStart)}0101`] || 0.0;
            const yearlyDeviation = scenario1YearlyValue ? ((scenario2YearlyValue - scenario1YearlyValue) / scenario1YearlyValue) * 100 : 0.0;
            valuesToAdd.push(...[
              scenario1YearlyValue,
              scenario2YearlyValue,
              isNaN(yearlyDeviation) ? '-' : yearlyDeviation
            ]);
          }

        } else if (period?.grain === 'quarter') {
          if (curr.quarter === 1 && pos > 0) {
            const scenario1YearlyValue = yearly[0].values[curr.set({ month: 1, day: 1, year: curr.year - 1 }).toFormat(format)] || 0.0;
            const scenario2YearlyValue = yearly[1].values[curr.set({ month: 1, day: 1, year: curr.year - 1 }).toFormat(format)] || 0.0;
            const yearlyDeviation = scenario1YearlyValue ? ((scenario2YearlyValue - scenario1YearlyValue) / scenario1YearlyValue) * 100 : 0.0;
            valuesToAdd.splice(0, 0, ...[
              scenario1YearlyValue,
              scenario2YearlyValue,
              isNaN(yearlyDeviation) ? '-' : yearlyDeviation
            ]);
          }
          if (pos === size - 1) {
            const scenario1YearlyValue = yearly[0].values[curr.set({ month: 1, day: 1, year: curr.year }).toFormat(format)] || 0.0;
            const scenario2YearlyValue = yearly[1].values[curr.set({ month: 1, day: 1, year: curr.year }).toFormat(format)] || 0.0;
            const yearlyDeviation = scenario1YearlyValue ? ((scenario2YearlyValue - scenario1YearlyValue) / scenario1YearlyValue) * 100 : 0.0;
            valuesToAdd.push(...[
              scenario1YearlyValue,
              scenario2YearlyValue,
              isNaN(yearlyDeviation) ? '-' : yearlyDeviation
            ]);
          }
        }
        return {
          before: curr, trail: [
            ...trail,
            ...valuesToAdd
          ]
        };

      }, { trail: [] }) as unknown as { trail: Cell[]; before?: DateTime };
    return trail;
  }

  function asRowData(
    scenarioData: (ComparativeRowData | ComparativeChild)[],
    yearlyData: (ComparativeRowData | ComparativeChild)[]
  ): Row {
    const firstScenarioData = scenarioData[0];
    const secondScenarioData = scenarioData[1];

    const data = {
      // These three first properties will be 
      // the same for both scenarios for each metric
      uuid: firstScenarioData?.uuid || yearlyData[0]?.uuid,
      unit: firstScenarioData?.unit || yearlyData[0]?.uuid,
      label: firstScenarioData?.label || yearlyData[0]?.label,
      columns: extractRowValues(scenarioData, yearlyData)
    };

    const missingLabels = secondScenarioData?.children?.filter( c => !firstScenarioData.children.map( c => c.label).includes(c.label)) || [];
    missingLabels.forEach( l => {
      firstScenarioData.children.push({label: l.label, dimension: l.dimension, values: {}, children: []} as ComparativeChild);
    });

    const missingYearlyLabels = yearlyData[1]?.children?.filter( c => !yearlyData[0].children.map( c => c.label).includes(c.label)) || [];
    missingYearlyLabels.forEach( l => {
      yearlyData[0].children.push({label: l.label, dimension: l.dimension, values: {}, children: []} as ComparativeChild);
    });

    const children = firstScenarioData.children?.map((c) => [c, secondScenarioData?.children.find(r => r.label === c.label)]) || []
    const yearlyChildren = yearlyData[0].children?.map((c) => [c, yearlyData[1]?.children.find(r => r.label === c.label)]) || []

    // We might have only the yearly data.
    // TODO: Remove all this and migrate to pivot tables
    // which don't have these issues.
    if (children?.length) {
      data.children = children
          .map((r) => {
            const currLabel = r[0].label;
            const firstScenarioYearlyData = yearlyData[0]?.children?.find(c => c.label === currLabel) || [];
            const secondScenarioYearlyData = yearlyData[1]?.children?.find(c => c.label === currLabel) || [];
            return asRowData(r, [firstScenarioYearlyData, secondScenarioYearlyData]);
          });
    } else if (yearlyChildren?.length) {
      data.children = yearlyChildren
          .map((r) => {
            const currLabel = r[0].label;
            const firstScenarioData = scenarioData[0].children?.find(c => c.label === currLabel) || [];
            const secondScenarioData = scenarioData[1]?.children?.find(c => c.label === currLabel) || [];
            return asRowData([firstScenarioData, secondScenarioData], r);
          });
    }

    return data;

  }

  return rowUuidList?.reduce((acc: Row[], uuid: string) => {
    if (uuid.match(tableSeparatorRowRegex)) {
      acc.push({
        uuid,
        label: uuid.split(tableSeparatorRowRegex)[1],
        columns: Array(dates.length).fill(0),
        children: []
      });
      return acc;
    }

    const rowData = data.userRequested?.find(r => r[0].uuid === uuid);
    if (!rowData) return acc;
    const yearlyData = data.yearlyRecords?.find(r => r[0].uuid === uuid);

    const row = asRowData(rowData, yearlyData || []);
    acc.push(row);

    return acc;
  }, []) || [];

  return data.userRequested?.map((metricRow) => {
    const yearlyData = data.yearlyRecords?.find(r => r[0].label === metricRow[0].label);
    return asRowData(metricRow, yearlyData || [], metricsUnitsMap[metricRow[0].uuid]);
  }) || [];
}


export function sourcesTableFlattenedRowsFromMetricValues(
  data: SourcesData["table"],
  period = defaultPeriod(),
  metricUnitsMap: Record<string, string> = {}
) {
  const columns = createColumns(period || defaultPeriod());
  const format = 'yyyyMMdd';
  const dates = Object.keys(columns);

  type RowChild = {
    uuid: string;
    code: string;
    label: string;
    url?: string;
    columns: Cell[];
    children: SourcesTableRow[];
    depth: number,
    id: string,
    parentId?: string
  };

  function asRowData(
    rows: SourcesTableRow[],
    depth = 0,
    parentId?: string
  ): RowChild[] {
    return rows.reduce((acc, row, i) => {
      const rowId = `${parentId ? parentId + '-' : ''}${row.uuid}`;
      return [
        ...acc,
        {
          id: rowId,
          parentId,
          uuid: row.uuid,
          code: row.code,
          url: row.dimension == "metric" ? `metrics/${row.code}`: `models/${row.modelUuid}`,
          type: row.dimension,
          label: row.label,
          unit: metricUnitsMap[row.uuid],
          depth,
          columns: dates.map(x => DateTime.fromISO(x)).reduce((trail, curr) => {
            const value = row.values[curr.toFormat(format)] || 0;
            // FIXME: Add yearly totals
            return [...trail, value];
          }, []) as unknown as Cell[],
          children: row.children || []
        },
        ...asRowData(row.children || [], depth + 1, rowId)
      ];
    }, [] as RowChild[]);
  }

  return data?.length ? asRowData(data) : [];
}



export const tableSeparatorRowRegex = /__\$[a-zA-Z0-9]+\$__/gm;

/**
 * Flattens an arrray of Rows. These rows contain the labels of all the rows. 
 * @param rows 
 * @param depth 
 * @param parentIdx 
 * @param parentUuid 
 * @param parentUnit
 * @returns 
 */
export function flattenTableRows(
  rows: Row[], 
  depth = 0, 
  parentIdx: string | null = null, 
  parentUuid?: string, 
  parentUnit?: string
): any {
    return rows.reduce((acc, row, i: number) => {
      return [
        ...acc,
        {
          ...row,
          depth: depth,
          rowIdx: parentIdx != null ?
            `${parentIdx}_${i}`
            : `${i}`,
          uuid: row.uuid || parentUuid,
          unit: row.unit || parentUnit,
          id: parentIdx != null ?
          `${parentIdx}_${i}`
          : `${i}`,
          parentId: parentIdx,
          children: row.children || []
        },
        ...flattenTableRows(
          row.children || [], 
          depth + 1, 
          parentIdx != null ? `${parentIdx}_${i}` : `${i}`, 
          row.uuid || parentUuid, 
          row.unit || parentUnit
        )
      ];
    }, [] as Row[]);
}