/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    _boolean?: FilterBooleanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Filter
     */
    negated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    operation: FilterOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    field: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    values: Array<string>;
}


/**
 * @export
 */
export const FilterBooleanEnum = {
    And: 'and',
    Or: 'or'
} as const;
export type FilterBooleanEnum = typeof FilterBooleanEnum[keyof typeof FilterBooleanEnum];

/**
 * @export
 */
export const FilterOperationEnum = {
    Eq: 'eq',
    Contains: 'contains',
    StartsWith: 'startsWith',
    EndsWith: 'endsWith',
    Neq: 'neq',
    Before: 'before',
    After: 'after'
} as const;
export type FilterOperationEnum = typeof FilterOperationEnum[keyof typeof FilterOperationEnum];


/**
 * Check if a given object implements the Filter interface.
 */
export function instanceOfFilter(value: object): boolean {
    if (!('operation' in value)) return false;
    if (!('field' in value)) return false;
    if (!('values' in value)) return false;
    return true;
}

export function FilterFromJSON(json: any): Filter {
    return FilterFromJSONTyped(json, false);
}

export function FilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Filter {
    if (json == null) {
        return json;
    }
    return {
        
        '_boolean': json['boolean'] == null ? undefined : json['boolean'],
        'negated': json['negated'] == null ? undefined : json['negated'],
        'operation': json['operation'],
        'field': json['field'],
        'values': json['values'],
    };
}

export function FilterToJSON(value?: Filter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'boolean': value['_boolean'],
        'negated': value['negated'],
        'operation': value['operation'],
        'field': value['field'],
        'values': value['values'],
    };
}

