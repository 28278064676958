/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface PivotTableCsvParams
 */
export interface PivotTableCsvParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof PivotTableCsvParams
     */
    dimensions?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof PivotTableCsvParams
     */
    filters?: Array<Filter>;
    /**
     * 
     * @type {string}
     * @memberof PivotTableCsvParams
     */
    periodSize?: string;
    /**
     * 
     * @type {Period}
     * @memberof PivotTableCsvParams
     */
    period: Period;
}

/**
 * Check if a given object implements the PivotTableCsvParams interface.
 */
export function instanceOfPivotTableCsvParams(value: object): boolean {
    if (!('period' in value)) return false;
    return true;
}

export function PivotTableCsvParamsFromJSON(json: any): PivotTableCsvParams {
    return PivotTableCsvParamsFromJSONTyped(json, false);
}

export function PivotTableCsvParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTableCsvParams {
    if (json == null) {
        return json;
    }
    return {
        
        'dimensions': json['dimensions'] == null ? undefined : json['dimensions'],
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
        'periodSize': json['period_size'] == null ? undefined : json['period_size'],
        'period': PeriodFromJSON(json['period']),
    };
}

export function PivotTableCsvParamsToJSON(value?: PivotTableCsvParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dimensions': value['dimensions'],
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
        'period_size': value['periodSize'],
        'period': PeriodToJSON(value['period']),
    };
}

