/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayConnection } from './DisplayConnection';
import {
    DisplayConnectionFromJSON,
    DisplayConnectionFromJSONTyped,
    DisplayConnectionToJSON,
} from './DisplayConnection';
import type { DisplayDimension } from './DisplayDimension';
import {
    DisplayDimensionFromJSON,
    DisplayDimensionFromJSONTyped,
    DisplayDimensionToJSON,
} from './DisplayDimension';
import type { DisplayModel } from './DisplayModel';
import {
    DisplayModelFromJSON,
    DisplayModelFromJSONTyped,
    DisplayModelToJSON,
} from './DisplayModel';
import type { DisplayUploader } from './DisplayUploader';
import {
    DisplayUploaderFromJSON,
    DisplayUploaderFromJSONTyped,
    DisplayUploaderToJSON,
} from './DisplayUploader';
import type { ModelTableData } from './ModelTableData';
import {
    ModelTableDataFromJSON,
    ModelTableDataFromJSONTyped,
    ModelTableDataToJSON,
} from './ModelTableData';

/**
 * 
 * @export
 * @interface PublicModelResponse
 */
export interface PublicModelResponse {
    /**
     * 
     * @type {DisplayModel}
     * @memberof PublicModelResponse
     */
    model: DisplayModel;
    /**
     * 
     * @type {ModelTableData}
     * @memberof PublicModelResponse
     */
    data: ModelTableData;
    /**
     * 
     * @type {Array<DisplayDimension>}
     * @memberof PublicModelResponse
     */
    wsDimensions: Array<DisplayDimension>;
    /**
     * 
     * @type {Array<DisplayConnection>}
     * @memberof PublicModelResponse
     */
    connections: Array<DisplayConnection>;
    /**
     * 
     * @type {Array<DisplayUploader>}
     * @memberof PublicModelResponse
     */
    uploaders: Array<DisplayUploader>;
}

/**
 * Check if a given object implements the PublicModelResponse interface.
 */
export function instanceOfPublicModelResponse(value: object): boolean {
    if (!('model' in value)) return false;
    if (!('data' in value)) return false;
    if (!('wsDimensions' in value)) return false;
    if (!('connections' in value)) return false;
    if (!('uploaders' in value)) return false;
    return true;
}

export function PublicModelResponseFromJSON(json: any): PublicModelResponse {
    return PublicModelResponseFromJSONTyped(json, false);
}

export function PublicModelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicModelResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'model': DisplayModelFromJSON(json['model']),
        'data': ModelTableDataFromJSON(json['data']),
        'wsDimensions': ((json['ws_dimensions'] as Array<any>).map(DisplayDimensionFromJSON)),
        'connections': ((json['connections'] as Array<any>).map(DisplayConnectionFromJSON)),
        'uploaders': ((json['uploaders'] as Array<any>).map(DisplayUploaderFromJSON)),
    };
}

export function PublicModelResponseToJSON(value?: PublicModelResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'model': DisplayModelToJSON(value['model']),
        'data': ModelTableDataToJSON(value['data']),
        'ws_dimensions': ((value['wsDimensions'] as Array<any>).map(DisplayDimensionToJSON)),
        'connections': ((value['connections'] as Array<any>).map(DisplayConnectionToJSON)),
        'uploaders': ((value['uploaders'] as Array<any>).map(DisplayUploaderToJSON)),
    };
}

