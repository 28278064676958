/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthUser,
  CreateWorkspaceParams,
  HTTPValidationError,
  Invite,
  InviteResponse,
  InviteUserToWorkspaceParams,
  UpdateWorkspaceParams,
  Workspace,
} from '../models/index';
import {
    AuthUserFromJSON,
    AuthUserToJSON,
    CreateWorkspaceParamsFromJSON,
    CreateWorkspaceParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    InviteFromJSON,
    InviteToJSON,
    InviteResponseFromJSON,
    InviteResponseToJSON,
    InviteUserToWorkspaceParamsFromJSON,
    InviteUserToWorkspaceParamsToJSON,
    UpdateWorkspaceParamsFromJSON,
    UpdateWorkspaceParamsToJSON,
    WorkspaceFromJSON,
    WorkspaceToJSON,
} from '../models/index';

export interface CancelInviteRequest {
    inviteUuid: string;
    workspaceCode: string;
}

export interface CreateWorkspaceRequest {
    createWorkspaceParams: CreateWorkspaceParams;
}

export interface DeleteWorkspaceRequest {
    workspaceUuid: string;
}

export interface FetchNewUserInviteRequest {
    inviteUuid: string;
}

export interface FetchWorkspacePendingInvitesRequest {
    workspaceCode: string;
}

export interface FetchWorkspaceUsersRequest {
    workspaceCode: string;
}

export interface InviteUserToWorkspaceRequest {
    inviteUserToWorkspaceParams: InviteUserToWorkspaceParams;
}

export interface RemoveUserFromWorkspaceRequest {
    userUuid: string;
    workspaceCode: string;
}

export interface UpdateWorkspaceRequest {
    updateWorkspaceParams: UpdateWorkspaceParams;
}

export interface UpdateWorkspaceLogoRequest {
    workspaceUuid: string;
    file: Blob;
}

/**
 * 
 */
export class WorkspaceApi extends runtime.BaseAPI {

    /**
     * Cancel Invite
     */
    async cancelInviteRaw(requestParameters: CancelInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['inviteUuid'] == null) {
            throw new runtime.RequiredError(
                'inviteUuid',
                'Required parameter "inviteUuid" was null or undefined when calling cancelInvite().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling cancelInvite().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workspaceCode'] != null) {
            queryParameters['workspace_code'] = requestParameters['workspaceCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/invites/{invite_uuid}/cancel`.replace(`{${"invite_uuid"}}`, encodeURIComponent(String(requestParameters['inviteUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Cancel Invite
     */
    async cancelInvite(requestParameters: CancelInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.cancelInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Workspace
     */
    async createWorkspaceRaw(requestParameters: CreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters['createWorkspaceParams'] == null) {
            throw new runtime.RequiredError(
                'createWorkspaceParams',
                'Required parameter "createWorkspaceParams" was null or undefined when calling createWorkspace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkspaceParamsToJSON(requestParameters['createWorkspaceParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Create Workspace
     */
    async createWorkspace(requestParameters: CreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workspace> {
        const response = await this.createWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Workspace
     */
    async deleteWorkspaceRaw(requestParameters: DeleteWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceUuid'] == null) {
            throw new runtime.RequiredError(
                'workspaceUuid',
                'Required parameter "workspaceUuid" was null or undefined when calling deleteWorkspace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/{workspace_uuid}`.replace(`{${"workspace_uuid"}}`, encodeURIComponent(String(requestParameters['workspaceUuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Workspace
     */
    async deleteWorkspace(requestParameters: DeleteWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch New User Invite
     */
    async fetchNewUserInviteRaw(requestParameters: FetchNewUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InviteResponse>> {
        if (requestParameters['inviteUuid'] == null) {
            throw new runtime.RequiredError(
                'inviteUuid',
                'Required parameter "inviteUuid" was null or undefined when calling fetchNewUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workspaces/invites/{invite_uuid}`.replace(`{${"invite_uuid"}}`, encodeURIComponent(String(requestParameters['inviteUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InviteResponseFromJSON(jsonValue));
    }

    /**
     * Fetch New User Invite
     */
    async fetchNewUserInvite(requestParameters: FetchNewUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InviteResponse> {
        const response = await this.fetchNewUserInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch User Workspaces
     */
    async fetchUserWorkspacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Workspace>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkspaceFromJSON));
    }

    /**
     * Fetch User Workspaces
     */
    async fetchUserWorkspaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Workspace>> {
        const response = await this.fetchUserWorkspacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch Workspace Pending Invites
     */
    async fetchWorkspacePendingInvitesRaw(requestParameters: FetchWorkspacePendingInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invite>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchWorkspacePendingInvites().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workspaceCode'] != null) {
            queryParameters['workspace_code'] = requestParameters['workspaceCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InviteFromJSON));
    }

    /**
     * Fetch Workspace Pending Invites
     */
    async fetchWorkspacePendingInvites(requestParameters: FetchWorkspacePendingInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invite>> {
        const response = await this.fetchWorkspacePendingInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Workspace Users
     */
    async fetchWorkspaceUsersRaw(requestParameters: FetchWorkspaceUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuthUser>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchWorkspaceUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workspaceCode'] != null) {
            queryParameters['workspace_code'] = requestParameters['workspaceCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthUserFromJSON));
    }

    /**
     * Fetch Workspace Users
     */
    async fetchWorkspaceUsers(requestParameters: FetchWorkspaceUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuthUser>> {
        const response = await this.fetchWorkspaceUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite User To Workspace
     */
    async inviteUserToWorkspaceRaw(requestParameters: InviteUserToWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['inviteUserToWorkspaceParams'] == null) {
            throw new runtime.RequiredError(
                'inviteUserToWorkspaceParams',
                'Required parameter "inviteUserToWorkspaceParams" was null or undefined when calling inviteUserToWorkspace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteUserToWorkspaceParamsToJSON(requestParameters['inviteUserToWorkspaceParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Invite User To Workspace
     */
    async inviteUserToWorkspace(requestParameters: InviteUserToWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.inviteUserToWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove User From Workspace
     */
    async removeUserFromWorkspaceRaw(requestParameters: RemoveUserFromWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling removeUserFromWorkspace().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeUserFromWorkspace().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workspaceCode'] != null) {
            queryParameters['workspace_code'] = requestParameters['workspaceCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/users/{user_uuid}/remove`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove User From Workspace
     */
    async removeUserFromWorkspace(requestParameters: RemoveUserFromWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.removeUserFromWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Workspace
     */
    async updateWorkspaceRaw(requestParameters: UpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['updateWorkspaceParams'] == null) {
            throw new runtime.RequiredError(
                'updateWorkspaceParams',
                'Required parameter "updateWorkspaceParams" was null or undefined when calling updateWorkspace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/workspaces/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceParamsToJSON(requestParameters['updateWorkspaceParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Workspace
     */
    async updateWorkspace(requestParameters: UpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Workspace Logo
     */
    async updateWorkspaceLogoRaw(requestParameters: UpdateWorkspaceLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceUuid'] == null) {
            throw new runtime.RequiredError(
                'workspaceUuid',
                'Required parameter "workspaceUuid" was null or undefined when calling updateWorkspaceLogo().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling updateWorkspaceLogo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/workspaces/logo/{workspace_uuid}`.replace(`{${"workspace_uuid"}}`, encodeURIComponent(String(requestParameters['workspaceUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Workspace Logo
     */
    async updateWorkspaceLogo(requestParameters: UpdateWorkspaceLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateWorkspaceLogoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
