/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NavSection
 */
export interface NavSection {
    /**
     * 
     * @type {string}
     * @memberof NavSection
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof NavSection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NavSection
     */
    emoji?: string;
    /**
     * 
     * @type {string}
     * @memberof NavSection
     */
    groupUuid?: string;
}

/**
 * Check if a given object implements the NavSection interface.
 */
export function instanceOfNavSection(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function NavSectionFromJSON(json: any): NavSection {
    return NavSectionFromJSONTyped(json, false);
}

export function NavSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavSection {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'emoji': json['emoji'] == null ? undefined : json['emoji'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
    };
}

export function NavSectionToJSON(value?: NavSection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'emoji': value['emoji'],
        'group_uuid': value['groupUuid'],
    };
}

