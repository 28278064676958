/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverviewTableData
 */
export interface OverviewTableData {
    /**
     * 
     * @type {Array<string>}
     * @memberof OverviewTableData
     */
    headers: Array<string>;
    /**
     * 
     * @type {Array<any>}
     * @memberof OverviewTableData
     */
    rows: Array<any>;
}

/**
 * Check if a given object implements the OverviewTableData interface.
 */
export function instanceOfOverviewTableData(value: object): boolean {
    if (!('headers' in value)) return false;
    if (!('rows' in value)) return false;
    return true;
}

export function OverviewTableDataFromJSON(json: any): OverviewTableData {
    return OverviewTableDataFromJSONTyped(json, false);
}

export function OverviewTableDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewTableData {
    if (json == null) {
        return json;
    }
    return {
        
        'headers': json['headers'],
        'rows': json['rows'],
    };
}

export function OverviewTableDataToJSON(value?: OverviewTableData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'headers': value['headers'],
        'rows': value['rows'],
    };
}

