/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteResponse
 */
export interface InviteResponse {
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    inviteeUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof InviteResponse
     */
    lastSentAt: Date;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    inviteeName?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    inviteeLastName?: string;
}

/**
 * Check if a given object implements the InviteResponse interface.
 */
export function instanceOfInviteResponse(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('email' in value)) return false;
    if (!('lastSentAt' in value)) return false;
    return true;
}

export function InviteResponseFromJSON(json: any): InviteResponse {
    return InviteResponseFromJSONTyped(json, false);
}

export function InviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'inviteeUuid': json['invitee_uuid'] == null ? undefined : json['invitee_uuid'],
        'email': json['email'],
        'lastSentAt': (new Date(json['last_sent_at'])),
        'inviteeName': json['invitee_name'] == null ? undefined : json['invitee_name'],
        'inviteeLastName': json['invitee_last_name'] == null ? undefined : json['invitee_last_name'],
    };
}

export function InviteResponseToJSON(value?: InviteResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'invitee_uuid': value['inviteeUuid'],
        'email': value['email'],
        'last_sent_at': ((value['lastSentAt']).toISOString()),
        'invitee_name': value['inviteeName'],
        'invitee_last_name': value['inviteeLastName'],
    };
}

