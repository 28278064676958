/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelVersionParams
 */
export interface UpdateModelVersionParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVersionParams
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVersionParams
     */
    excelDownloadUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVersionParams
     */
    gaCampaignName?: string;
}

/**
 * Check if a given object implements the UpdateModelVersionParams interface.
 */
export function instanceOfUpdateModelVersionParams(value: object): boolean {
    return true;
}

export function UpdateModelVersionParamsFromJSON(json: any): UpdateModelVersionParams {
    return UpdateModelVersionParamsFromJSONTyped(json, false);
}

export function UpdateModelVersionParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelVersionParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'excelDownloadUrl': json['excel_download_url'] == null ? undefined : json['excel_download_url'],
        'gaCampaignName': json['ga_campaign_name'] == null ? undefined : json['ga_campaign_name'],
    };
}

export function UpdateModelVersionParamsToJSON(value?: UpdateModelVersionParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'excel_download_url': value['excelDownloadUrl'],
        'ga_campaign_name': value['gaCampaignName'],
    };
}

