/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';

/**
 * 
 * @export
 * @interface DisplayUploader
 */
export interface DisplayUploader {
    /**
     * 
     * @type {string}
     * @memberof DisplayUploader
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayUploader
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayUploader
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayUploader
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayUploader
     */
    description?: string;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof DisplayUploader
     */
    schema?: Array<SchemaColumn>;
    /**
     * 
     * @type {Date}
     * @memberof DisplayUploader
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayUploader
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the DisplayUploader interface.
 */
export function instanceOfDisplayUploader(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    return true;
}

export function DisplayUploaderFromJSON(json: any): DisplayUploader {
    return DisplayUploaderFromJSONTyped(json, false);
}

export function DisplayUploaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayUploader {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'code': json['code'],
        'description': json['description'] == null ? undefined : json['description'],
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
    };
}

export function DisplayUploaderToJSON(value?: DisplayUploader | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'code': value['code'],
        'description': value['description'],
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
    };
}

