/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MappingOption } from './MappingOption';
import {
    MappingOptionFromJSON,
    MappingOptionFromJSONTyped,
    MappingOptionToJSON,
} from './MappingOption';
import type { MappingUploadSettings } from './MappingUploadSettings';
import {
    MappingUploadSettingsFromJSON,
    MappingUploadSettingsFromJSONTyped,
    MappingUploadSettingsToJSON,
} from './MappingUploadSettings';

/**
 * 
 * @export
 * @interface UpdateMappingParams
 */
export interface UpdateMappingParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingParams
     */
    code: string;
    /**
     * 
     * @type {Array<MappingOption>}
     * @memberof UpdateMappingParams
     */
    options: Array<MappingOption>;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingParams
     */
    mappingUuid: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingParams
     */
    defaultValue?: string;
    /**
     * 
     * @type {MappingUploadSettings}
     * @memberof UpdateMappingParams
     */
    settings?: MappingUploadSettings;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingParams
     */
    uploadUuid?: string;
}

/**
 * Check if a given object implements the UpdateMappingParams interface.
 */
export function instanceOfUpdateMappingParams(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('options' in value)) return false;
    if (!('mappingUuid' in value)) return false;
    return true;
}

export function UpdateMappingParamsFromJSON(json: any): UpdateMappingParams {
    return UpdateMappingParamsFromJSONTyped(json, false);
}

export function UpdateMappingParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMappingParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'code': json['code'],
        'options': ((json['options'] as Array<any>).map(MappingOptionFromJSON)),
        'mappingUuid': json['mapping_uuid'],
        'defaultValue': json['default_value'] == null ? undefined : json['default_value'],
        'settings': json['settings'] == null ? undefined : MappingUploadSettingsFromJSON(json['settings']),
        'uploadUuid': json['upload_uuid'] == null ? undefined : json['upload_uuid'],
    };
}

export function UpdateMappingParamsToJSON(value?: UpdateMappingParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'code': value['code'],
        'options': ((value['options'] as Array<any>).map(MappingOptionToJSON)),
        'mapping_uuid': value['mappingUuid'],
        'default_value': value['defaultValue'],
        'settings': MappingUploadSettingsToJSON(value['settings']),
        'upload_uuid': value['uploadUuid'],
    };
}

