/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayDimension } from './DisplayDimension';
import {
    DisplayDimensionFromJSON,
    DisplayDimensionFromJSONTyped,
    DisplayDimensionToJSON,
} from './DisplayDimension';
import type { ModelTableData } from './ModelTableData';
import {
    ModelTableDataFromJSON,
    ModelTableDataFromJSONTyped,
    ModelTableDataToJSON,
} from './ModelTableData';
import type { ScenarioTreeNode } from './ScenarioTreeNode';
import {
    ScenarioTreeNodeFromJSON,
    ScenarioTreeNodeFromJSONTyped,
    ScenarioTreeNodeToJSON,
} from './ScenarioTreeNode';

/**
 * 
 * @export
 * @interface CommunityModelPreviewResponse
 */
export interface CommunityModelPreviewResponse {
    /**
     * 
     * @type {ModelTableData}
     * @memberof CommunityModelPreviewResponse
     */
    data: ModelTableData;
    /**
     * 
     * @type {Array<DisplayDimension>}
     * @memberof CommunityModelPreviewResponse
     */
    dimensions?: Array<DisplayDimension>;
    /**
     * 
     * @type {Array<ScenarioTreeNode>}
     * @memberof CommunityModelPreviewResponse
     */
    scenarios?: Array<ScenarioTreeNode>;
}

/**
 * Check if a given object implements the CommunityModelPreviewResponse interface.
 */
export function instanceOfCommunityModelPreviewResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CommunityModelPreviewResponseFromJSON(json: any): CommunityModelPreviewResponse {
    return CommunityModelPreviewResponseFromJSONTyped(json, false);
}

export function CommunityModelPreviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunityModelPreviewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ModelTableDataFromJSON(json['data']),
        'dimensions': json['dimensions'] == null ? undefined : ((json['dimensions'] as Array<any>).map(DisplayDimensionFromJSON)),
        'scenarios': json['scenarios'] == null ? undefined : ((json['scenarios'] as Array<any>).map(ScenarioTreeNodeFromJSON)),
    };
}

export function CommunityModelPreviewResponseToJSON(value?: CommunityModelPreviewResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ModelTableDataToJSON(value['data']),
        'dimensions': value['dimensions'] == null ? undefined : ((value['dimensions'] as Array<any>).map(DisplayDimensionToJSON)),
        'scenarios': value['scenarios'] == null ? undefined : ((value['scenarios'] as Array<any>).map(ScenarioTreeNodeToJSON)),
    };
}

