/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MappingFilter } from './MappingFilter';
import {
    MappingFilterFromJSON,
    MappingFilterFromJSONTyped,
    MappingFilterToJSON,
} from './MappingFilter';
import type { MappingOperation } from './MappingOperation';
import {
    MappingOperationFromJSON,
    MappingOperationFromJSONTyped,
    MappingOperationToJSON,
} from './MappingOperation';

/**
 * 
 * @export
 * @interface MappingOption
 */
export interface MappingOption {
    /**
     * 
     * @type {string}
     * @memberof MappingOption
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingOption
     */
    resultValue?: string;
    /**
     * 
     * @type {Array<MappingFilter>}
     * @memberof MappingOption
     */
    mappingFilters?: Array<MappingFilter>;
    /**
     * 
     * @type {MappingOperation}
     * @memberof MappingOption
     */
    operation?: MappingOperation;
}

/**
 * Check if a given object implements the MappingOption interface.
 */
export function instanceOfMappingOption(value: object): boolean {
    return true;
}

export function MappingOptionFromJSON(json: any): MappingOption {
    return MappingOptionFromJSONTyped(json, false);
}

export function MappingOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingOption {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'resultValue': json['result_value'] == null ? undefined : json['result_value'],
        'mappingFilters': json['mapping_filters'] == null ? undefined : ((json['mapping_filters'] as Array<any>).map(MappingFilterFromJSON)),
        'operation': json['operation'] == null ? undefined : MappingOperationFromJSON(json['operation']),
    };
}

export function MappingOptionToJSON(value?: MappingOption | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'result_value': value['resultValue'],
        'mapping_filters': value['mappingFilters'] == null ? undefined : ((value['mappingFilters'] as Array<any>).map(MappingFilterToJSON)),
        'operation': MappingOperationToJSON(value['operation']),
    };
}

