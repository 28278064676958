/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelVersionContributor
 */
export interface ModelVersionContributor {
    /**
     * 
     * @type {string}
     * @memberof ModelVersionContributor
     */
    userUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersionContributor
     */
    modelVersionUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ModelVersionContributor
     */
    role: ModelVersionContributorRoleEnum;
}


/**
 * @export
 */
export const ModelVersionContributorRoleEnum = {
    Owner: 'owner',
    Contributor: 'contributor',
    Guest: 'guest'
} as const;
export type ModelVersionContributorRoleEnum = typeof ModelVersionContributorRoleEnum[keyof typeof ModelVersionContributorRoleEnum];


/**
 * Check if a given object implements the ModelVersionContributor interface.
 */
export function instanceOfModelVersionContributor(value: object): boolean {
    if (!('userUuid' in value)) return false;
    if (!('modelVersionUuid' in value)) return false;
    if (!('role' in value)) return false;
    return true;
}

export function ModelVersionContributorFromJSON(json: any): ModelVersionContributor {
    return ModelVersionContributorFromJSONTyped(json, false);
}

export function ModelVersionContributorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelVersionContributor {
    if (json == null) {
        return json;
    }
    return {
        
        'userUuid': json['user_uuid'],
        'modelVersionUuid': json['model_version_uuid'],
        'role': json['role'],
    };
}

export function ModelVersionContributorToJSON(value?: ModelVersionContributor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_uuid': value['userUuid'],
        'model_version_uuid': value['modelVersionUuid'],
        'role': value['role'],
    };
}

