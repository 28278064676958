import Rollbar from 'rollbar';
import { useConfig } from './useConfig';

const cfg = useConfig();

const rollbar = new Rollbar({
    accessToken: cfg.rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: cfg.env === 'production',
    payload: {
        environment: cfg.env,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: cfg.commit,
                guess_uncaught_frames: true
            }
        }
    }
});

export default (): Rollbar => rollbar;
