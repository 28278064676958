/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VariableDimension } from './VariableDimension';
import {
    VariableDimensionFromJSON,
    VariableDimensionFromJSONTyped,
    VariableDimensionToJSON,
} from './VariableDimension';

/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    uuid: string;
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    dimAggregation?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    timeAggregation?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    modelUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Variable
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Variable
     */
    createdAt?: Date;
    /**
     * 
     * @type {object}
     * @memberof Variable
     */
    rowStyles?: object;
    /**
     * 
     * @type {Array<VariableDimension>}
     * @memberof Variable
     */
    dimensions?: Array<VariableDimension>;
}

/**
 * Check if a given object implements the Variable interface.
 */
export function instanceOfVariable(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('modelUuid' in value)) return false;
    return true;
}

export function VariableFromJSON(json: any): Variable {
    return VariableFromJSONTyped(json, false);
}

export function VariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Variable {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'orderId': json['order_id'] == null ? undefined : json['order_id'],
        'name': json['name'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'dimAggregation': json['dim_aggregation'] == null ? undefined : json['dim_aggregation'],
        'timeAggregation': json['time_aggregation'] == null ? undefined : json['time_aggregation'],
        'modelUuid': json['model_uuid'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'rowStyles': json['row_styles'] == null ? undefined : json['row_styles'],
        'dimensions': json['dimensions'] == null ? undefined : ((json['dimensions'] as Array<any>).map(VariableDimensionFromJSON)),
    };
}

export function VariableToJSON(value?: Variable | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'order_id': value['orderId'],
        'name': value['name'],
        'unit': value['unit'],
        'dim_aggregation': value['dimAggregation'],
        'time_aggregation': value['timeAggregation'],
        'model_uuid': value['modelUuid'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'row_styles': value['rowStyles'],
        'dimensions': value['dimensions'] == null ? undefined : ((value['dimensions'] as Array<any>).map(VariableDimensionToJSON)),
    };
}

