/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchemaColumn
 */
export interface SchemaColumn {
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    type: SchemaColumnTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    _default?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaColumn
     */
    isDeduplicationKey?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    which?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    fromColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    mapping?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    formula?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaColumn
     */
    isDefaultDate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SchemaColumn
     */
    format?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaColumn
     */
    ignoreInvalidDates?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaColumn
     */
    isHidden?: boolean;
}


/**
 * @export
 */
export const SchemaColumnTypeEnum = {
    Formula: 'formula',
    Dimension: 'dimension',
    Value: 'value',
    Date: 'date',
    Mapping: 'mapping'
} as const;
export type SchemaColumnTypeEnum = typeof SchemaColumnTypeEnum[keyof typeof SchemaColumnTypeEnum];


/**
 * Check if a given object implements the SchemaColumn interface.
 */
export function instanceOfSchemaColumn(value: object): boolean {
    if (!('header' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function SchemaColumnFromJSON(json: any): SchemaColumn {
    return SchemaColumnFromJSONTyped(json, false);
}

export function SchemaColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaColumn {
    if (json == null) {
        return json;
    }
    return {
        
        'header': json['header'],
        'type': json['type'],
        '_default': json['default'] == null ? undefined : json['default'],
        'isDeduplicationKey': json['is_deduplication_key'] == null ? undefined : json['is_deduplication_key'],
        'which': json['which'] == null ? undefined : json['which'],
        'fromColumn': json['from_column'] == null ? undefined : json['from_column'],
        'mapping': json['mapping'] == null ? undefined : json['mapping'],
        'formula': json['formula'] == null ? undefined : json['formula'],
        'isDefaultDate': json['is_default_date'] == null ? undefined : json['is_default_date'],
        'format': json['format'] == null ? undefined : json['format'],
        'ignoreInvalidDates': json['ignore_invalid_dates'] == null ? undefined : json['ignore_invalid_dates'],
        'isHidden': json['is_hidden'] == null ? undefined : json['is_hidden'],
    };
}

export function SchemaColumnToJSON(value?: SchemaColumn | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'header': value['header'],
        'type': value['type'],
        'default': value['_default'],
        'is_deduplication_key': value['isDeduplicationKey'],
        'which': value['which'],
        'from_column': value['fromColumn'],
        'mapping': value['mapping'],
        'formula': value['formula'],
        'is_default_date': value['isDefaultDate'],
        'format': value['format'],
        'ignore_invalid_dates': value['ignoreInvalidDates'],
        'is_hidden': value['isHidden'],
    };
}

