/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DebugMetricParams,
  HTTPValidationError,
  SerializedQuery,
  SerializedQueryComputeResponse,
} from '../models/index';
import {
    DebugMetricParamsFromJSON,
    DebugMetricParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    SerializedQueryFromJSON,
    SerializedQueryToJSON,
    SerializedQueryComputeResponseFromJSON,
    SerializedQueryComputeResponseToJSON,
} from '../models/index';

export interface DebugComputeQueryRequest {
    serializedQuery: SerializedQuery;
}

export interface DebugMetricQuerySourcesRequest {
    metricUuid: string;
    debugMetricParams: DebugMetricParams;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * Compute Serialized Query
     */
    async debugComputeQueryRaw(requestParameters: DebugComputeQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SerializedQueryComputeResponse>> {
        if (requestParameters['serializedQuery'] == null) {
            throw new runtime.RequiredError(
                'serializedQuery',
                'Required parameter "serializedQuery" was null or undefined when calling debugComputeQuery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/admin/debug/compute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SerializedQueryToJSON(requestParameters['serializedQuery']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SerializedQueryComputeResponseFromJSON(jsonValue));
    }

    /**
     * Compute Serialized Query
     */
    async debugComputeQuery(requestParameters: DebugComputeQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SerializedQueryComputeResponse> {
        const response = await this.debugComputeQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Metric Query Sources Data
     */
    async debugMetricQuerySourcesRaw(requestParameters: DebugMetricQuerySourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['metricUuid'] == null) {
            throw new runtime.RequiredError(
                'metricUuid',
                'Required parameter "metricUuid" was null or undefined when calling debugMetricQuerySources().'
            );
        }

        if (requestParameters['debugMetricParams'] == null) {
            throw new runtime.RequiredError(
                'debugMetricParams',
                'Required parameter "debugMetricParams" was null or undefined when calling debugMetricQuerySources().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/admin/debug/metrics/{metric_uuid}/sources`.replace(`{${"metric_uuid"}}`, encodeURIComponent(String(requestParameters['metricUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DebugMetricParamsToJSON(requestParameters['debugMetricParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Metric Query Sources Data
     */
    async debugMetricQuerySources(requestParameters: DebugMetricQuerySourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.debugMetricQuerySourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
