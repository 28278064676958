/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Dimension
 */
export interface Dimension {
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    _default?: string;
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    uploaderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Dimension
     */
    workspaceUuid?: string;
    /**
     * 
     * @type {object}
     * @memberof Dimension
     */
    mappings?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Dimension
     */
    values?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Dimension
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the Dimension interface.
 */
export function instanceOfDimension(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    return true;
}

export function DimensionFromJSON(json: any): Dimension {
    return DimensionFromJSONTyped(json, false);
}

export function DimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dimension {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'code': json['code'],
        'description': json['description'] == null ? undefined : json['description'],
        '_default': json['default'] == null ? undefined : json['default'],
        'uploaderUuid': json['uploader_uuid'] == null ? undefined : json['uploader_uuid'],
        'workspaceUuid': json['workspace_uuid'] == null ? undefined : json['workspace_uuid'],
        'mappings': json['mappings'] == null ? undefined : json['mappings'],
        'values': json['values'] == null ? undefined : json['values'],
        'isDefault': json['is_default'] == null ? undefined : json['is_default'],
    };
}

export function DimensionToJSON(value?: Dimension | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'code': value['code'],
        'description': value['description'],
        'default': value['_default'],
        'uploader_uuid': value['uploaderUuid'],
        'workspace_uuid': value['workspaceUuid'],
        'mappings': value['mappings'],
        'values': value['values'],
        'is_default': value['isDefault'],
    };
}

