/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelVariableParams
 */
export interface UpdateModelVariableParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVariableParams
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVariableParams
     */
    aggregateFunction?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVariableParams
     */
    timeAggregateFunction?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVariableParams
     */
    unit?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateModelVariableParams
     */
    orderId?: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateModelVariableParams
     */
    rowStyles?: object;
}

/**
 * Check if a given object implements the UpdateModelVariableParams interface.
 */
export function instanceOfUpdateModelVariableParams(value: object): boolean {
    return true;
}

export function UpdateModelVariableParamsFromJSON(json: any): UpdateModelVariableParams {
    return UpdateModelVariableParamsFromJSONTyped(json, false);
}

export function UpdateModelVariableParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelVariableParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'aggregateFunction': json['aggregate_function'] == null ? undefined : json['aggregate_function'],
        'timeAggregateFunction': json['time_aggregate_function'] == null ? undefined : json['time_aggregate_function'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'orderId': json['order_id'] == null ? undefined : json['order_id'],
        'rowStyles': json['row_styles'] == null ? undefined : json['row_styles'],
    };
}

export function UpdateModelVariableParamsToJSON(value?: UpdateModelVariableParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'aggregate_function': value['aggregateFunction'],
        'time_aggregate_function': value['timeAggregateFunction'],
        'unit': value['unit'],
        'order_id': value['orderId'],
        'row_styles': value['rowStyles'],
    };
}

