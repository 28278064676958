/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface FetchConnectionDfParams
 */
export interface FetchConnectionDfParams {
    /**
     * 
     * @type {Period}
     * @memberof FetchConnectionDfParams
     */
    period: Period;
    /**
     * 
     * @type {number}
     * @memberof FetchConnectionDfParams
     */
    page?: number;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof FetchConnectionDfParams
     */
    filters?: Array<Filter>;
}

/**
 * Check if a given object implements the FetchConnectionDfParams interface.
 */
export function instanceOfFetchConnectionDfParams(value: object): boolean {
    if (!('period' in value)) return false;
    return true;
}

export function FetchConnectionDfParamsFromJSON(json: any): FetchConnectionDfParams {
    return FetchConnectionDfParamsFromJSONTyped(json, false);
}

export function FetchConnectionDfParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchConnectionDfParams {
    if (json == null) {
        return json;
    }
    return {
        
        'period': PeriodFromJSON(json['period']),
        'page': json['page'] == null ? undefined : json['page'],
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
    };
}

export function FetchConnectionDfParamsToJSON(value?: FetchConnectionDfParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': PeriodToJSON(value['period']),
        'page': value['page'],
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
    };
}

