/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterParams
 */
export interface RegisterParams {
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    inviteToken?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    credentialType?: RegisterParamsCredentialTypeEnum;
}


/**
 * @export
 */
export const RegisterParamsCredentialTypeEnum = {
    CofiEmail: 'cofi_email',
    AuthGoogle: 'auth_google',
    Auth0Email: 'auth0_email',
    Token: 'token'
} as const;
export type RegisterParamsCredentialTypeEnum = typeof RegisterParamsCredentialTypeEnum[keyof typeof RegisterParamsCredentialTypeEnum];


/**
 * Check if a given object implements the RegisterParams interface.
 */
export function instanceOfRegisterParams(value: object): boolean {
    if (!('firstName' in value)) return false;
    if (!('lastName' in value)) return false;
    if (!('email' in value)) return false;
    if (!('password' in value)) return false;
    return true;
}

export function RegisterParamsFromJSON(json: any): RegisterParams {
    return RegisterParamsFromJSONTyped(json, false);
}

export function RegisterParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterParams {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'email': json['email'],
        'password': json['password'],
        'inviteToken': json['inviteToken'] == null ? undefined : json['inviteToken'],
        'credentialType': json['credential_type'] == null ? undefined : json['credential_type'],
    };
}

export function RegisterParamsToJSON(value?: RegisterParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'email': value['email'],
        'password': value['password'],
        'inviteToken': value['inviteToken'],
        'credential_type': value['credentialType'],
    };
}

