/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface MetricSourcesParams
 */
export interface MetricSourcesParams {
    /**
     * 
     * @type {Period}
     * @memberof MetricSourcesParams
     */
    period: Period;
    /**
     * 
     * @type {string}
     * @memberof MetricSourcesParams
     */
    yearTotals?: MetricSourcesParamsYearTotalsEnum;
}


/**
 * @export
 */
export const MetricSourcesParamsYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type MetricSourcesParamsYearTotalsEnum = typeof MetricSourcesParamsYearTotalsEnum[keyof typeof MetricSourcesParamsYearTotalsEnum];


/**
 * Check if a given object implements the MetricSourcesParams interface.
 */
export function instanceOfMetricSourcesParams(value: object): boolean {
    if (!('period' in value)) return false;
    return true;
}

export function MetricSourcesParamsFromJSON(json: any): MetricSourcesParams {
    return MetricSourcesParamsFromJSONTyped(json, false);
}

export function MetricSourcesParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricSourcesParams {
    if (json == null) {
        return json;
    }
    return {
        
        'period': PeriodFromJSON(json['period']),
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
    };
}

export function MetricSourcesParamsToJSON(value?: MetricSourcesParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': PeriodToJSON(value['period']),
        'year_totals': value['yearTotals'],
    };
}

