/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosingDate } from './ClosingDate';
import {
    ClosingDateFromJSON,
    ClosingDateFromJSONTyped,
    ClosingDateToJSON,
} from './ClosingDate';

/**
 * 
 * @export
 * @interface UpdateScenario
 */
export interface UpdateScenario {
    /**
     * 
     * @type {string}
     * @memberof UpdateScenario
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenario
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenario
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateScenario
     */
    periodOffset?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenario
     */
    periodSize?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenario
     */
    sourceScenario?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScenario
     */
    actualsScenarioUuid?: string;
    /**
     * 
     * @type {ClosingDate}
     * @memberof UpdateScenario
     */
    closingDate?: ClosingDate;
}

/**
 * Check if a given object implements the UpdateScenario interface.
 */
export function instanceOfUpdateScenario(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('color' in value)) return false;
    return true;
}

export function UpdateScenarioFromJSON(json: any): UpdateScenario {
    return UpdateScenarioFromJSONTyped(json, false);
}

export function UpdateScenarioFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScenario {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'color': json['color'],
        'description': json['description'] == null ? undefined : json['description'],
        'periodOffset': json['period_offset'] == null ? undefined : json['period_offset'],
        'periodSize': json['period_size'] == null ? undefined : json['period_size'],
        'sourceScenario': json['source_scenario'] == null ? undefined : json['source_scenario'],
        'actualsScenarioUuid': json['actuals_scenario_uuid'] == null ? undefined : json['actuals_scenario_uuid'],
        'closingDate': json['closing_date'] == null ? undefined : ClosingDateFromJSON(json['closing_date']),
    };
}

export function UpdateScenarioToJSON(value?: UpdateScenario | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'color': value['color'],
        'description': value['description'],
        'period_offset': value['periodOffset'],
        'period_size': value['periodSize'],
        'source_scenario': value['sourceScenario'],
        'actuals_scenario_uuid': value['actualsScenarioUuid'],
        'closing_date': ClosingDateToJSON(value['closingDate']),
    };
}

