/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DatabaseRelationship } from './DatabaseRelationship';
import {
    DatabaseRelationshipFromJSON,
    DatabaseRelationshipFromJSONTyped,
    DatabaseRelationshipToJSON,
} from './DatabaseRelationship';

/**
 * 
 * @export
 * @interface UpdateDatabaseRelationshipsParams
 */
export interface UpdateDatabaseRelationshipsParams {
    /**
     * 
     * @type {Array<DatabaseRelationship>}
     * @memberof UpdateDatabaseRelationshipsParams
     */
    relationships: Array<DatabaseRelationship>;
}

/**
 * Check if a given object implements the UpdateDatabaseRelationshipsParams interface.
 */
export function instanceOfUpdateDatabaseRelationshipsParams(value: object): boolean {
    if (!('relationships' in value)) return false;
    return true;
}

export function UpdateDatabaseRelationshipsParamsFromJSON(json: any): UpdateDatabaseRelationshipsParams {
    return UpdateDatabaseRelationshipsParamsFromJSONTyped(json, false);
}

export function UpdateDatabaseRelationshipsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDatabaseRelationshipsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'relationships': ((json['relationships'] as Array<any>).map(DatabaseRelationshipFromJSON)),
    };
}

export function UpdateDatabaseRelationshipsParamsToJSON(value?: UpdateDatabaseRelationshipsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'relationships': ((value['relationships'] as Array<any>).map(DatabaseRelationshipToJSON)),
    };
}

