/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Metric } from './Metric';
import {
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
} from './Metric';
import type { MetricCategory } from './MetricCategory';
import {
    MetricCategoryFromJSON,
    MetricCategoryFromJSONTyped,
    MetricCategoryToJSON,
} from './MetricCategory';
import type { Scenario } from './Scenario';
import {
    ScenarioFromJSON,
    ScenarioFromJSONTyped,
    ScenarioToJSON,
} from './Scenario';

/**
 * 
 * @export
 * @interface Summary
 */
export interface Summary {
    /**
     * 
     * @type {string}
     * @memberof Summary
     */
    workspaceUuid: string;
    /**
     * 
     * @type {Array<Metric>}
     * @memberof Summary
     */
    metrics: Array<Metric>;
    /**
     * 
     * @type {Array<Metric>}
     * @memberof Summary
     */
    favorited: Array<Metric>;
    /**
     * 
     * @type {Array<Scenario>}
     * @memberof Summary
     */
    contexts: Array<Scenario>;
    /**
     * 
     * @type {Array<MetricCategory>}
     * @memberof Summary
     */
    categories: Array<MetricCategory>;
}

/**
 * Check if a given object implements the Summary interface.
 */
export function instanceOfSummary(value: object): boolean {
    if (!('workspaceUuid' in value)) return false;
    if (!('metrics' in value)) return false;
    if (!('favorited' in value)) return false;
    if (!('contexts' in value)) return false;
    if (!('categories' in value)) return false;
    return true;
}

export function SummaryFromJSON(json: any): Summary {
    return SummaryFromJSONTyped(json, false);
}

export function SummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Summary {
    if (json == null) {
        return json;
    }
    return {
        
        'workspaceUuid': json['workspace_uuid'],
        'metrics': ((json['metrics'] as Array<any>).map(MetricFromJSON)),
        'favorited': ((json['favorited'] as Array<any>).map(MetricFromJSON)),
        'contexts': ((json['contexts'] as Array<any>).map(ScenarioFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(MetricCategoryFromJSON)),
    };
}

export function SummaryToJSON(value?: Summary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workspace_uuid': value['workspaceUuid'],
        'metrics': ((value['metrics'] as Array<any>).map(MetricToJSON)),
        'favorited': ((value['favorited'] as Array<any>).map(MetricToJSON)),
        'contexts': ((value['contexts'] as Array<any>).map(ScenarioToJSON)),
        'categories': ((value['categories'] as Array<any>).map(MetricCategoryToJSON)),
    };
}

