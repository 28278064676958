/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModelContributor } from './ModelContributor';
import {
    ModelContributorFromJSON,
    ModelContributorFromJSONTyped,
    ModelContributorToJSON,
} from './ModelContributor';
import type { ModelVersion } from './ModelVersion';
import {
    ModelVersionFromJSON,
    ModelVersionFromJSONTyped,
    ModelVersionToJSON,
} from './ModelVersion';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';
import type { Variable } from './Variable';
import {
    VariableFromJSON,
    VariableFromJSONTyped,
    VariableToJSON,
} from './Variable';

/**
 * 
 * @export
 * @interface Model
 */
export interface Model {
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    workspaceUuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof Model
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Model
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    emoji?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    isHidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    authorUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    groupUuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Model
     */
    favoritedBy?: Array<string>;
    /**
     * 
     * @type {Array<ModelContributor>}
     * @memberof Model
     */
    contributors?: Array<ModelContributor>;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    yearTotals?: ModelYearTotalsEnum;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    granularity?: ModelGranularityEnum;
    /**
     * 
     * @type {Period}
     * @memberof Model
     */
    period?: Period;
    /**
     * 
     * @type {Array<ModelVersion>}
     * @memberof Model
     */
    versions?: Array<ModelVersion>;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof Model
     */
    variables?: Array<Variable>;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    originModelUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    communityPublishedModelUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    communityPublishedAssetUuid?: string;
}


/**
 * @export
 */
export const ModelYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type ModelYearTotalsEnum = typeof ModelYearTotalsEnum[keyof typeof ModelYearTotalsEnum];

/**
 * @export
 */
export const ModelGranularityEnum = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year'
} as const;
export type ModelGranularityEnum = typeof ModelGranularityEnum[keyof typeof ModelGranularityEnum];


/**
 * Check if a given object implements the Model interface.
 */
export function instanceOfModel(value: object): boolean {
    if (!('uuid' in value)) return false;
    return true;
}

export function ModelFromJSON(json: any): Model {
    return ModelFromJSONTyped(json, false);
}

export function ModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Model {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'] == null ? undefined : json['name'],
        'workspaceUuid': json['workspace_uuid'] == null ? undefined : json['workspace_uuid'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'description': json['description'] == null ? undefined : json['description'],
        'emoji': json['emoji'] == null ? undefined : json['emoji'],
        'isPublic': json['is_public'] == null ? undefined : json['is_public'],
        'isHidden': json['is_hidden'] == null ? undefined : json['is_hidden'],
        'authorUuid': json['author_uuid'] == null ? undefined : json['author_uuid'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'favoritedBy': json['favorited_by'] == null ? undefined : json['favorited_by'],
        'contributors': json['contributors'] == null ? undefined : ((json['contributors'] as Array<any>).map(ModelContributorFromJSON)),
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
        'granularity': json['granularity'] == null ? undefined : json['granularity'],
        'period': json['period'] == null ? undefined : PeriodFromJSON(json['period']),
        'versions': json['versions'] == null ? undefined : ((json['versions'] as Array<any>).map(ModelVersionFromJSON)),
        'variables': json['variables'] == null ? undefined : ((json['variables'] as Array<any>).map(VariableFromJSON)),
        'originModelUuid': json['origin_model_uuid'] == null ? undefined : json['origin_model_uuid'],
        'communityPublishedModelUuid': json['community_published_model_uuid'] == null ? undefined : json['community_published_model_uuid'],
        'communityPublishedAssetUuid': json['community_published_asset_uuid'] == null ? undefined : json['community_published_asset_uuid'],
    };
}

export function ModelToJSON(value?: Model | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'workspace_uuid': value['workspaceUuid'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'description': value['description'],
        'emoji': value['emoji'],
        'is_public': value['isPublic'],
        'is_hidden': value['isHidden'],
        'author_uuid': value['authorUuid'],
        'group_uuid': value['groupUuid'],
        'favorited_by': value['favoritedBy'],
        'contributors': value['contributors'] == null ? undefined : ((value['contributors'] as Array<any>).map(ModelContributorToJSON)),
        'year_totals': value['yearTotals'],
        'granularity': value['granularity'],
        'period': PeriodToJSON(value['period']),
        'versions': value['versions'] == null ? undefined : ((value['versions'] as Array<any>).map(ModelVersionToJSON)),
        'variables': value['variables'] == null ? undefined : ((value['variables'] as Array<any>).map(VariableToJSON)),
        'origin_model_uuid': value['originModelUuid'],
        'community_published_model_uuid': value['communityPublishedModelUuid'],
        'community_published_asset_uuid': value['communityPublishedAssetUuid'],
    };
}

