/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Credential,
  DeleteCredentialParams,
  HTTPValidationError,
  LoginParams,
  RegisterParams,
  RegisterUser,
  RegistrationRequestParams,
  RequestPasswordResetParams,
  RequestTokenParameters,
  ResetPasswordParams,
  RoleSwitchParams,
  SetVisibilityParams,
  SwitchCredentialParams,
  UpdateUserProfileParams,
  User,
} from '../models/index';
import {
    CredentialFromJSON,
    CredentialToJSON,
    DeleteCredentialParamsFromJSON,
    DeleteCredentialParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LoginParamsFromJSON,
    LoginParamsToJSON,
    RegisterParamsFromJSON,
    RegisterParamsToJSON,
    RegisterUserFromJSON,
    RegisterUserToJSON,
    RegistrationRequestParamsFromJSON,
    RegistrationRequestParamsToJSON,
    RequestPasswordResetParamsFromJSON,
    RequestPasswordResetParamsToJSON,
    RequestTokenParametersFromJSON,
    RequestTokenParametersToJSON,
    ResetPasswordParamsFromJSON,
    ResetPasswordParamsToJSON,
    RoleSwitchParamsFromJSON,
    RoleSwitchParamsToJSON,
    SetVisibilityParamsFromJSON,
    SetVisibilityParamsToJSON,
    SwitchCredentialParamsFromJSON,
    SwitchCredentialParamsToJSON,
    UpdateUserProfileParamsFromJSON,
    UpdateUserProfileParamsToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface ApiAccessTokenRequest {
    requestTokenParameters: RequestTokenParameters;
}

export interface AssignRoleRequest {
    roleSwitchParams: RoleSwitchParams;
}

export interface AuthenticateRequest {
    loginParams: LoginParams;
}

export interface ChangePasswordRequest {
    resetPasswordParams: ResetPasswordParams;
}

export interface DisableCredentialRequest {
    switchCredentialParams: SwitchCredentialParams;
}

export interface EnableCredentialRequest {
    switchCredentialParams: SwitchCredentialParams;
}

export interface GetOAuthUserDataRequest {
    code: string;
}

export interface LinkGoogleAccountCallbackRequest {
    code: string;
}

export interface RegisterUserRequest {
    registerParams: RegisterParams;
}

export interface RegistrationRequestRequest {
    registrationRequestParams: RegistrationRequestParams;
}

export interface RemoveCredentialRequest {
    deleteCredentialParams: DeleteCredentialParams;
}

export interface RequestPasswordResetRequest {
    requestPasswordResetParams: RequestPasswordResetParams;
}

export interface SetVisibilityRequest {
    setVisibilityParams: SetVisibilityParams;
}

export interface TokenRequest {
    code: string;
}

export interface UpdateUserAvatarRequest {
    file: Blob;
}

export interface UpdateUserProfileRequest {
    updateUserProfileParams: UpdateUserProfileParams;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Request Api Access Token
     */
    async apiAccessTokenRaw(requestParameters: ApiAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['requestTokenParameters'] == null) {
            throw new runtime.RequiredError(
                'requestTokenParameters',
                'Required parameter "requestTokenParameters" was null or undefined when calling apiAccessToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/api_access_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestTokenParametersToJSON(requestParameters['requestTokenParameters']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Request Api Access Token
     */
    async apiAccessToken(requestParameters: ApiAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign Role
     */
    async assignRoleRaw(requestParameters: AssignRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['roleSwitchParams'] == null) {
            throw new runtime.RequiredError(
                'roleSwitchParams',
                'Required parameter "roleSwitchParams" was null or undefined when calling assignRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/role`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleSwitchParamsToJSON(requestParameters['roleSwitchParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Assign Role
     */
    async assignRole(requestParameters: AssignRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.assignRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authenticate
     */
    async authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['loginParams'] == null) {
            throw new runtime.RequiredError(
                'loginParams',
                'Required parameter "loginParams" was null or undefined when calling authenticate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginParamsToJSON(requestParameters['loginParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Authenticate
     */
    async authenticate(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.authenticateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change Password
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['resetPasswordParams'] == null) {
            throw new runtime.RequiredError(
                'resetPasswordParams',
                'Required parameter "resetPasswordParams" was null or undefined when calling changePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordParamsToJSON(requestParameters['resetPasswordParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Change Password
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Credentials
     */
    async credentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Credential>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CredentialFromJSON));
    }

    /**
     * Credentials
     */
    async credentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Credential>> {
        const response = await this.credentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Disable Credential
     */
    async disableCredentialRaw(requestParameters: DisableCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['switchCredentialParams'] == null) {
            throw new runtime.RequiredError(
                'switchCredentialParams',
                'Required parameter "switchCredentialParams" was null or undefined when calling disableCredential().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/disable_credential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchCredentialParamsToJSON(requestParameters['switchCredentialParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Disable Credential
     */
    async disableCredential(requestParameters: DisableCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.disableCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable Credential
     */
    async enableCredentialRaw(requestParameters: EnableCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['switchCredentialParams'] == null) {
            throw new runtime.RequiredError(
                'switchCredentialParams',
                'Required parameter "switchCredentialParams" was null or undefined when calling enableCredential().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/enable_credential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchCredentialParamsToJSON(requestParameters['switchCredentialParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Enable Credential
     */
    async enableCredential(requestParameters: EnableCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.enableCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Oauth User
     */
    async getOAuthUserDataRaw(requestParameters: GetOAuthUserDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling getOAuthUserData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/oauth/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Oauth User
     */
    async getOAuthUserData(requestParameters: GetOAuthUserDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getOAuthUserDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Session User
     */
    async getUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Session User
     */
    async getUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Link Google
     */
    async linkGoogleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/link-google`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Link Google
     */
    async linkGoogle(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.linkGoogleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Link Google Account Callback
     */
    async linkGoogleAccountCallbackRaw(requestParameters: LinkGoogleAccountCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling linkGoogleAccountCallback().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/link_google_account_callback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Link Google Account Callback
     */
    async linkGoogleAccountCallback(requestParameters: LinkGoogleAccountCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.linkGoogleAccountCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Logout
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.logoutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Oauth Login
     */
    async oauthLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/oauth/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Oauth Login
     */
    async oauthLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.oauthLoginRaw(initOverrides);
        return await response.value();
    }

    /**
     * Oauth Register
     */
    async oauthRegisterRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/oauth/register`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Oauth Register
     */
    async oauthRegister(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.oauthRegisterRaw(initOverrides);
        return await response.value();
    }

    /**
     * Register User
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterUser>> {
        if (requestParameters['registerParams'] == null) {
            throw new runtime.RequiredError(
                'registerParams',
                'Required parameter "registerParams" was null or undefined when calling registerUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterParamsToJSON(requestParameters['registerParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterUserFromJSON(jsonValue));
    }

    /**
     * Register User
     */
    async registerUser(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterUser> {
        const response = await this.registerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registration Request
     */
    async registrationRequestRaw(requestParameters: RegistrationRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['registrationRequestParams'] == null) {
            throw new runtime.RequiredError(
                'registrationRequestParams',
                'Required parameter "registrationRequestParams" was null or undefined when calling registrationRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/registration_request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationRequestParamsToJSON(requestParameters['registrationRequestParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Registration Request
     */
    async registrationRequest(requestParameters: RegistrationRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.registrationRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Credential
     */
    async removeCredentialRaw(requestParameters: RemoveCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['deleteCredentialParams'] == null) {
            throw new runtime.RequiredError(
                'deleteCredentialParams',
                'Required parameter "deleteCredentialParams" was null or undefined when calling removeCredential().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/remove_credential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCredentialParamsToJSON(requestParameters['deleteCredentialParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove Credential
     */
    async removeCredential(requestParameters: RemoveCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.removeCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request Password Reset
     */
    async requestPasswordResetRaw(requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['requestPasswordResetParams'] == null) {
            throw new runtime.RequiredError(
                'requestPasswordResetParams',
                'Required parameter "requestPasswordResetParams" was null or undefined when calling requestPasswordReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPasswordResetParamsToJSON(requestParameters['requestPasswordResetParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Request Password Reset
     */
    async requestPasswordReset(requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.requestPasswordResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Visibility
     */
    async setVisibilityRaw(requestParameters: SetVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['setVisibilityParams'] == null) {
            throw new runtime.RequiredError(
                'setVisibilityParams',
                'Required parameter "setVisibilityParams" was null or undefined when calling setVisibility().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/visibility`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetVisibilityParamsToJSON(requestParameters['setVisibilityParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Visibility
     */
    async setVisibility(requestParameters: SetVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.setVisibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Token
     */
    async tokenRaw(requestParameters: TokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling token().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Token
     */
    async token(requestParameters: TokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.tokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User Avatar With Image
     */
    async updateUserAvatarRaw(requestParameters: UpdateUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling updateUserAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/auth/update/avatar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update User Avatar With Image
     */
    async updateUserAvatar(requestParameters: UpdateUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateUserAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User Profile
     */
    async updateUserProfileRaw(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['updateUserProfileParams'] == null) {
            throw new runtime.RequiredError(
                'updateUserProfileParams',
                'Required parameter "updateUserProfileParams" was null or undefined when calling updateUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/auth/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserProfileParamsToJSON(requestParameters['updateUserProfileParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update User Profile
     */
    async updateUserProfile(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
