/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface CreateModelParams
 */
export interface CreateModelParams {
    /**
     * 
     * @type {string}
     * @memberof CreateModelParams
     */
    name: string;
    /**
     * 
     * @type {Period}
     * @memberof CreateModelParams
     */
    period: Period;
    /**
     * 
     * @type {string}
     * @memberof CreateModelParams
     */
    groupUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateModelParams
     */
    isPublic: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateModelParams
     */
    contributors: { [key: string]: string; };
}


/**
 * @export
 */
export const CreateModelParamsContributorsEnum = {
    Owner: 'owner',
    Contributor: 'contributor',
    Guest: 'guest'
} as const;
export type CreateModelParamsContributorsEnum = typeof CreateModelParamsContributorsEnum[keyof typeof CreateModelParamsContributorsEnum];


/**
 * Check if a given object implements the CreateModelParams interface.
 */
export function instanceOfCreateModelParams(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('period' in value)) return false;
    if (!('isPublic' in value)) return false;
    if (!('contributors' in value)) return false;
    return true;
}

export function CreateModelParamsFromJSON(json: any): CreateModelParams {
    return CreateModelParamsFromJSONTyped(json, false);
}

export function CreateModelParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateModelParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'period': PeriodFromJSON(json['period']),
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'isPublic': json['is_public'],
        'contributors': json['contributors'],
    };
}

export function CreateModelParamsToJSON(value?: CreateModelParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'period': PeriodToJSON(value['period']),
        'group_uuid': value['groupUuid'],
        'is_public': value['isPublic'],
        'contributors': value['contributors'],
    };
}

